/* Colors for the ripple elements.*/
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
@import '~@angular/cdk/overlay-prebuilt.css';
.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1, .mat-headline, .mat-typography h1 {
  font: 400 24px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2, .mat-title, .mat-typography h2 {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3, .mat-subheading-2, .mat-typography h3 {
  font: 400 16px/28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4, .mat-subheading-1, .mat-typography h4 {
  font: 400 15px/24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5, .mat-typography h5 {
  font: 400 calc(14px * 0.83)/20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6, .mat-typography h6 {
  font: 400 calc(14px * 0.67)/20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong, .mat-body-2 {
  font: 500 14px/24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body, .mat-body-1, .mat-typography {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p, .mat-body-1 p, .mat-typography p {
  margin: 0 0 12px;
}

.mat-small, .mat-caption {
  font: 400 12px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-display-4, .mat-typography .mat-display-4 {
  font: 300 112px/112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3, .mat-typography .mat-display-3 {
  font: 400 56px/56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2, .mat-typography .mat-display-2 {
  font: 400 45px/48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1, .mat-typography .mat-display-1 {
  font: 400 34px/40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon, .mat-chip .mat-icon.chip-icon-suffix,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  -ms-transform: translateY(-1.28125em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  -ms-transform: translateY(-1.28124em) scale(0.75);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  -ms-transform: translateY(-1.28123em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px/24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

@keyframes cdk-text-field-autofill-start {
  /*!*/
}
@keyframes cdk-text-field-autofill-end {
  /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

.mat-focus-indicator {
  position: relative;
}

.mat-mdc-focus-indicator {
  position: relative;
}

.primary100 {
  color: #17161E;
}

.primary90 {
  color: #2e2c3b;
}

.primary80 {
  color: #444159;
}

.primary70 {
  color: #5b5776;
}

.primary60 {
  color: #716c94;
}

.primary50 {
  color: #8e8aa9;
}

.primary40 {
  color: #aaa7bf;
}

.primary30 {
  color: #c7c5d4;
}

.primary20 {
  color: #e3e2ea;
}

.primary10 {
  color: #f1f1f5;
}

.black100 {
  color: #25282B;
}

.black80 {
  color: #52575C;
}

.black60 {
  color: #A0A4A8;
}

.black40 {
  color: #CACCCF;
}

.black20 {
  color: #DBDDE0;
}

.black10 {
  color: #E8E8E8;
}

.black5 {
  color: #F7F7F7;
}

.white-default {
  color: #FFFFFF;
}

.white-dark {
  color: #F7F9FB;
}

.warning-default {
  color: #FFE4A5;
}

.warning-light {
  color: #FFF2D2;
}

.warning-dark {
  color: #CC8400;
}

.success-default {
  color: #2AC769;
}

.success-dark {
  color: #128718;
}

.success-light {
  color: #D1F9C6;
}

.error-default {
  color: #FB4E4E;
}

.error-dark {
  color: #E93C3C;
}

.error-light {
  color: #FFC6C6;
}

.accent-default {
  color: #4F24FA;
}

.bg-primary100 {
  background-color: #17161E;
}

.bg-primary90 {
  background-color: #2e2c3b;
}

.bg-primary80 {
  background-color: #444159;
}

.bg-primary70 {
  background-color: #5b5776;
}

.bg-primary60 {
  background-color: #716c94;
}

.bg-primary50 {
  background-color: #8e8aa9;
}

.bg-primary40 {
  background-color: #aaa7bf;
}

.bg-primary30 {
  background-color: #c7c5d4;
}

.bg-primary20 {
  background-color: #e3e2ea;
}

.bg-primary10 {
  background-color: #f1f1f5;
}

.bg-black100 {
  background-color: #25282B;
}

.bg-black80 {
  background-color: #52575C;
}

.bg-black60 {
  background-color: #A0A4A8;
}

.bg-black40 {
  background-color: #CACCCF;
}

.bg-black20 {
  background-color: #DBDDE0;
}

.bg-black10 {
  background-color: #E8E8E8;
}

.bg-black5 {
  background-color: #F7F7F7;
}

.bg-white-default {
  background-color: #FFFFFF;
}

.bg-white-dark {
  background-color: #F7F9FB;
}

.bg-warning-light {
  background-color: #FFF2D2;
}

.bg-warning-dark {
  background-color: #CC8400;
}

.bg-warning-default {
  background-color: #FFE4A5;
}

.bg-success-default {
  background-color: #2AC769;
}

.bg-success-dark {
  background-color: #128718;
}

.bg-success-light {
  background-color: #D1F9C6;
}

.bg-error-default {
  background-color: #FB4E4E;
}

.bg-error-dark {
  background-color: #E93C3C;
}

.bg-error-light {
  background-color: #FFC6C6;
}

.bg-accent-default {
  background-color: #4F24FA;
}

.fill-none {
  fill: none;
}

.fill-primary100 {
  fill: #17161E;
}

.fill-primary90 {
  fill: #2e2c3b;
}

.fill-primary80 {
  fill: #444159;
}

.fill-primary70 {
  fill: #5b5776;
}

.fill-primary60 {
  fill: #716c94;
}

.fill-primary50 {
  fill: #8e8aa9;
}

.fill-primary40 {
  fill: #aaa7bf;
}

.fill-primary30 {
  fill: #c7c5d4;
}

.fill-primary20 {
  fill: #e3e2ea;
}

.fill-primary10 {
  fill: #f1f1f5;
}

.fill-black100 {
  fill: #25282B;
}

.fill-black80 {
  fill: #52575C;
}

.fill-black60 {
  fill: #A0A4A8;
}

.fill-black40 {
  fill: #CACCCF;
}

.fill-black20 {
  fill: #DBDDE0;
}

.fill-black10 {
  fill: #E8E8E8;
}

.fill-black5 {
  fill: #F7F7F7;
}

.fill-white-default {
  fill: #FFFFFF;
}

.fill-white-dark {
  fill: #F7F9FB;
}

.fill-warning-light {
  fill: #FFF2D2;
}

.fill-warning-dark {
  fill: #CC8400;
}

.fill-warning-default {
  fill: #FFE4A5;
}

.fill-success-default {
  fill: #2AC769;
}

.fill-success-dark {
  fill: #128718;
}

.fill-success-light {
  fill: #D1F9C6;
}

.fill-error-default {
  fill: #FB4E4E;
}

.fill-error-dark {
  fill: #E93C3C;
}

.fill-error-light {
  fill: #FFC6C6;
}

.fill-accent-default {
  fill: #4F24FA;
}

.liquid-light-theme .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.liquid-light-theme .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.liquid-light-theme .mat-option:hover:not(.mat-option-disabled), .liquid-light-theme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.liquid-light-theme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.liquid-light-theme .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.liquid-light-theme .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.liquid-light-theme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #17161e;
}
.liquid-light-theme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #8e8aa9;
}
.liquid-light-theme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #fb4e4e;
}
.liquid-light-theme .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.liquid-light-theme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.liquid-light-theme .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.liquid-light-theme .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.liquid-light-theme .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.liquid-light-theme .mat-primary .mat-pseudo-checkbox-checked,
.liquid-light-theme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #17161e;
}
.liquid-light-theme .mat-pseudo-checkbox-checked,
.liquid-light-theme .mat-pseudo-checkbox-indeterminate,
.liquid-light-theme .mat-accent .mat-pseudo-checkbox-checked,
.liquid-light-theme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #8e8aa9;
}
.liquid-light-theme .mat-warn .mat-pseudo-checkbox-checked,
.liquid-light-theme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #fb4e4e;
}
.liquid-light-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.liquid-light-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.liquid-light-theme .mat-app-background, .liquid-light-theme.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.liquid-light-theme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.liquid-light-theme .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.liquid-light-theme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.liquid-light-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.liquid-light-theme .mat-badge-content {
  color: white;
  background: #17161e;
}
.cdk-high-contrast-active .liquid-light-theme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.liquid-light-theme .mat-badge-accent .mat-badge-content {
  background: #8e8aa9;
  color: white;
}
.liquid-light-theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #fb4e4e;
}
.liquid-light-theme .mat-badge {
  position: relative;
}
.liquid-light-theme .mat-badge-hidden .mat-badge-content {
  display: none;
}
.liquid-light-theme .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.liquid-light-theme .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.liquid-light-theme .ng-animate-disabled .mat-badge-content,
.liquid-light-theme .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.liquid-light-theme .mat-badge-content.mat-badge-active {
  transform: none;
}
.liquid-light-theme .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.liquid-light-theme .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.liquid-light-theme .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.liquid-light-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .liquid-light-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.liquid-light-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .liquid-light-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.liquid-light-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .liquid-light-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.liquid-light-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .liquid-light-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.liquid-light-theme .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.liquid-light-theme .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.liquid-light-theme .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.liquid-light-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .liquid-light-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.liquid-light-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .liquid-light-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.liquid-light-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .liquid-light-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.liquid-light-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .liquid-light-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.liquid-light-theme .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.liquid-light-theme .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.liquid-light-theme .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.liquid-light-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .liquid-light-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.liquid-light-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .liquid-light-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.liquid-light-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .liquid-light-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.liquid-light-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .liquid-light-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.liquid-light-theme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.liquid-light-theme .mat-button, .liquid-light-theme .mat-icon-button, .liquid-light-theme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.liquid-light-theme .mat-button.mat-primary, .liquid-light-theme .mat-icon-button.mat-primary, .liquid-light-theme .mat-stroked-button.mat-primary {
  color: #17161e;
}
.liquid-light-theme .mat-button.mat-accent, .liquid-light-theme .mat-icon-button.mat-accent, .liquid-light-theme .mat-stroked-button.mat-accent {
  color: #8e8aa9;
}
.liquid-light-theme .mat-button.mat-warn, .liquid-light-theme .mat-icon-button.mat-warn, .liquid-light-theme .mat-stroked-button.mat-warn {
  color: #fb4e4e;
}
.liquid-light-theme .mat-button.mat-primary.mat-button-disabled, .liquid-light-theme .mat-button.mat-accent.mat-button-disabled, .liquid-light-theme .mat-button.mat-warn.mat-button-disabled, .liquid-light-theme .mat-button.mat-button-disabled.mat-button-disabled, .liquid-light-theme .mat-icon-button.mat-primary.mat-button-disabled, .liquid-light-theme .mat-icon-button.mat-accent.mat-button-disabled, .liquid-light-theme .mat-icon-button.mat-warn.mat-button-disabled, .liquid-light-theme .mat-icon-button.mat-button-disabled.mat-button-disabled, .liquid-light-theme .mat-stroked-button.mat-primary.mat-button-disabled, .liquid-light-theme .mat-stroked-button.mat-accent.mat-button-disabled, .liquid-light-theme .mat-stroked-button.mat-warn.mat-button-disabled, .liquid-light-theme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.liquid-light-theme .mat-button.mat-primary .mat-button-focus-overlay, .liquid-light-theme .mat-icon-button.mat-primary .mat-button-focus-overlay, .liquid-light-theme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #17161e;
}
.liquid-light-theme .mat-button.mat-accent .mat-button-focus-overlay, .liquid-light-theme .mat-icon-button.mat-accent .mat-button-focus-overlay, .liquid-light-theme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #8e8aa9;
}
.liquid-light-theme .mat-button.mat-warn .mat-button-focus-overlay, .liquid-light-theme .mat-icon-button.mat-warn .mat-button-focus-overlay, .liquid-light-theme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #fb4e4e;
}
.liquid-light-theme .mat-button.mat-button-disabled .mat-button-focus-overlay, .liquid-light-theme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .liquid-light-theme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.liquid-light-theme .mat-button .mat-ripple-element, .liquid-light-theme .mat-icon-button .mat-ripple-element, .liquid-light-theme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.liquid-light-theme .mat-button-focus-overlay {
  background: black;
}
.liquid-light-theme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-flat-button, .liquid-light-theme .mat-raised-button, .liquid-light-theme .mat-fab, .liquid-light-theme .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.liquid-light-theme .mat-flat-button.mat-primary, .liquid-light-theme .mat-raised-button.mat-primary, .liquid-light-theme .mat-fab.mat-primary, .liquid-light-theme .mat-mini-fab.mat-primary {
  color: white;
}
.liquid-light-theme .mat-flat-button.mat-accent, .liquid-light-theme .mat-raised-button.mat-accent, .liquid-light-theme .mat-fab.mat-accent, .liquid-light-theme .mat-mini-fab.mat-accent {
  color: white;
}
.liquid-light-theme .mat-flat-button.mat-warn, .liquid-light-theme .mat-raised-button.mat-warn, .liquid-light-theme .mat-fab.mat-warn, .liquid-light-theme .mat-mini-fab.mat-warn {
  color: white;
}
.liquid-light-theme .mat-flat-button.mat-primary.mat-button-disabled, .liquid-light-theme .mat-flat-button.mat-accent.mat-button-disabled, .liquid-light-theme .mat-flat-button.mat-warn.mat-button-disabled, .liquid-light-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .liquid-light-theme .mat-raised-button.mat-primary.mat-button-disabled, .liquid-light-theme .mat-raised-button.mat-accent.mat-button-disabled, .liquid-light-theme .mat-raised-button.mat-warn.mat-button-disabled, .liquid-light-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .liquid-light-theme .mat-fab.mat-primary.mat-button-disabled, .liquid-light-theme .mat-fab.mat-accent.mat-button-disabled, .liquid-light-theme .mat-fab.mat-warn.mat-button-disabled, .liquid-light-theme .mat-fab.mat-button-disabled.mat-button-disabled, .liquid-light-theme .mat-mini-fab.mat-primary.mat-button-disabled, .liquid-light-theme .mat-mini-fab.mat-accent.mat-button-disabled, .liquid-light-theme .mat-mini-fab.mat-warn.mat-button-disabled, .liquid-light-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.liquid-light-theme .mat-flat-button.mat-primary, .liquid-light-theme .mat-raised-button.mat-primary, .liquid-light-theme .mat-fab.mat-primary, .liquid-light-theme .mat-mini-fab.mat-primary {
  background-color: #17161e;
}
.liquid-light-theme .mat-flat-button.mat-accent, .liquid-light-theme .mat-raised-button.mat-accent, .liquid-light-theme .mat-fab.mat-accent, .liquid-light-theme .mat-mini-fab.mat-accent {
  background-color: #8e8aa9;
}
.liquid-light-theme .mat-flat-button.mat-warn, .liquid-light-theme .mat-raised-button.mat-warn, .liquid-light-theme .mat-fab.mat-warn, .liquid-light-theme .mat-mini-fab.mat-warn {
  background-color: #fb4e4e;
}
.liquid-light-theme .mat-flat-button.mat-primary.mat-button-disabled, .liquid-light-theme .mat-flat-button.mat-accent.mat-button-disabled, .liquid-light-theme .mat-flat-button.mat-warn.mat-button-disabled, .liquid-light-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .liquid-light-theme .mat-raised-button.mat-primary.mat-button-disabled, .liquid-light-theme .mat-raised-button.mat-accent.mat-button-disabled, .liquid-light-theme .mat-raised-button.mat-warn.mat-button-disabled, .liquid-light-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .liquid-light-theme .mat-fab.mat-primary.mat-button-disabled, .liquid-light-theme .mat-fab.mat-accent.mat-button-disabled, .liquid-light-theme .mat-fab.mat-warn.mat-button-disabled, .liquid-light-theme .mat-fab.mat-button-disabled.mat-button-disabled, .liquid-light-theme .mat-mini-fab.mat-primary.mat-button-disabled, .liquid-light-theme .mat-mini-fab.mat-accent.mat-button-disabled, .liquid-light-theme .mat-mini-fab.mat-warn.mat-button-disabled, .liquid-light-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-flat-button.mat-primary .mat-ripple-element, .liquid-light-theme .mat-raised-button.mat-primary .mat-ripple-element, .liquid-light-theme .mat-fab.mat-primary .mat-ripple-element, .liquid-light-theme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.liquid-light-theme .mat-flat-button.mat-accent .mat-ripple-element, .liquid-light-theme .mat-raised-button.mat-accent .mat-ripple-element, .liquid-light-theme .mat-fab.mat-accent .mat-ripple-element, .liquid-light-theme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.liquid-light-theme .mat-flat-button.mat-warn .mat-ripple-element, .liquid-light-theme .mat-raised-button.mat-warn .mat-ripple-element, .liquid-light-theme .mat-fab.mat-warn .mat-ripple-element, .liquid-light-theme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.liquid-light-theme .mat-stroked-button:not([class*=mat-elevation-z]), .liquid-light-theme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-fab:not([class*=mat-elevation-z]), .liquid-light-theme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .liquid-light-theme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .liquid-light-theme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-button-toggle-standalone,
.liquid-light-theme .mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.liquid-light-theme .mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}
.liquid-light-theme .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.liquid-light-theme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.liquid-light-theme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.liquid-light-theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
}
.liquid-light-theme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.liquid-light-theme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.liquid-light-theme .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.liquid-light-theme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.liquid-light-theme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.liquid-light-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.liquid-light-theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.liquid-light-theme .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.liquid-light-theme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.liquid-light-theme .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.liquid-light-theme .mat-checkbox-checkmark {
  fill: #fafafa;
}
.liquid-light-theme .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.liquid-light-theme .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.liquid-light-theme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .liquid-light-theme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #17161e;
}
.liquid-light-theme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .liquid-light-theme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #8e8aa9;
}
.liquid-light-theme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .liquid-light-theme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #fb4e4e;
}
.liquid-light-theme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .liquid-light-theme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.liquid-light-theme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.liquid-light-theme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.54);
}
.liquid-light-theme .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.liquid-light-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.liquid-light-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #17161e;
}
.liquid-light-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.liquid-light-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #8e8aa9;
}
.liquid-light-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.liquid-light-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #fb4e4e;
}
.liquid-light-theme .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.liquid-light-theme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.liquid-light-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.liquid-light-theme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.liquid-light-theme .mat-chip.mat-standard-chip::after {
  background: black;
}
.liquid-light-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #17161e;
  color: white;
}
.liquid-light-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.liquid-light-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.liquid-light-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #fb4e4e;
  color: white;
}
.liquid-light-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.liquid-light-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.liquid-light-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #8e8aa9;
  color: white;
}
.liquid-light-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.liquid-light-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.liquid-light-theme .mat-table {
  background: white;
}
.liquid-light-theme .mat-table thead, .liquid-light-theme .mat-table tbody, .liquid-light-theme .mat-table tfoot,
.liquid-light-theme mat-header-row, .liquid-light-theme mat-row, .liquid-light-theme mat-footer-row,
.liquid-light-theme [mat-header-row], .liquid-light-theme [mat-row], .liquid-light-theme [mat-footer-row],
.liquid-light-theme .mat-table-sticky {
  background: inherit;
}
.liquid-light-theme mat-row, .liquid-light-theme mat-header-row, .liquid-light-theme mat-footer-row,
.liquid-light-theme th.mat-header-cell, .liquid-light-theme td.mat-cell, .liquid-light-theme td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.liquid-light-theme .mat-cell, .liquid-light-theme .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.liquid-light-theme .mat-calendar-arrow {
  border-top-color: rgba(0, 0, 0, 0.54);
}
.liquid-light-theme .mat-datepicker-toggle,
.liquid-light-theme .mat-datepicker-content .mat-calendar-next-button,
.liquid-light-theme .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.liquid-light-theme .mat-calendar-table-header {
  color: rgba(0, 0, 0, 0.38);
}
.liquid-light-theme .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.liquid-light-theme .mat-calendar-body-cell-content,
.liquid-light-theme .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.liquid-light-theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.liquid-light-theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.liquid-light-theme .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.liquid-light-theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.liquid-light-theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.liquid-light-theme .mat-calendar-body-in-range::before {
  background: rgba(23, 22, 30, 0.2);
}
.liquid-light-theme .mat-calendar-body-comparison-identical,
.liquid-light-theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.liquid-light-theme .mat-calendar-body-comparison-bridge-start::before,
.liquid-light-theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(23, 22, 30, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.liquid-light-theme .mat-calendar-body-comparison-bridge-end::before,
.liquid-light-theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(23, 22, 30, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.liquid-light-theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.liquid-light-theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.liquid-light-theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.liquid-light-theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.liquid-light-theme .mat-calendar-body-selected {
  background-color: #17161e;
  color: white;
}
.liquid-light-theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(23, 22, 30, 0.4);
}
.liquid-light-theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.liquid-light-theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.liquid-light-theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.liquid-light-theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(23, 22, 30, 0.3);
}
.liquid-light-theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.liquid-light-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(142, 138, 169, 0.2);
}
.liquid-light-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.liquid-light-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.liquid-light-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.liquid-light-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(142, 138, 169, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.liquid-light-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.liquid-light-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(142, 138, 169, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.liquid-light-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.liquid-light-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.liquid-light-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.liquid-light-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.liquid-light-theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #8e8aa9;
  color: white;
}
.liquid-light-theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(142, 138, 169, 0.4);
}
.liquid-light-theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.liquid-light-theme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.liquid-light-theme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.liquid-light-theme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(142, 138, 169, 0.3);
}
.liquid-light-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(251, 78, 78, 0.2);
}
.liquid-light-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.liquid-light-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.liquid-light-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.liquid-light-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(251, 78, 78, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.liquid-light-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.liquid-light-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(251, 78, 78, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.liquid-light-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.liquid-light-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.liquid-light-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.liquid-light-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.liquid-light-theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #fb4e4e;
  color: white;
}
.liquid-light-theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(251, 78, 78, 0.4);
}
.liquid-light-theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.liquid-light-theme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.liquid-light-theme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.liquid-light-theme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(251, 78, 78, 0.3);
}
.liquid-light-theme .mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-datepicker-toggle-active {
  color: #17161e;
}
.liquid-light-theme .mat-datepicker-toggle-active.mat-accent {
  color: #8e8aa9;
}
.liquid-light-theme .mat-datepicker-toggle-active.mat-warn {
  color: #fb4e4e;
}
.liquid-light-theme .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.liquid-light-theme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.liquid-light-theme .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.liquid-light-theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .liquid-light-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .liquid-light-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .liquid-light-theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.liquid-light-theme .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.liquid-light-theme .mat-expansion-panel-header-description,
.liquid-light-theme .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.liquid-light-theme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.liquid-light-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.liquid-light-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.liquid-light-theme .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.liquid-light-theme .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.liquid-light-theme .mat-form-field.mat-focused .mat-form-field-label {
  color: #17161e;
}
.liquid-light-theme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #8e8aa9;
}
.liquid-light-theme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #fb4e4e;
}
.liquid-light-theme .mat-focused .mat-form-field-required-marker {
  color: #8e8aa9;
}
.liquid-light-theme .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.liquid-light-theme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #17161e;
}
.liquid-light-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #8e8aa9;
}
.liquid-light-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #fb4e4e;
}
.liquid-light-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #17161e;
}
.liquid-light-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #8e8aa9;
}
.liquid-light-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #fb4e4e;
}
.liquid-light-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #fb4e4e;
}
.liquid-light-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.liquid-light-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #fb4e4e;
}
.liquid-light-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.liquid-light-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #fb4e4e;
}
.liquid-light-theme .mat-error {
  color: #fb4e4e;
}
.liquid-light-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.liquid-light-theme .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.liquid-light-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.liquid-light-theme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.liquid-light-theme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.liquid-light-theme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.liquid-light-theme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.liquid-light-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.liquid-light-theme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.liquid-light-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.liquid-light-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.liquid-light-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.liquid-light-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #17161e;
}
.liquid-light-theme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #8e8aa9;
}
.liquid-light-theme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #fb4e4e;
}
.liquid-light-theme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #fb4e4e;
}
.liquid-light-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.liquid-light-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.liquid-light-theme .mat-icon.mat-primary {
  color: #17161e;
}
.liquid-light-theme .mat-icon.mat-accent {
  color: #8e8aa9;
}
.liquid-light-theme .mat-icon.mat-warn {
  color: #fb4e4e;
}
.liquid-light-theme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.liquid-light-theme .mat-input-element:disabled,
.liquid-light-theme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.liquid-light-theme .mat-input-element {
  caret-color: #17161e;
}
.liquid-light-theme .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.liquid-light-theme .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.liquid-light-theme .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.liquid-light-theme .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.liquid-light-theme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #8e8aa9;
}
.liquid-light-theme .mat-form-field.mat-warn .mat-input-element,
.liquid-light-theme .mat-form-field-invalid .mat-input-element {
  caret-color: #fb4e4e;
}
.liquid-light-theme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #fb4e4e;
}
.liquid-light-theme .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.liquid-light-theme .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.liquid-light-theme .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.liquid-light-theme .mat-list-item-disabled {
  background-color: #eeeeee;
}
.liquid-light-theme .mat-list-option:hover, .liquid-light-theme .mat-list-option:focus,
.liquid-light-theme .mat-nav-list .mat-list-item:hover,
.liquid-light-theme .mat-nav-list .mat-list-item:focus,
.liquid-light-theme .mat-action-list .mat-list-item:hover,
.liquid-light-theme .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.liquid-light-theme .mat-list-single-selected-option, .liquid-light-theme .mat-list-single-selected-option:hover, .liquid-light-theme .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-menu-panel {
  background: white;
}
.liquid-light-theme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.liquid-light-theme .mat-menu-item[disabled], .liquid-light-theme .mat-menu-item[disabled]::after,
.liquid-light-theme .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.liquid-light-theme .mat-menu-item .mat-icon-no-color,
.liquid-light-theme .mat-menu-item-submenu-trigger::after {
  color: rgba(0, 0, 0, 0.54);
}
.liquid-light-theme .mat-menu-item:hover:not([disabled]),
.liquid-light-theme .mat-menu-item.cdk-program-focused:not([disabled]),
.liquid-light-theme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.liquid-light-theme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.liquid-light-theme .mat-paginator {
  background: white;
}
.liquid-light-theme .mat-paginator,
.liquid-light-theme .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.liquid-light-theme .mat-paginator-decrement,
.liquid-light-theme .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.liquid-light-theme .mat-paginator-first,
.liquid-light-theme .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.liquid-light-theme .mat-icon-button[disabled] .mat-paginator-decrement,
.liquid-light-theme .mat-icon-button[disabled] .mat-paginator-increment,
.liquid-light-theme .mat-icon-button[disabled] .mat-paginator-first,
.liquid-light-theme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-paginator-container {
  min-height: 56px;
}

.liquid-light-theme .mat-progress-bar-background {
  fill: #aaa7bf;
}
.liquid-light-theme .mat-progress-bar-buffer {
  background-color: #aaa7bf;
}
.liquid-light-theme .mat-progress-bar-fill::after {
  background-color: #17161e;
}
.liquid-light-theme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #f7f9fb;
}
.liquid-light-theme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #f7f9fb;
}
.liquid-light-theme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #8e8aa9;
}
.liquid-light-theme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ffc6c6;
}
.liquid-light-theme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ffc6c6;
}
.liquid-light-theme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #fb4e4e;
}
.liquid-light-theme .mat-progress-spinner circle, .liquid-light-theme .mat-spinner circle {
  stroke: #17161e;
}
.liquid-light-theme .mat-progress-spinner.mat-accent circle, .liquid-light-theme .mat-spinner.mat-accent circle {
  stroke: #8e8aa9;
}
.liquid-light-theme .mat-progress-spinner.mat-warn circle, .liquid-light-theme .mat-spinner.mat-warn circle {
  stroke: #fb4e4e;
}
.liquid-light-theme .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.liquid-light-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #17161e;
}
.liquid-light-theme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.liquid-light-theme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .liquid-light-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .liquid-light-theme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #17161e;
}
.liquid-light-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #8e8aa9;
}
.liquid-light-theme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.liquid-light-theme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .liquid-light-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .liquid-light-theme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #8e8aa9;
}
.liquid-light-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #fb4e4e;
}
.liquid-light-theme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.liquid-light-theme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .liquid-light-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .liquid-light-theme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #fb4e4e;
}
.liquid-light-theme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.liquid-light-theme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.liquid-light-theme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.liquid-light-theme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.liquid-light-theme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.liquid-light-theme .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.liquid-light-theme .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.liquid-light-theme .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.liquid-light-theme .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.liquid-light-theme .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.liquid-light-theme .mat-select-panel {
  background: white;
}
.liquid-light-theme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #17161e;
}
.liquid-light-theme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #8e8aa9;
}
.liquid-light-theme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #fb4e4e;
}
.liquid-light-theme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #fb4e4e;
}
.liquid-light-theme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.liquid-light-theme .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.liquid-light-theme .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.liquid-light-theme .mat-drawer.mat-drawer-push {
  background-color: white;
}
.liquid-light-theme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.liquid-light-theme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.liquid-light-theme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.liquid-light-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #8e8aa9;
}
.liquid-light-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(142, 138, 169, 0.54);
}
.liquid-light-theme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #8e8aa9;
}
.liquid-light-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #17161e;
}
.liquid-light-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(23, 22, 30, 0.54);
}
.liquid-light-theme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #17161e;
}
.liquid-light-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #fb4e4e;
}
.liquid-light-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(251, 78, 78, 0.54);
}
.liquid-light-theme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #fb4e4e;
}
.liquid-light-theme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.liquid-light-theme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.liquid-light-theme .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.liquid-light-theme .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.liquid-light-theme .mat-primary .mat-slider-track-fill,
.liquid-light-theme .mat-primary .mat-slider-thumb,
.liquid-light-theme .mat-primary .mat-slider-thumb-label {
  background-color: #17161e;
}
.liquid-light-theme .mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.liquid-light-theme .mat-primary .mat-slider-focus-ring {
  background-color: rgba(23, 22, 30, 0.2);
}
.liquid-light-theme .mat-accent .mat-slider-track-fill,
.liquid-light-theme .mat-accent .mat-slider-thumb,
.liquid-light-theme .mat-accent .mat-slider-thumb-label {
  background-color: #8e8aa9;
}
.liquid-light-theme .mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.liquid-light-theme .mat-accent .mat-slider-focus-ring {
  background-color: rgba(142, 138, 169, 0.2);
}
.liquid-light-theme .mat-warn .mat-slider-track-fill,
.liquid-light-theme .mat-warn .mat-slider-thumb,
.liquid-light-theme .mat-warn .mat-slider-thumb-label {
  background-color: #fb4e4e;
}
.liquid-light-theme .mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.liquid-light-theme .mat-warn .mat-slider-focus-ring {
  background-color: rgba(251, 78, 78, 0.2);
}
.liquid-light-theme .mat-slider:hover .mat-slider-track-background,
.liquid-light-theme .cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.liquid-light-theme .mat-slider-disabled .mat-slider-track-background,
.liquid-light-theme .mat-slider-disabled .mat-slider-track-fill,
.liquid-light-theme .mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.liquid-light-theme .mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.liquid-light-theme .mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.liquid-light-theme .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.liquid-light-theme .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.liquid-light-theme .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.liquid-light-theme .mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.liquid-light-theme .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .liquid-light-theme .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.liquid-light-theme .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .liquid-light-theme .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.liquid-light-theme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.liquid-light-theme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.liquid-light-theme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.liquid-light-theme .mat-step-header.cdk-keyboard-focused, .liquid-light-theme .mat-step-header.cdk-program-focused, .liquid-light-theme .mat-step-header:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .liquid-light-theme .mat-step-header:hover {
    background: none;
  }
}
.liquid-light-theme .mat-step-header .mat-step-label,
.liquid-light-theme .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.liquid-light-theme .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.liquid-light-theme .mat-step-header .mat-step-icon-selected,
.liquid-light-theme .mat-step-header .mat-step-icon-state-done,
.liquid-light-theme .mat-step-header .mat-step-icon-state-edit {
  background-color: #17161e;
  color: white;
}
.liquid-light-theme .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.liquid-light-theme .mat-step-header.mat-accent .mat-step-icon-selected,
.liquid-light-theme .mat-step-header.mat-accent .mat-step-icon-state-done,
.liquid-light-theme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #8e8aa9;
  color: white;
}
.liquid-light-theme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.liquid-light-theme .mat-step-header.mat-warn .mat-step-icon-selected,
.liquid-light-theme .mat-step-header.mat-warn .mat-step-icon-state-done,
.liquid-light-theme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #fb4e4e;
  color: white;
}
.liquid-light-theme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #fb4e4e;
}
.liquid-light-theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.liquid-light-theme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #fb4e4e;
}
.liquid-light-theme .mat-stepper-horizontal, .liquid-light-theme .mat-stepper-vertical {
  background-color: white;
}
.liquid-light-theme .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-horizontal-stepper-header::before,
.liquid-light-theme .mat-horizontal-stepper-header::after,
.liquid-light-theme .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.liquid-light-theme .mat-sort-header-arrow {
  color: #757575;
}
.liquid-light-theme .mat-tab-nav-bar,
.liquid-light-theme .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.liquid-light-theme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.liquid-light-theme .mat-tab-label, .liquid-light-theme .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.liquid-light-theme .mat-tab-label.mat-tab-disabled, .liquid-light-theme .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.liquid-light-theme .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.liquid-light-theme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.liquid-light-theme .mat-tab-group[class*=mat-background-] .mat-tab-header,
.liquid-light-theme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.liquid-light-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .liquid-light-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.liquid-light-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.liquid-light-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .liquid-light-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .liquid-light-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.liquid-light-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.liquid-light-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(170, 167, 191, 0.3);
}
.liquid-light-theme .mat-tab-group.mat-primary .mat-ink-bar, .liquid-light-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #17161e;
}
.liquid-light-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .liquid-light-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .liquid-light-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .liquid-light-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.liquid-light-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .liquid-light-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.liquid-light-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.liquid-light-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .liquid-light-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .liquid-light-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.liquid-light-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.liquid-light-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(247, 249, 251, 0.3);
}
.liquid-light-theme .mat-tab-group.mat-accent .mat-ink-bar, .liquid-light-theme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #8e8aa9;
}
.liquid-light-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .liquid-light-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .liquid-light-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .liquid-light-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.liquid-light-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .liquid-light-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.liquid-light-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.liquid-light-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .liquid-light-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .liquid-light-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.liquid-light-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.liquid-light-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 198, 198, 0.3);
}
.liquid-light-theme .mat-tab-group.mat-warn .mat-ink-bar, .liquid-light-theme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #fb4e4e;
}
.liquid-light-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .liquid-light-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .liquid-light-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .liquid-light-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.liquid-light-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .liquid-light-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.liquid-light-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.liquid-light-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .liquid-light-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .liquid-light-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.liquid-light-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.liquid-light-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(170, 167, 191, 0.3);
}
.liquid-light-theme .mat-tab-group.mat-background-primary > .mat-tab-header, .liquid-light-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .liquid-light-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .liquid-light-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .liquid-light-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .liquid-light-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #17161e;
}
.liquid-light-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .liquid-light-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .liquid-light-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .liquid-light-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.liquid-light-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .liquid-light-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .liquid-light-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .liquid-light-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.liquid-light-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.liquid-light-theme .mat-tab-group.mat-background-primary > .mat-tab-links .mat-focus-indicator::before,
.liquid-light-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .liquid-light-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.liquid-light-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-links .mat-focus-indicator::before,
.liquid-light-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.liquid-light-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .liquid-light-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.liquid-light-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.liquid-light-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element, .liquid-light-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.liquid-light-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.liquid-light-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .liquid-light-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.liquid-light-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.liquid-light-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .liquid-light-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .liquid-light-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.liquid-light-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.liquid-light-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(247, 249, 251, 0.3);
}
.liquid-light-theme .mat-tab-group.mat-background-accent > .mat-tab-header, .liquid-light-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .liquid-light-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .liquid-light-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .liquid-light-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .liquid-light-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #8e8aa9;
}
.liquid-light-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .liquid-light-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .liquid-light-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .liquid-light-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.liquid-light-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .liquid-light-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .liquid-light-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .liquid-light-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.liquid-light-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.liquid-light-theme .mat-tab-group.mat-background-accent > .mat-tab-links .mat-focus-indicator::before,
.liquid-light-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .liquid-light-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.liquid-light-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-links .mat-focus-indicator::before,
.liquid-light-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.liquid-light-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .liquid-light-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.liquid-light-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.liquid-light-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element, .liquid-light-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.liquid-light-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.liquid-light-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .liquid-light-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.liquid-light-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.liquid-light-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .liquid-light-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .liquid-light-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.liquid-light-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.liquid-light-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 198, 198, 0.3);
}
.liquid-light-theme .mat-tab-group.mat-background-warn > .mat-tab-header, .liquid-light-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .liquid-light-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .liquid-light-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .liquid-light-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .liquid-light-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #fb4e4e;
}
.liquid-light-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .liquid-light-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .liquid-light-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .liquid-light-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.liquid-light-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .liquid-light-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .liquid-light-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .liquid-light-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.liquid-light-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.liquid-light-theme .mat-tab-group.mat-background-warn > .mat-tab-links .mat-focus-indicator::before,
.liquid-light-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .liquid-light-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.liquid-light-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-links .mat-focus-indicator::before,
.liquid-light-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.liquid-light-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .liquid-light-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.liquid-light-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.liquid-light-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element, .liquid-light-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.liquid-light-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.liquid-light-theme .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.liquid-light-theme .mat-toolbar.mat-primary {
  background: #17161e;
  color: white;
}
.liquid-light-theme .mat-toolbar.mat-accent {
  background: #8e8aa9;
  color: white;
}
.liquid-light-theme .mat-toolbar.mat-warn {
  background: #fb4e4e;
  color: white;
}
.liquid-light-theme .mat-toolbar .mat-form-field-underline,
.liquid-light-theme .mat-toolbar .mat-form-field-ripple,
.liquid-light-theme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.liquid-light-theme .mat-toolbar .mat-form-field-label,
.liquid-light-theme .mat-toolbar .mat-focused .mat-form-field-label,
.liquid-light-theme .mat-toolbar .mat-select-value,
.liquid-light-theme .mat-toolbar .mat-select-arrow,
.liquid-light-theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.liquid-light-theme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }

  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.liquid-light-theme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.liquid-light-theme .mat-tree {
  background: white;
}
.liquid-light-theme .mat-tree-node,
.liquid-light-theme .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tree-node {
  min-height: 48px;
}

.liquid-light-theme .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-simple-snackbar-action {
  color: #8e8aa9;
}

.primary100 {
  color: #17161E;
}

.primary90 {
  color: #2e2c3b;
}

.primary80 {
  color: #444159;
}

.primary70 {
  color: #5b5776;
}

.primary60 {
  color: #716c94;
}

.primary50 {
  color: #8e8aa9;
}

.primary40 {
  color: #aaa7bf;
}

.primary30 {
  color: #c7c5d4;
}

.primary20 {
  color: #e3e2ea;
}

.primary10 {
  color: #f1f1f5;
}

.black100 {
  color: #25282B;
}

.black80 {
  color: #52575C;
}

.black60 {
  color: #A0A4A8;
}

.black40 {
  color: #CACCCF;
}

.black20 {
  color: #DBDDE0;
}

.black10 {
  color: #E8E8E8;
}

.black5 {
  color: #F7F7F7;
}

.white-default {
  color: #FFFFFF;
}

.white-dark {
  color: #F7F9FB;
}

.warning-default {
  color: #FFE4A5;
}

.warning-light {
  color: #FFF2D2;
}

.warning-dark {
  color: #CC8400;
}

.success-default {
  color: #2AC769;
}

.success-dark {
  color: #128718;
}

.success-light {
  color: #D1F9C6;
}

.error-default {
  color: #FB4E4E;
}

.error-dark {
  color: #E93C3C;
}

.error-light {
  color: #FFC6C6;
}

.accent-default {
  color: #4F24FA;
}

.bg-primary100 {
  background-color: #17161E;
}

.bg-primary90 {
  background-color: #2e2c3b;
}

.bg-primary80 {
  background-color: #444159;
}

.bg-primary70 {
  background-color: #5b5776;
}

.bg-primary60 {
  background-color: #716c94;
}

.bg-primary50 {
  background-color: #8e8aa9;
}

.bg-primary40 {
  background-color: #aaa7bf;
}

.bg-primary30 {
  background-color: #c7c5d4;
}

.bg-primary20 {
  background-color: #e3e2ea;
}

.bg-primary10 {
  background-color: #f1f1f5;
}

.bg-black100 {
  background-color: #25282B;
}

.bg-black80 {
  background-color: #52575C;
}

.bg-black60 {
  background-color: #A0A4A8;
}

.bg-black40 {
  background-color: #CACCCF;
}

.bg-black20 {
  background-color: #DBDDE0;
}

.bg-black10 {
  background-color: #E8E8E8;
}

.bg-black5 {
  background-color: #F7F7F7;
}

.bg-white-default {
  background-color: #FFFFFF;
}

.bg-white-dark {
  background-color: #F7F9FB;
}

.bg-warning-light {
  background-color: #FFF2D2;
}

.bg-warning-dark {
  background-color: #CC8400;
}

.bg-warning-default {
  background-color: #FFE4A5;
}

.bg-success-default {
  background-color: #2AC769;
}

.bg-success-dark {
  background-color: #128718;
}

.bg-success-light {
  background-color: #D1F9C6;
}

.bg-error-default {
  background-color: #FB4E4E;
}

.bg-error-dark {
  background-color: #E93C3C;
}

.bg-error-light {
  background-color: #FFC6C6;
}

.bg-accent-default {
  background-color: #4F24FA;
}

.fill-none {
  fill: none;
}

.fill-primary100 {
  fill: #17161E;
}

.fill-primary90 {
  fill: #2e2c3b;
}

.fill-primary80 {
  fill: #444159;
}

.fill-primary70 {
  fill: #5b5776;
}

.fill-primary60 {
  fill: #716c94;
}

.fill-primary50 {
  fill: #8e8aa9;
}

.fill-primary40 {
  fill: #aaa7bf;
}

.fill-primary30 {
  fill: #c7c5d4;
}

.fill-primary20 {
  fill: #e3e2ea;
}

.fill-primary10 {
  fill: #f1f1f5;
}

.fill-black100 {
  fill: #25282B;
}

.fill-black80 {
  fill: #52575C;
}

.fill-black60 {
  fill: #A0A4A8;
}

.fill-black40 {
  fill: #CACCCF;
}

.fill-black20 {
  fill: #DBDDE0;
}

.fill-black10 {
  fill: #E8E8E8;
}

.fill-black5 {
  fill: #F7F7F7;
}

.fill-white-default {
  fill: #FFFFFF;
}

.fill-white-dark {
  fill: #F7F9FB;
}

.fill-warning-light {
  fill: #FFF2D2;
}

.fill-warning-dark {
  fill: #CC8400;
}

.fill-warning-default {
  fill: #FFE4A5;
}

.fill-success-default {
  fill: #2AC769;
}

.fill-success-dark {
  fill: #128718;
}

.fill-success-light {
  fill: #D1F9C6;
}

.fill-error-default {
  fill: #FB4E4E;
}

.fill-error-dark {
  fill: #E93C3C;
}

.fill-error-light {
  fill: #FFC6C6;
}

.fill-accent-default {
  fill: #4F24FA;
}

.primary100 {
  color: #17161E;
}

.primary90 {
  color: #2e2c3b;
}

.primary80 {
  color: #444159;
}

.primary70 {
  color: #5b5776;
}

.primary60 {
  color: #716c94;
}

.primary50 {
  color: #8e8aa9;
}

.primary40 {
  color: #aaa7bf;
}

.primary30 {
  color: #c7c5d4;
}

.primary20 {
  color: #e3e2ea;
}

.primary10 {
  color: #f1f1f5;
}

.black100 {
  color: #25282B;
}

.black80 {
  color: #52575C;
}

.black60 {
  color: #A0A4A8;
}

.black40 {
  color: #CACCCF;
}

.black20 {
  color: #DBDDE0;
}

.black10 {
  color: #E8E8E8;
}

.black5 {
  color: #F7F7F7;
}

.white-default {
  color: #FFFFFF;
}

.white-dark {
  color: #F7F9FB;
}

.warning-default {
  color: #FFE4A5;
}

.warning-light {
  color: #FFF2D2;
}

.warning-dark {
  color: #CC8400;
}

.success-default {
  color: #2AC769;
}

.success-dark {
  color: #128718;
}

.success-light {
  color: #D1F9C6;
}

.error-default {
  color: #FB4E4E;
}

.error-dark {
  color: #E93C3C;
}

.error-light {
  color: #FFC6C6;
}

.accent-default {
  color: #4F24FA;
}

.bg-primary100 {
  background-color: #17161E;
}

.bg-primary90 {
  background-color: #2e2c3b;
}

.bg-primary80 {
  background-color: #444159;
}

.bg-primary70 {
  background-color: #5b5776;
}

.bg-primary60 {
  background-color: #716c94;
}

.bg-primary50 {
  background-color: #8e8aa9;
}

.bg-primary40 {
  background-color: #aaa7bf;
}

.bg-primary30 {
  background-color: #c7c5d4;
}

.bg-primary20 {
  background-color: #e3e2ea;
}

.bg-primary10 {
  background-color: #f1f1f5;
}

.bg-black100 {
  background-color: #25282B;
}

.bg-black80 {
  background-color: #52575C;
}

.bg-black60 {
  background-color: #A0A4A8;
}

.bg-black40 {
  background-color: #CACCCF;
}

.bg-black20 {
  background-color: #DBDDE0;
}

.bg-black10 {
  background-color: #E8E8E8;
}

.bg-black5 {
  background-color: #F7F7F7;
}

.bg-white-default {
  background-color: #FFFFFF;
}

.bg-white-dark {
  background-color: #F7F9FB;
}

.bg-warning-light {
  background-color: #FFF2D2;
}

.bg-warning-dark {
  background-color: #CC8400;
}

.bg-warning-default {
  background-color: #FFE4A5;
}

.bg-success-default {
  background-color: #2AC769;
}

.bg-success-dark {
  background-color: #128718;
}

.bg-success-light {
  background-color: #D1F9C6;
}

.bg-error-default {
  background-color: #FB4E4E;
}

.bg-error-dark {
  background-color: #E93C3C;
}

.bg-error-light {
  background-color: #FFC6C6;
}

.bg-accent-default {
  background-color: #4F24FA;
}

.fill-none {
  fill: none;
}

.fill-primary100 {
  fill: #17161E;
}

.fill-primary90 {
  fill: #2e2c3b;
}

.fill-primary80 {
  fill: #444159;
}

.fill-primary70 {
  fill: #5b5776;
}

.fill-primary60 {
  fill: #716c94;
}

.fill-primary50 {
  fill: #8e8aa9;
}

.fill-primary40 {
  fill: #aaa7bf;
}

.fill-primary30 {
  fill: #c7c5d4;
}

.fill-primary20 {
  fill: #e3e2ea;
}

.fill-primary10 {
  fill: #f1f1f5;
}

.fill-black100 {
  fill: #25282B;
}

.fill-black80 {
  fill: #52575C;
}

.fill-black60 {
  fill: #A0A4A8;
}

.fill-black40 {
  fill: #CACCCF;
}

.fill-black20 {
  fill: #DBDDE0;
}

.fill-black10 {
  fill: #E8E8E8;
}

.fill-black5 {
  fill: #F7F7F7;
}

.fill-white-default {
  fill: #FFFFFF;
}

.fill-white-dark {
  fill: #F7F9FB;
}

.fill-warning-light {
  fill: #FFF2D2;
}

.fill-warning-dark {
  fill: #CC8400;
}

.fill-warning-default {
  fill: #FFE4A5;
}

.fill-success-default {
  fill: #2AC769;
}

.fill-success-dark {
  fill: #128718;
}

.fill-success-light {
  fill: #D1F9C6;
}

.fill-error-default {
  fill: #FB4E4E;
}

.fill-error-dark {
  fill: #E93C3C;
}

.fill-error-light {
  fill: #FFC6C6;
}

.fill-accent-default {
  fill: #4F24FA;
}

* {
  box-sizing: border-box;
}

html,
body {
  background-color: #F7F9FB;
  color: #17161E;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 300;
  height: 100%;
  letter-spacing: 0px;
  margin: 0;
  min-height: 100vh;
  padding: 0;
  scrollbar-width: thin;
  scrollbar-color: #A0A4A8 transparent;
  overflow-x: hidden;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #A0A4A8;
  border-radius: 3px;
  border: none;
}

app-component {
  display: flex;
  min-height: 100vh;
  width: 100%;
}

app-liquid-app,
app-root-component,
app-root-component main {
  flex: 1;
  display: flex;
}

.router-outlet.main-app-router {
  flex: 1;
  display: flex;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin: 8px 0 16px 0;
}

h6,
.h6 {
  text-transform: uppercase;
}

h1,
.h1 {
  font-size: 32px;
  line-height: 40px;
  color: #17161E;
  font-weight: 300;
  letter-spacing: 0.2px;
}

h2,
.h2 {
  color: #17161E;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 26px;
}
@media (min-width: 600px) {
  h2,
.h2 {
    font-size: 26px;
    line-height: 32px;
  }
}

h3,
.h3 {
  font-size: 32px;
  line-height: 40px;
  color: #17161E;
  font-weight: 600;
  letter-spacing: 0.1px;
}

h4,
.h4 {
  font-size: 26px;
  line-height: 32px;
  color: #17161E;
  font-weight: 600;
  letter-spacing: 0.2px;
}

h5,
.h5 {
  font-size: 20px;
  line-height: 26px;
  color: #17161E;
  font-weight: 600;
  letter-spacing: 0.2px;
}

h6,
.h6 {
  font-size: 18px;
  line-height: 24px;
  color: #17161E;
  font-weight: 600;
  letter-spacing: 0.2px;
}

.text-h6 {
  color: #25282B;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 24px;
}

.subtitle1 {
  font-size: 18px;
  line-height: 24px;
  color: #25282B;
  letter-spacing: 0.1px;
  font-weight: bold;
  text-transform: initial;
}

.subtitle2 {
  font-size: 16px;
  line-height: 24px;
  color: #25282B;
  letter-spacing: 0.1px;
  font-weight: 600;
}

.subtitle3, .alert p {
  font-size: 14px;
  line-height: 18px;
  color: #25282B;
  letter-spacing: 0.1px;
  font-weight: 600;
}

p,
.body1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  letter-spacing: 0.1px;
  color: #25282B;
}

.body2 {
  font-size: 14px;
  line-height: 22px;
  font-weight: normal;
  letter-spacing: 0.1px;
  color: #25282B;
}

.body3 {
  font-size: 14px;
  line-height: 18px;
  font-weight: normal;
  color: #25282B;
  letter-spacing: 0.1px;
}

.small1, .mat-chip.chip-status-badge, .status-badge {
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  letter-spacing: 0.2px;
  color: #25282B;
}

.small2, .small3,
.small2 a,
.small3 a {
  font-size: 12px !important;
  line-height: 16px;
  font-weight: normal;
  letter-spacing: 0.2px;
}

.small2, .small3 {
  color: #25282B;
}

.small3 {
  font-size: 10px !important;
}

.button1 {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #25282B;
}

.button2 {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #25282B;
}

.initials1 {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #25282B;
}

.label1 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #25282B;
}

.label2 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #25282B;
}

.label3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #25282B;
}

.big1 {
  font-weight: 600;
  font-size: 56px;
  line-height: 32px;
  letter-spacing: 0.2px;
  color: #17161E;
}

.big2 {
  font-weight: bold;
  font-size: 50px;
  line-height: 32px;
  letter-spacing: 0.2px;
  color: #17161E;
}

.big3 {
  font-weight: bold;
  font-size: 22px !important;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #17161E;
}

.low-contrast {
  color: #A0A4A8 !important;
}

hr {
  border: 1px solid #DBDDE0;
}

.subheader2 {
  font-size: 16px;
  color: #25282B;
  margin-bottom: 10px;
}

.note {
  font-size: 12px;
  color: #aaa7bf;
  margin-bottom: 2px;
  margin-top: -15px;
  z-index: 1000 !important;
  position: relative;
}

.form-field-placeholder {
  padding-bottom: 1.25em;
  height: 1.125em;
}

.emph {
  font-weight: bold;
  font-style: italic;
  color: #FFC6C6;
}

caption {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.4px;
}

.overline {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.full-width {
  min-width: 100%;
}

.avatar {
  background-color: #FFFFFF;
}

div.logo-black {
  display: block;
  background: url("/assets/img/logo/liquid_logo.svg") no-repeat 0 0;
  background-size: 80px 36px;
  width: 80px;
  height: 36px;
}
div.logo-small {
  display: block;
  background: url("/assets/img/logo/Liquid_Graphic_Logo.svg") no-repeat 0 0;
  background-size: 29px 19px;
  width: 29px;
  height: 36px;
}
div.logo-white {
  display: block;
  background: url("/assets/img/logo/Liquid_Graphic_Logo.svg") no-repeat 0 0;
  background-size: 80px 36px;
  min-width: 80px;
  min-height: 36px;
}

.round {
  border-radius: 50%;
  padding: 4px;
}

.oval {
  border-radius: 20px;
  margin-left: 14px;
  padding: 0px 10px;
  font-size: 14px;
}

.removed,
.deleted {
  color: #aaa7bf;
}
.removed.round, .removed.oval,
.deleted.round,
.deleted.oval {
  background-color: rgba(170, 167, 191, 0.08);
}

.disabled {
  color: #A0A4A8;
}
.disabled.round, .disabled.oval {
  background-color: #FFFFFF !important;
}

.yellow {
  color: #FFE4A5 !important;
}
.yellow.round, .yellow.oval {
  background-color: #F7F7F7 !important;
}

.dark-green {
  color: #128718 !important;
}

i-feather.site-icon {
  font-size: 0;
  width: 30px;
  height: 30px;
  padding: 5px;
  margin-right: 10px;
}
i-feather.site-icon.big {
  width: 72px;
  height: 72px;
}

a {
  cursor: pointer;
  text-decoration: underline;
  font-weight: normal;
  font-size: 14px;
  color: #17161E;
}
a.mat-button {
  text-decoration: none;
}

.module-outlet > .ng-star-inserted {
  flex: 1;
}

.module-container {
  flex: 1 1 100%;
  display: flex !important;
  flex-direction: column;
  justify-content: flex-start;
}
.module-container .module-contents {
  flex: 1 1 100%;
  padding-top: 0;
  display: flex;
  flex-direction: column;
}
.module-container .module-contents .subnav-content-container {
  flex: 1 1 100%;
  display: flex;
  padding: 0 0 0 16px;
  margin-bottom: 0;
}
.module-container .module-contents .subnav-content-container .subnav-content {
  min-height: min-content;
  display: flex;
  flex: 1 1 100%;
  width: 100%;
}
.module-container .module-contents .subnav-content-container .subnav-content > .ng-star-inserted {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  width: 100%;
}
.module-container .subnav-mobile {
  background-color: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0px 5px 8px rgba(151, 181, 233, 0.15);
  margin-bottom: 16px;
}
@media (min-width: 600px) {
  .module-container .subnav-mobile {
    display: none;
  }
}
@media (max-width: 599px) {
  .module-container .subnav-mobile {
    margin-right: 0 !important;
  }
}
.module-container .subnav {
  display: none;
}
.module-container .subnav .mat-tab-link {
  min-width: initial;
  padding: 0 12px;
}
.module-container .subnav-wrap .mat-tab-header-pagination {
  display: none !important;
}
.module-container .subnav-wrap .mat-tab-links {
  flex-flow: wrap;
}
@media (min-width: 600px) {
  .module-container .subnav {
    display: flex;
  }
}
.module-container .subnav.no-bottom-border {
  border-bottom: 0;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.page-container {
  opacity: 1;
  position: absolute;
  top: 97px;
  bottom: 0px;
  left: 0;
  right: 0;
  overflow: auto;
}
.page-container .page-header-outer {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  padding: 10px 24px 10px 24px;
  margin-bottom: 16px;
}
.page-container .page-header-outer .page-header {
  flex: 1 1 auto;
}
.page-container .page-header-outer .page-header .header-text {
  color: #17161E;
  font-size: 32px;
  font-weight: 600;
  line-height: 42px;
  text-align: left;
  vertical-align: middle;
}
.page-container .page-header-outer .page-header .header-text .mat-input-element {
  font-size: 32px;
  line-height: 32px;
  text-align: left;
  vertical-align: middle;
}
.page-container .page-header-outer .page-header .header-text .subtext {
  font-size: 14px;
}
.page-container .page-header-outer .page-header .instruction {
  padding-top: 10px;
  font-size: 22px;
}
.page-container .page-header-outer .page-header-actions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.page-container .page-header-outer .page-header-actions button {
  margin: 8px;
}
.page-container .page-contents {
  padding: 0px 24px;
  color: #17161E !important;
}
.page-container .page-contents .contents-list {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  align-content: flex-start;
}
.page-container .page-footer {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  padding: 0px 24px;
  margin-top: 16px;
}
.page-container .page-footer .footer-left {
  flex: 0 1 auto;
}
.page-container .page-footer .footer-actions {
  flex: 1 1 auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
}
.page-container .page-actions {
  flex: 0 0 auto;
}

.item-editor .actions {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0px 22px 0px;
}

.container-with-sidenav {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.container-with-sidenav .sidenav {
  flex-grow: 0;
  margin: 0px 24px 0px 0px;
  overflow: auto;
  width: 260px;
  max-width: 260px;
}
.container-with-sidenav .sidenav .list-container {
  display: table;
  width: 100%;
}
.container-with-sidenav .sidenav .list-container .list-row {
  display: table-row;
}
.container-with-sidenav .sidenav .list-container .list-row .list-row-item {
  line-height: 22px;
  border-radius: 6px;
  font-size: 14px;
  display: table-cell;
  vertical-align: middle;
  padding: 12px 12px;
}
.container-with-sidenav .sidenav .list-container .list-row:not(:hover) {
  color: #A0A4A8;
}
.container-with-sidenav .sidenav .list-container .list-row:hover {
  cursor: pointer;
}
.container-with-sidenav .sidenav .list-container .list-row:hover .list-row-item {
  font-weight: bold;
}
.container-with-sidenav .sidenav .list-container .selected {
  cursor: pointer;
}
.container-with-sidenav .sidenav .list-container .selected .list-row-item {
  font-weight: bold;
}
.container-with-sidenav .contents {
  flex-grow: 1;
}
.container-with-sidenav .contents .mat-checkbox,
.container-with-sidenav .contents .mat-radio-button {
  font-size: 14px;
}

.page-card-contents {
  box-shadow: 2px 0px 14px #E8E8E8;
  background-color: #FFFFFF;
  padding: 30px;
  color: #17161E;
}

.pull-left {
  display: flex;
  justify-content: flex-start;
}

.pull-right {
  display: flex;
  justify-content: flex-end;
}

.flex-row {
  display: flex !important;
  flex-direction: row;
}

.center-aligned-flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mod-flex-row {
  display: flex !important;
  flex-direction: row !important;
}

.detail-box {
  display: flex;
  align-content: space-between;
  padding: 1em 0;
}

.action-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.w .w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-19 {
  width: 19% !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-33 {
  width: 33% !important;
}

.w-37 {
  width: 37% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-47 {
  width: 47% !important;
}

.w-49 {
  width: 49% !important;
}

.w-50 {
  width: 50% !important;
}

.w-55 {
  width: 55% !important;
}

.w-52 {
  width: 52% !important;
}

.w-59 {
  width: 59% !important;
}

.w-60 {
  width: 60% !important;
}

.w-63 {
  width: 63% !important;
}

.w-67 {
  width: 67% !important;
}

.w-70 {
  width: 70% !important;
}

.w-75 {
  width: 75% !important;
}

.w-79 {
  width: 79% !important;
}

.w-80 {
  width: 80% !important;
}

.w-98 {
  width: 98% !important;
}

.w-100 {
  width: 100% !important;
}

.no-margin {
  margin: 0;
}

.mr-1 {
  margin-right: 1% !important;
}

.mr-2 {
  margin-right: 2% !important;
}

.mr-5 {
  margin-right: 5% !important;
}

.mr-6 {
  margin-right: 6% !important;
}

.mr-666 {
  margin-right: 6.66% !important;
}

.mrv-5 {
  margin-right: 5px !important;
}

.mrv-10 {
  margin-right: 10px !important;
}

.mrv-16 {
  margin-right: 16px !important;
}

.mrv-24 {
  margin-right: 24px !important;
}

.mrv-30 {
  margin-right: 24px !important;
}

.mrv-48 {
  margin-right: 24px !important;
}

.mtv-10 {
  margin-top: 10px;
}

.mtv-16 {
  margin-top: 16px;
}

.mtv-20 {
  margin-top: 20px;
}

.mtv-40 {
  margin-top: 40px;
}

.mbv-5 {
  margin-bottom: 5px;
}

.mbv-10 {
  margin-bottom: 10px;
}

.mbv-16 {
  margin-bottom: 16px;
}

.mbv-20 {
  margin-bottom: 20px;
}

.mbv-24 {
  margin-bottom: 24px;
}

.mbv-30 {
  margin-bottom: 30px;
}

.pbv-10 {
  padding-bottom: 10px;
}

.mlv-5 {
  margin-left: 5px;
}

.mlv-10 {
  margin-left: 16px;
}

.mlv-16 {
  margin-left: 16px;
}

.mlv-24 {
  margin-left: 24px;
}

.pbv-10 {
  padding-bottom: 10px;
}

.pbv-20 {
  padding-bottom: 20px;
}

.ptv-10 {
  padding-top: 10px;
}

.ptv-20 {
  padding-top: 10px;
}

.ptv-30 {
  padding-top: 30px;
}

.pointer {
  cursor: pointer !important;
}

.page-sub-header-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 0px 51px 0px 24px;
  margin: 0px 0px 25px 0px;
}

.filter-sort {
  padding: 0px;
  margin: 0px;
  list-style: none;
  display: flex;
  flex-flow: wrap;
  justify-content: flex-start;
}
.filter-sort .filter,
.filter-sort .sort {
  min-width: 308px;
  max-width: 308px;
  width: 308px;
  font-size: 14px;
  margin-right: 27px;
}

.list-item-container {
  width: 100%;
}

.list-item {
  box-shadow: 2px 0px 14px #E8E8E8;
}
.list-item:hover {
  cursor: pointer;
  box-shadow: 2px 4px 20px #CACCCF;
}

.list-item.view-mode,
.list-item.edit-mode {
  width: calc(100% - 50px);
  margin-bottom: 16px;
  padding: 0px 25px;
  display: flex;
}
.list-item.view-mode.view-mode,
.list-item.edit-mode.view-mode {
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-content: center;
}
.list-item.view-mode.view-mode .item-name-container,
.list-item.edit-mode.view-mode .item-name-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  padding: 17px 0px;
  font-size: 22px;
}
.list-item.view-mode.view-mode .item-name-container .item-name,
.list-item.edit-mode.view-mode .item-name-container .item-name {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}
.list-item.view-mode.edit-mode,
.list-item.edit-mode.edit-mode {
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-content: center;
}
.list-item.view-mode.edit-mode .item-name-container,
.list-item.edit-mode.edit-mode .item-name-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  font-size: 22px;
  padding: 17px 0px;
}
.list-item.view-mode.edit-mode .item-name-container .item-name,
.list-item.edit-mode.edit-mode .item-name-container .item-name {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}
.list-item.view-mode.edit-mode .item-editor,
.list-item.edit-mode.edit-mode .item-editor {
  width: 100%;
}
.list-item.view-mode .item-actions.i-feather,
.list-item.edit-mode .item-actions.i-feather {
  margin-right: 16px;
}

.item-name-status {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}

b {
  font-weight: bold;
}

.pre-format {
  white-space: pre-wrap;
}

.table-container th button {
  text-transform: uppercase;
}

.alert {
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  margin: 1em 0;
  padding: 0 17px;
  position: relative;
}
.alert .close {
  position: absolute;
  top: 0;
  right: 0;
  color: #A0A4A8;
}
.alert p {
  color: inherit;
}

.alert-error {
  background-color: rgba(251, 78, 78, 0.04);
  border-color: #FB4E4E;
  color: #E93C3C;
}

.alert {
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  margin: 1em 0;
  padding: 0 17px;
  position: relative;
}
.alert .close {
  position: absolute;
  top: 0;
  right: 0;
  color: #A0A4A8;
}
.alert p {
  color: inherit;
}

.alert-error {
  background-color: rgba(251, 78, 78, 0.04);
  border-color: #FB4E4E;
  color: #E93C3C;
}

.no-list-type {
  list-style-type: none;
}

.checkbox-top-alignment .mat-checkbox-inner-container {
  margin-top: 4px;
}

/* STATUS BADGES */
.status-badge {
  text-align: center;
  display: inline-block;
  padding: 7px 12px;
  border-radius: 8px;
  text-transform: uppercase;
  color: #17161E;
  background-color: #f1f1f5;
}
.status-badge.clickable {
  cursor: pointer;
}
.status-badge.orange, .status-badge.warn {
  color: #CC8400;
  background-color: rgba(204, 132, 0, 0.3);
}
.status-badge.green, .status-badge.success {
  color: #128718;
  background-color: rgba(18, 135, 24, 0.3);
}
.status-badge.red, .status-badge.error {
  color: #E93C3C;
  background-color: rgba(233, 60, 60, 0.3);
}

/* NUMBER BADGE */
.number-badge-container {
  display: flex;
  width: 40px;
}
.number-badge-container .number-badge[number-badge]:after {
  content: attr(number-badge);
  height: 20px;
  min-width: 20px;
  text-align: center;
  border-radius: 50%;
  border: solid 3px #e3e2ea;
  float: left;
}

/* XS VIEWPORT */
.xs-only {
  display: none !important;
}
@media (max-width: 599px) {
  .xs-only {
    display: block !important;
  }
}

.xs-hide {
  display: block;
}
@media (max-width: 599px) {
  .xs-hide {
    display: none !important;
  }
}

@media (max-width: 599px) {
  .xs-bottom-divider {
    border-bottom: 1px solid #DBDDE0;
  }
}

@media (max-width: 599px) {
  .xs-top-divider {
    border-top: 1px solid #DBDDE0;
  }
}

.gtelg-only {
  display: none;
}
@media (min-width: 1440px) {
  .gtelg-only {
    display: block;
  }
}

.gtemd-only {
  display: none;
}
@media (min-width: 1024px) {
  .gtemd-only {
    display: block;
  }
}

.ltemd-only {
  display: none;
}
@media (max-width: 1439px) {
  .ltemd-only {
    display: block;
  }
}

.ltesm-only {
  display: none;
}
@media (max-width: 1023px) {
  .ltesm-only {
    display: block;
  }
}

.md-only {
  display: none;
}

.md-not {
  display: block;
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .md-only {
    display: block;
  }

  .md-not {
    display: none;
  }
}
.center-aligned {
  text-align: center;
}

.right-aligned {
  text-align: right;
}

.left-aligned {
  text-align: left;
}

.hidden {
  display: none !important;
}

.bottom-divider {
  border-bottom: 1px solid #DBDDE0;
}

.top-divider {
  border-top: 1px solid #DBDDE0;
}

.inline-error,
.inline-success,
.inline-warning {
  padding: 8px 20px;
  margin: 20px 0;
  width: 100%;
  border-radius: 8px;
  border: 1px solid;
  text-align: center;
}
.inline-error a,
.inline-error p,
.inline-success a,
.inline-success p,
.inline-warning a,
.inline-warning p {
  color: inherit !important;
}
.inline-error p,
.inline-success p,
.inline-warning p {
  margin-bottom: 25px;
}

.inline-error {
  color: #E93C3C;
  border-color: #FB4E4E;
  background-color: #FFC6C6;
}

.inline-success {
  color: #128718;
  border-color: #2AC769;
  background-color: #D1F9C6;
}

.inline-warning {
  color: #CC8400;
  border-color: #FFE4A5;
  background-color: #FFF2D2;
}

.bold-text {
  font-weight: bold;
}

pdf-viewer {
  display: block;
}
pdf-viewer.loaded .ng2-pdf-viewer-container {
  position: relative;
}

.spacer {
  flex: 1 1 auto;
}

.both-direction-centered {
  display: grid;
  place-items: center center;
}

.primary100 {
  color: #17161E;
}

.primary90 {
  color: #2e2c3b;
}

.primary80 {
  color: #444159;
}

.primary70 {
  color: #5b5776;
}

.primary60 {
  color: #716c94;
}

.primary50 {
  color: #8e8aa9;
}

.primary40 {
  color: #aaa7bf;
}

.primary30 {
  color: #c7c5d4;
}

.primary20 {
  color: #e3e2ea;
}

.primary10 {
  color: #f1f1f5;
}

.black100 {
  color: #25282B;
}

.black80 {
  color: #52575C;
}

.black60 {
  color: #A0A4A8;
}

.black40 {
  color: #CACCCF;
}

.black20 {
  color: #DBDDE0;
}

.black10 {
  color: #E8E8E8;
}

.black5 {
  color: #F7F7F7;
}

.white-default {
  color: #FFFFFF;
}

.white-dark {
  color: #F7F9FB;
}

.warning-default {
  color: #FFE4A5;
}

.warning-light {
  color: #FFF2D2;
}

.warning-dark {
  color: #CC8400;
}

.success-default {
  color: #2AC769;
}

.success-dark {
  color: #128718;
}

.success-light {
  color: #D1F9C6;
}

.error-default {
  color: #FB4E4E;
}

.error-dark {
  color: #E93C3C;
}

.error-light {
  color: #FFC6C6;
}

.accent-default {
  color: #4F24FA;
}

.bg-primary100 {
  background-color: #17161E;
}

.bg-primary90 {
  background-color: #2e2c3b;
}

.bg-primary80 {
  background-color: #444159;
}

.bg-primary70 {
  background-color: #5b5776;
}

.bg-primary60 {
  background-color: #716c94;
}

.bg-primary50 {
  background-color: #8e8aa9;
}

.bg-primary40 {
  background-color: #aaa7bf;
}

.bg-primary30 {
  background-color: #c7c5d4;
}

.bg-primary20 {
  background-color: #e3e2ea;
}

.bg-primary10 {
  background-color: #f1f1f5;
}

.bg-black100 {
  background-color: #25282B;
}

.bg-black80 {
  background-color: #52575C;
}

.bg-black60 {
  background-color: #A0A4A8;
}

.bg-black40 {
  background-color: #CACCCF;
}

.bg-black20 {
  background-color: #DBDDE0;
}

.bg-black10 {
  background-color: #E8E8E8;
}

.bg-black5 {
  background-color: #F7F7F7;
}

.bg-white-default {
  background-color: #FFFFFF;
}

.bg-white-dark {
  background-color: #F7F9FB;
}

.bg-warning-light {
  background-color: #FFF2D2;
}

.bg-warning-dark {
  background-color: #CC8400;
}

.bg-warning-default {
  background-color: #FFE4A5;
}

.bg-success-default {
  background-color: #2AC769;
}

.bg-success-dark {
  background-color: #128718;
}

.bg-success-light {
  background-color: #D1F9C6;
}

.bg-error-default {
  background-color: #FB4E4E;
}

.bg-error-dark {
  background-color: #E93C3C;
}

.bg-error-light {
  background-color: #FFC6C6;
}

.bg-accent-default {
  background-color: #4F24FA;
}

.fill-none {
  fill: none;
}

.fill-primary100 {
  fill: #17161E;
}

.fill-primary90 {
  fill: #2e2c3b;
}

.fill-primary80 {
  fill: #444159;
}

.fill-primary70 {
  fill: #5b5776;
}

.fill-primary60 {
  fill: #716c94;
}

.fill-primary50 {
  fill: #8e8aa9;
}

.fill-primary40 {
  fill: #aaa7bf;
}

.fill-primary30 {
  fill: #c7c5d4;
}

.fill-primary20 {
  fill: #e3e2ea;
}

.fill-primary10 {
  fill: #f1f1f5;
}

.fill-black100 {
  fill: #25282B;
}

.fill-black80 {
  fill: #52575C;
}

.fill-black60 {
  fill: #A0A4A8;
}

.fill-black40 {
  fill: #CACCCF;
}

.fill-black20 {
  fill: #DBDDE0;
}

.fill-black10 {
  fill: #E8E8E8;
}

.fill-black5 {
  fill: #F7F7F7;
}

.fill-white-default {
  fill: #FFFFFF;
}

.fill-white-dark {
  fill: #F7F9FB;
}

.fill-warning-light {
  fill: #FFF2D2;
}

.fill-warning-dark {
  fill: #CC8400;
}

.fill-warning-default {
  fill: #FFE4A5;
}

.fill-success-default {
  fill: #2AC769;
}

.fill-success-dark {
  fill: #128718;
}

.fill-success-light {
  fill: #D1F9C6;
}

.fill-error-default {
  fill: #FB4E4E;
}

.fill-error-dark {
  fill: #E93C3C;
}

.fill-error-light {
  fill: #FFC6C6;
}

.fill-accent-default {
  fill: #4F24FA;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
    transform-origin: center;
  }
  100% {
    transform: rotate(360deg);
  }
}
.primary100 {
  color: #17161E;
}

.primary90 {
  color: #2e2c3b;
}

.primary80 {
  color: #444159;
}

.primary70 {
  color: #5b5776;
}

.primary60 {
  color: #716c94;
}

.primary50 {
  color: #8e8aa9;
}

.primary40 {
  color: #aaa7bf;
}

.primary30 {
  color: #c7c5d4;
}

.primary20 {
  color: #e3e2ea;
}

.primary10 {
  color: #f1f1f5;
}

.black100 {
  color: #25282B;
}

.black80 {
  color: #52575C;
}

.black60 {
  color: #A0A4A8;
}

.black40 {
  color: #CACCCF;
}

.black20 {
  color: #DBDDE0;
}

.black10 {
  color: #E8E8E8;
}

.black5 {
  color: #F7F7F7;
}

.white-default {
  color: #FFFFFF;
}

.white-dark {
  color: #F7F9FB;
}

.warning-default {
  color: #FFE4A5;
}

.warning-light {
  color: #FFF2D2;
}

.warning-dark {
  color: #CC8400;
}

.success-default {
  color: #2AC769;
}

.success-dark {
  color: #128718;
}

.success-light {
  color: #D1F9C6;
}

.error-default {
  color: #FB4E4E;
}

.error-dark {
  color: #E93C3C;
}

.error-light {
  color: #FFC6C6;
}

.accent-default {
  color: #4F24FA;
}

.bg-primary100 {
  background-color: #17161E;
}

.bg-primary90 {
  background-color: #2e2c3b;
}

.bg-primary80 {
  background-color: #444159;
}

.bg-primary70 {
  background-color: #5b5776;
}

.bg-primary60 {
  background-color: #716c94;
}

.bg-primary50 {
  background-color: #8e8aa9;
}

.bg-primary40 {
  background-color: #aaa7bf;
}

.bg-primary30 {
  background-color: #c7c5d4;
}

.bg-primary20 {
  background-color: #e3e2ea;
}

.bg-primary10 {
  background-color: #f1f1f5;
}

.bg-black100 {
  background-color: #25282B;
}

.bg-black80 {
  background-color: #52575C;
}

.bg-black60 {
  background-color: #A0A4A8;
}

.bg-black40 {
  background-color: #CACCCF;
}

.bg-black20 {
  background-color: #DBDDE0;
}

.bg-black10 {
  background-color: #E8E8E8;
}

.bg-black5 {
  background-color: #F7F7F7;
}

.bg-white-default {
  background-color: #FFFFFF;
}

.bg-white-dark {
  background-color: #F7F9FB;
}

.bg-warning-light {
  background-color: #FFF2D2;
}

.bg-warning-dark {
  background-color: #CC8400;
}

.bg-warning-default {
  background-color: #FFE4A5;
}

.bg-success-default {
  background-color: #2AC769;
}

.bg-success-dark {
  background-color: #128718;
}

.bg-success-light {
  background-color: #D1F9C6;
}

.bg-error-default {
  background-color: #FB4E4E;
}

.bg-error-dark {
  background-color: #E93C3C;
}

.bg-error-light {
  background-color: #FFC6C6;
}

.bg-accent-default {
  background-color: #4F24FA;
}

.fill-none {
  fill: none;
}

.fill-primary100 {
  fill: #17161E;
}

.fill-primary90 {
  fill: #2e2c3b;
}

.fill-primary80 {
  fill: #444159;
}

.fill-primary70 {
  fill: #5b5776;
}

.fill-primary60 {
  fill: #716c94;
}

.fill-primary50 {
  fill: #8e8aa9;
}

.fill-primary40 {
  fill: #aaa7bf;
}

.fill-primary30 {
  fill: #c7c5d4;
}

.fill-primary20 {
  fill: #e3e2ea;
}

.fill-primary10 {
  fill: #f1f1f5;
}

.fill-black100 {
  fill: #25282B;
}

.fill-black80 {
  fill: #52575C;
}

.fill-black60 {
  fill: #A0A4A8;
}

.fill-black40 {
  fill: #CACCCF;
}

.fill-black20 {
  fill: #DBDDE0;
}

.fill-black10 {
  fill: #E8E8E8;
}

.fill-black5 {
  fill: #F7F7F7;
}

.fill-white-default {
  fill: #FFFFFF;
}

.fill-white-dark {
  fill: #F7F9FB;
}

.fill-warning-light {
  fill: #FFF2D2;
}

.fill-warning-dark {
  fill: #CC8400;
}

.fill-warning-default {
  fill: #FFE4A5;
}

.fill-success-default {
  fill: #2AC769;
}

.fill-success-dark {
  fill: #128718;
}

.fill-success-light {
  fill: #D1F9C6;
}

.fill-error-default {
  fill: #FB4E4E;
}

.fill-error-dark {
  fill: #E93C3C;
}

.fill-error-light {
  fill: #FFC6C6;
}

.fill-accent-default {
  fill: #4F24FA;
}

.liquid-light-theme .mat-accordion.filter-accordion {
  border-radius: 8px;
  max-height: 500px;
  overflow-y: auto;
}
@media (max-width: 599px) {
  .liquid-light-theme .mat-accordion.filter-accordion {
    max-width: 300px !important;
  }
}
.liquid-light-theme .mat-accordion.filter-accordion .mat-expansion-panel {
  box-shadow: none;
}
.liquid-light-theme .mat-accordion.filter-accordion .mat-expansion-panel:first-of-type .mat-expansion-panel-header {
  border-top-color: transparent;
}
.liquid-light-theme .mat-accordion.filter-accordion .mat-expansion-panel.no-header {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.liquid-light-theme .mat-accordion.filter-accordion .mat-expansion-panel.no-header .mat-expansion-panel-body {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.liquid-light-theme .mat-accordion.filter-accordion .mat-expansion-panel-spacing {
  margin: 0;
}
.liquid-light-theme .mat-accordion.filter-accordion .mat-expansion-panel-header {
  height: 60px !important;
  padding: 0 15px;
  border-radius: 0;
  border-top: 1px solid #DBDDE0;
  border-bottom: 1px solid transparent;
}
.liquid-light-theme .mat-accordion.filter-accordion .mat-expansion-panel-header.mat-expanded {
  border-bottom-color: #DBDDE0;
}
.liquid-light-theme .mat-accordion.filter-accordion .mat-expansion-panel-header .mat-expansion-panel-header-title {
  align-items: center;
  justify-content: space-between;
  color: #17161E;
}
.liquid-light-theme .mat-accordion.filter-accordion .mat-expansion-panel-body {
  padding: 14px 8px;
  display: flex;
  flex-direction: column;
}
.liquid-light-theme .mat-accordion.filter-accordion .mat-expansion-panel-body .clear {
  width: auto !important;
}
.liquid-light-theme .mat-accordion.filter-accordion .mat-expansion-panel-body .clear .mat-button-wrapper {
  flex: 1;
  justify-content: space-between;
}
.liquid-light-theme .mat-accordion.filter-accordion .mat-expansion-panel-body .option-wrapper {
  margin: 4px 0;
  padding: 8px;
}
.liquid-light-theme .mat-accordion.filter-accordion .mat-expansion-panel-body .option-wrapper .mat-button-wrapper {
  flex: 1;
  justify-content: space-between;
}
.liquid-light-theme .mat-accordion.filter-accordion .mat-expansion-panel-body .option-wrapper .mat-button-wrapper span.label {
  flex: 1;
  text-align: left;
  margin-left: 6px;
}
.liquid-light-theme .mat-accordion.filter-accordion .mat-expansion-panel.title .mat-expansion-panel-body {
  padding: 4px 4px 4px 16px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.liquid-light-theme .mat-accordion.filter-accordion .mat-expansion-panel.title .mat-expansion-panel-body span.label {
  line-height: 48px;
}
.liquid-light-theme .mat-accordion.filter-accordion .mat-expansion-panel.no-header .mat-expansion-panel-body {
  padding-top: 0;
}
.liquid-light-theme .mat-accordion.sort-select {
  z-index: 1000;
  max-height: 500px;
  overflow-y: auto !important;
  background-color: #FFFFFF;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-accordion.sort-select .mat-expansion-panel-body {
  padding: 16px;
}
.liquid-light-theme .mat-accordion.sort-select .mat-expansion-panel-body button {
  width: 100%;
}
.liquid-light-theme .mat-accordion .icon-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: solid 3px #e3e2ea;
  float: left;
}
.liquid-light-theme .mat-accordion .icon-badge i-feather {
  padding: 0px;
  margin: 0px;
  width: 15px;
  height: 15px;
}
.liquid-light-theme .mat-accordion .group-badge[group-badge].active {
  font-weight: bold;
}
.liquid-light-theme .mat-accordion .group-badge[group-badge].active:after {
  border: solid 3px #aaa7bf;
}
.liquid-light-theme .mat-accordion .group-badge[group-badge]:after {
  content: attr(group-badge);
  height: 20px;
  min-width: 20px;
  text-align: center;
  border-radius: 50%;
  border: solid 3px #e3e2ea;
  float: left;
}
.liquid-light-theme .cdk-overlay-connected-position-bounding-box {
  overflow-y: auto;
}
.liquid-light-theme .workflow-snack {
  border-radius: 8px;
  color: #E93C3C;
  background-color: #FFC6C6;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .workflow-snack .mat-simple-snackbar {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
}

.primary100 {
  color: #17161E;
}

.primary90 {
  color: #2e2c3b;
}

.primary80 {
  color: #444159;
}

.primary70 {
  color: #5b5776;
}

.primary60 {
  color: #716c94;
}

.primary50 {
  color: #8e8aa9;
}

.primary40 {
  color: #aaa7bf;
}

.primary30 {
  color: #c7c5d4;
}

.primary20 {
  color: #e3e2ea;
}

.primary10 {
  color: #f1f1f5;
}

.black100 {
  color: #25282B;
}

.black80 {
  color: #52575C;
}

.black60 {
  color: #A0A4A8;
}

.black40 {
  color: #CACCCF;
}

.black20 {
  color: #DBDDE0;
}

.black10 {
  color: #E8E8E8;
}

.black5 {
  color: #F7F7F7;
}

.white-default {
  color: #FFFFFF;
}

.white-dark {
  color: #F7F9FB;
}

.warning-default {
  color: #FFE4A5;
}

.warning-light {
  color: #FFF2D2;
}

.warning-dark {
  color: #CC8400;
}

.success-default {
  color: #2AC769;
}

.success-dark {
  color: #128718;
}

.success-light {
  color: #D1F9C6;
}

.error-default {
  color: #FB4E4E;
}

.error-dark {
  color: #E93C3C;
}

.error-light {
  color: #FFC6C6;
}

.accent-default {
  color: #4F24FA;
}

.bg-primary100 {
  background-color: #17161E;
}

.bg-primary90 {
  background-color: #2e2c3b;
}

.bg-primary80 {
  background-color: #444159;
}

.bg-primary70 {
  background-color: #5b5776;
}

.bg-primary60 {
  background-color: #716c94;
}

.bg-primary50 {
  background-color: #8e8aa9;
}

.bg-primary40 {
  background-color: #aaa7bf;
}

.bg-primary30 {
  background-color: #c7c5d4;
}

.bg-primary20 {
  background-color: #e3e2ea;
}

.bg-primary10 {
  background-color: #f1f1f5;
}

.bg-black100 {
  background-color: #25282B;
}

.bg-black80 {
  background-color: #52575C;
}

.bg-black60 {
  background-color: #A0A4A8;
}

.bg-black40 {
  background-color: #CACCCF;
}

.bg-black20 {
  background-color: #DBDDE0;
}

.bg-black10 {
  background-color: #E8E8E8;
}

.bg-black5 {
  background-color: #F7F7F7;
}

.bg-white-default {
  background-color: #FFFFFF;
}

.bg-white-dark {
  background-color: #F7F9FB;
}

.bg-warning-light {
  background-color: #FFF2D2;
}

.bg-warning-dark {
  background-color: #CC8400;
}

.bg-warning-default {
  background-color: #FFE4A5;
}

.bg-success-default {
  background-color: #2AC769;
}

.bg-success-dark {
  background-color: #128718;
}

.bg-success-light {
  background-color: #D1F9C6;
}

.bg-error-default {
  background-color: #FB4E4E;
}

.bg-error-dark {
  background-color: #E93C3C;
}

.bg-error-light {
  background-color: #FFC6C6;
}

.bg-accent-default {
  background-color: #4F24FA;
}

.fill-none {
  fill: none;
}

.fill-primary100 {
  fill: #17161E;
}

.fill-primary90 {
  fill: #2e2c3b;
}

.fill-primary80 {
  fill: #444159;
}

.fill-primary70 {
  fill: #5b5776;
}

.fill-primary60 {
  fill: #716c94;
}

.fill-primary50 {
  fill: #8e8aa9;
}

.fill-primary40 {
  fill: #aaa7bf;
}

.fill-primary30 {
  fill: #c7c5d4;
}

.fill-primary20 {
  fill: #e3e2ea;
}

.fill-primary10 {
  fill: #f1f1f5;
}

.fill-black100 {
  fill: #25282B;
}

.fill-black80 {
  fill: #52575C;
}

.fill-black60 {
  fill: #A0A4A8;
}

.fill-black40 {
  fill: #CACCCF;
}

.fill-black20 {
  fill: #DBDDE0;
}

.fill-black10 {
  fill: #E8E8E8;
}

.fill-black5 {
  fill: #F7F7F7;
}

.fill-white-default {
  fill: #FFFFFF;
}

.fill-white-dark {
  fill: #F7F9FB;
}

.fill-warning-light {
  fill: #FFF2D2;
}

.fill-warning-dark {
  fill: #CC8400;
}

.fill-warning-default {
  fill: #FFE4A5;
}

.fill-success-default {
  fill: #2AC769;
}

.fill-success-dark {
  fill: #128718;
}

.fill-success-light {
  fill: #D1F9C6;
}

.fill-error-default {
  fill: #FB4E4E;
}

.fill-error-dark {
  fill: #E93C3C;
}

.fill-error-light {
  fill: #FFC6C6;
}

.fill-accent-default {
  fill: #4F24FA;
}

* {
  box-sizing: border-box;
}

html,
body {
  background-color: #F7F9FB;
  color: #17161E;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 300;
  height: 100%;
  letter-spacing: 0px;
  margin: 0;
  min-height: 100vh;
  padding: 0;
  scrollbar-width: thin;
  scrollbar-color: #A0A4A8 transparent;
  overflow-x: hidden;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #A0A4A8;
  border-radius: 3px;
  border: none;
}

app-component {
  display: flex;
  min-height: 100vh;
  width: 100%;
}

app-liquid-app,
app-root-component,
app-root-component main {
  flex: 1;
  display: flex;
}

.router-outlet.main-app-router {
  flex: 1;
  display: flex;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin: 8px 0 16px 0;
}

h6,
.h6 {
  text-transform: uppercase;
}

h1,
.h1 {
  font-size: 32px;
  line-height: 40px;
  color: #17161E;
  font-weight: 300;
  letter-spacing: 0.2px;
}

h2,
.h2 {
  color: #17161E;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 26px;
}
@media (min-width: 600px) {
  h2,
.h2 {
    font-size: 26px;
    line-height: 32px;
  }
}

h3,
.h3 {
  font-size: 32px;
  line-height: 40px;
  color: #17161E;
  font-weight: 600;
  letter-spacing: 0.1px;
}

h4,
.h4 {
  font-size: 26px;
  line-height: 32px;
  color: #17161E;
  font-weight: 600;
  letter-spacing: 0.2px;
}

h5,
.h5 {
  font-size: 20px;
  line-height: 26px;
  color: #17161E;
  font-weight: 600;
  letter-spacing: 0.2px;
}

h6,
.h6 {
  font-size: 18px;
  line-height: 24px;
  color: #17161E;
  font-weight: 600;
  letter-spacing: 0.2px;
}

.text-h6 {
  color: #25282B;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 24px;
}

.subtitle1 {
  font-size: 18px;
  line-height: 24px;
  color: #25282B;
  letter-spacing: 0.1px;
  font-weight: bold;
  text-transform: initial;
}

.subtitle2 {
  font-size: 16px;
  line-height: 24px;
  color: #25282B;
  letter-spacing: 0.1px;
  font-weight: 600;
}

.subtitle3, .alert p {
  font-size: 14px;
  line-height: 18px;
  color: #25282B;
  letter-spacing: 0.1px;
  font-weight: 600;
}

p,
.body1 {
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  letter-spacing: 0.1px;
  color: #25282B;
}

.body2 {
  font-size: 14px;
  line-height: 22px;
  font-weight: normal;
  letter-spacing: 0.1px;
  color: #25282B;
}

.body3 {
  font-size: 14px;
  line-height: 18px;
  font-weight: normal;
  color: #25282B;
  letter-spacing: 0.1px;
}

.small1, .mat-chip.chip-status-badge, .status-badge {
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  letter-spacing: 0.2px;
  color: #25282B;
}

.small2, .small3,
.small2 a,
.small3 a {
  font-size: 12px !important;
  line-height: 16px;
  font-weight: normal;
  letter-spacing: 0.2px;
}

.small2, .small3 {
  color: #25282B;
}

.small3 {
  font-size: 10px !important;
}

.button1 {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #25282B;
}

.button2 {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #25282B;
}

.initials1 {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #25282B;
}

.label1 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #25282B;
}

.label2 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #25282B;
}

.label3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #25282B;
}

.big1 {
  font-weight: 600;
  font-size: 56px;
  line-height: 32px;
  letter-spacing: 0.2px;
  color: #17161E;
}

.big2 {
  font-weight: bold;
  font-size: 50px;
  line-height: 32px;
  letter-spacing: 0.2px;
  color: #17161E;
}

.big3 {
  font-weight: bold;
  font-size: 22px !important;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #17161E;
}

.low-contrast {
  color: #A0A4A8 !important;
}

hr {
  border: 1px solid #DBDDE0;
}

.subheader2 {
  font-size: 16px;
  color: #25282B;
  margin-bottom: 10px;
}

.note {
  font-size: 12px;
  color: #aaa7bf;
  margin-bottom: 2px;
  margin-top: -15px;
  z-index: 1000 !important;
  position: relative;
}

.form-field-placeholder {
  padding-bottom: 1.25em;
  height: 1.125em;
}

.emph {
  font-weight: bold;
  font-style: italic;
  color: #FFC6C6;
}

caption {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.4px;
}

.overline {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.full-width {
  min-width: 100%;
}

.avatar {
  background-color: #FFFFFF;
}

div.logo-black {
  display: block;
  background: url("/assets/img/logo/liquid_logo.svg") no-repeat 0 0;
  background-size: 80px 36px;
  width: 80px;
  height: 36px;
}
div.logo-small {
  display: block;
  background: url("/assets/img/logo/Liquid_Graphic_Logo.svg") no-repeat 0 0;
  background-size: 29px 19px;
  width: 29px;
  height: 36px;
}
div.logo-white {
  display: block;
  background: url("/assets/img/logo/Liquid_Graphic_Logo.svg") no-repeat 0 0;
  background-size: 80px 36px;
  min-width: 80px;
  min-height: 36px;
}

.round {
  border-radius: 50%;
  padding: 4px;
}

.oval {
  border-radius: 20px;
  margin-left: 14px;
  padding: 0px 10px;
  font-size: 14px;
}

.removed,
.deleted {
  color: #aaa7bf;
}
.removed.round, .removed.oval,
.deleted.round,
.deleted.oval {
  background-color: rgba(170, 167, 191, 0.08);
}

.disabled {
  color: #A0A4A8;
}
.disabled.round, .disabled.oval {
  background-color: #FFFFFF !important;
}

.yellow {
  color: #FFE4A5 !important;
}
.yellow.round, .yellow.oval {
  background-color: #F7F7F7 !important;
}

.dark-green {
  color: #128718 !important;
}

i-feather.site-icon {
  font-size: 0;
  width: 30px;
  height: 30px;
  padding: 5px;
  margin-right: 10px;
}
i-feather.site-icon.big {
  width: 72px;
  height: 72px;
}

a {
  cursor: pointer;
  text-decoration: underline;
  font-weight: normal;
  font-size: 14px;
  color: #17161E;
}
a.mat-button {
  text-decoration: none;
}

.module-outlet > .ng-star-inserted {
  flex: 1;
}

.module-container {
  flex: 1 1 100%;
  display: flex !important;
  flex-direction: column;
  justify-content: flex-start;
}
.module-container .module-contents {
  flex: 1 1 100%;
  padding-top: 0;
  display: flex;
  flex-direction: column;
}
.module-container .module-contents .subnav-content-container {
  flex: 1 1 100%;
  display: flex;
  padding: 0 0 0 16px;
  margin-bottom: 0;
}
.module-container .module-contents .subnav-content-container .subnav-content {
  min-height: min-content;
  display: flex;
  flex: 1 1 100%;
  width: 100%;
}
.module-container .module-contents .subnav-content-container .subnav-content > .ng-star-inserted {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  width: 100%;
}
.module-container .subnav-mobile {
  background-color: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0px 5px 8px rgba(151, 181, 233, 0.15);
  margin-bottom: 16px;
}
@media (min-width: 600px) {
  .module-container .subnav-mobile {
    display: none;
  }
}
@media (max-width: 599px) {
  .module-container .subnav-mobile {
    margin-right: 0 !important;
  }
}
.module-container .subnav {
  display: none;
}
.module-container .subnav .mat-tab-link {
  min-width: initial;
  padding: 0 12px;
}
.module-container .subnav-wrap .mat-tab-header-pagination {
  display: none !important;
}
.module-container .subnav-wrap .mat-tab-links {
  flex-flow: wrap;
}
@media (min-width: 600px) {
  .module-container .subnav {
    display: flex;
  }
}
.module-container .subnav.no-bottom-border {
  border-bottom: 0;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.page-container {
  opacity: 1;
  position: absolute;
  top: 97px;
  bottom: 0px;
  left: 0;
  right: 0;
  overflow: auto;
}
.page-container .page-header-outer {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  padding: 10px 24px 10px 24px;
  margin-bottom: 16px;
}
.page-container .page-header-outer .page-header {
  flex: 1 1 auto;
}
.page-container .page-header-outer .page-header .header-text {
  color: #17161E;
  font-size: 32px;
  font-weight: 600;
  line-height: 42px;
  text-align: left;
  vertical-align: middle;
}
.page-container .page-header-outer .page-header .header-text .mat-input-element {
  font-size: 32px;
  line-height: 32px;
  text-align: left;
  vertical-align: middle;
}
.page-container .page-header-outer .page-header .header-text .subtext {
  font-size: 14px;
}
.page-container .page-header-outer .page-header .instruction {
  padding-top: 10px;
  font-size: 22px;
}
.page-container .page-header-outer .page-header-actions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.page-container .page-header-outer .page-header-actions button {
  margin: 8px;
}
.page-container .page-contents {
  padding: 0px 24px;
  color: #17161E !important;
}
.page-container .page-contents .contents-list {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  align-content: flex-start;
}
.page-container .page-footer {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  padding: 0px 24px;
  margin-top: 16px;
}
.page-container .page-footer .footer-left {
  flex: 0 1 auto;
}
.page-container .page-footer .footer-actions {
  flex: 1 1 auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
}
.page-container .page-actions {
  flex: 0 0 auto;
}

.item-editor .actions {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0px 22px 0px;
}

.container-with-sidenav {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.container-with-sidenav .sidenav {
  flex-grow: 0;
  margin: 0px 24px 0px 0px;
  overflow: auto;
  width: 260px;
  max-width: 260px;
}
.container-with-sidenav .sidenav .list-container {
  display: table;
  width: 100%;
}
.container-with-sidenav .sidenav .list-container .list-row {
  display: table-row;
}
.container-with-sidenav .sidenav .list-container .list-row .list-row-item {
  line-height: 22px;
  border-radius: 6px;
  font-size: 14px;
  display: table-cell;
  vertical-align: middle;
  padding: 12px 12px;
}
.container-with-sidenav .sidenav .list-container .list-row:not(:hover) {
  color: #A0A4A8;
}
.container-with-sidenav .sidenav .list-container .list-row:hover {
  cursor: pointer;
}
.container-with-sidenav .sidenav .list-container .list-row:hover .list-row-item {
  font-weight: bold;
}
.container-with-sidenav .sidenav .list-container .selected {
  cursor: pointer;
}
.container-with-sidenav .sidenav .list-container .selected .list-row-item {
  font-weight: bold;
}
.container-with-sidenav .contents {
  flex-grow: 1;
}
.container-with-sidenav .contents .mat-checkbox,
.container-with-sidenav .contents .mat-radio-button {
  font-size: 14px;
}

.page-card-contents {
  box-shadow: 2px 0px 14px #E8E8E8;
  background-color: #FFFFFF;
  padding: 30px;
  color: #17161E;
}

.pull-left {
  display: flex;
  justify-content: flex-start;
}

.pull-right {
  display: flex;
  justify-content: flex-end;
}

.flex-row {
  display: flex !important;
  flex-direction: row;
}

.center-aligned-flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mod-flex-row {
  display: flex !important;
  flex-direction: row !important;
}

.detail-box {
  display: flex;
  align-content: space-between;
  padding: 1em 0;
}

.action-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.w .w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-19 {
  width: 19% !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-33 {
  width: 33% !important;
}

.w-37 {
  width: 37% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-47 {
  width: 47% !important;
}

.w-49 {
  width: 49% !important;
}

.w-50 {
  width: 50% !important;
}

.w-55 {
  width: 55% !important;
}

.w-52 {
  width: 52% !important;
}

.w-59 {
  width: 59% !important;
}

.w-60 {
  width: 60% !important;
}

.w-63 {
  width: 63% !important;
}

.w-67 {
  width: 67% !important;
}

.w-70 {
  width: 70% !important;
}

.w-75 {
  width: 75% !important;
}

.w-79 {
  width: 79% !important;
}

.w-80 {
  width: 80% !important;
}

.w-98 {
  width: 98% !important;
}

.w-100 {
  width: 100% !important;
}

.no-margin {
  margin: 0;
}

.mr-1 {
  margin-right: 1% !important;
}

.mr-2 {
  margin-right: 2% !important;
}

.mr-5 {
  margin-right: 5% !important;
}

.mr-6 {
  margin-right: 6% !important;
}

.mr-666 {
  margin-right: 6.66% !important;
}

.mrv-5 {
  margin-right: 5px !important;
}

.mrv-10 {
  margin-right: 10px !important;
}

.mrv-16 {
  margin-right: 16px !important;
}

.mrv-24 {
  margin-right: 24px !important;
}

.mrv-30 {
  margin-right: 24px !important;
}

.mrv-48 {
  margin-right: 24px !important;
}

.mtv-10 {
  margin-top: 10px;
}

.mtv-16 {
  margin-top: 16px;
}

.mtv-20 {
  margin-top: 20px;
}

.mtv-40 {
  margin-top: 40px;
}

.mbv-5 {
  margin-bottom: 5px;
}

.mbv-10 {
  margin-bottom: 10px;
}

.mbv-16 {
  margin-bottom: 16px;
}

.mbv-20 {
  margin-bottom: 20px;
}

.mbv-24 {
  margin-bottom: 24px;
}

.mbv-30 {
  margin-bottom: 30px;
}

.pbv-10 {
  padding-bottom: 10px;
}

.mlv-5 {
  margin-left: 5px;
}

.mlv-10 {
  margin-left: 16px;
}

.mlv-16 {
  margin-left: 16px;
}

.mlv-24 {
  margin-left: 24px;
}

.pbv-10 {
  padding-bottom: 10px;
}

.pbv-20 {
  padding-bottom: 20px;
}

.ptv-10 {
  padding-top: 10px;
}

.ptv-20 {
  padding-top: 10px;
}

.ptv-30 {
  padding-top: 30px;
}

.pointer {
  cursor: pointer !important;
}

.page-sub-header-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 0px 51px 0px 24px;
  margin: 0px 0px 25px 0px;
}

.filter-sort {
  padding: 0px;
  margin: 0px;
  list-style: none;
  display: flex;
  flex-flow: wrap;
  justify-content: flex-start;
}
.filter-sort .filter,
.filter-sort .sort {
  min-width: 308px;
  max-width: 308px;
  width: 308px;
  font-size: 14px;
  margin-right: 27px;
}

.list-item-container {
  width: 100%;
}

.list-item {
  box-shadow: 2px 0px 14px #E8E8E8;
}
.list-item:hover {
  cursor: pointer;
  box-shadow: 2px 4px 20px #CACCCF;
}

.list-item.view-mode,
.list-item.edit-mode {
  width: calc(100% - 50px);
  margin-bottom: 16px;
  padding: 0px 25px;
  display: flex;
}
.list-item.view-mode.view-mode,
.list-item.edit-mode.view-mode {
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-content: center;
}
.list-item.view-mode.view-mode .item-name-container,
.list-item.edit-mode.view-mode .item-name-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  padding: 17px 0px;
  font-size: 22px;
}
.list-item.view-mode.view-mode .item-name-container .item-name,
.list-item.edit-mode.view-mode .item-name-container .item-name {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}
.list-item.view-mode.edit-mode,
.list-item.edit-mode.edit-mode {
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-content: center;
}
.list-item.view-mode.edit-mode .item-name-container,
.list-item.edit-mode.edit-mode .item-name-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  font-size: 22px;
  padding: 17px 0px;
}
.list-item.view-mode.edit-mode .item-name-container .item-name,
.list-item.edit-mode.edit-mode .item-name-container .item-name {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}
.list-item.view-mode.edit-mode .item-editor,
.list-item.edit-mode.edit-mode .item-editor {
  width: 100%;
}
.list-item.view-mode .item-actions.i-feather,
.list-item.edit-mode .item-actions.i-feather {
  margin-right: 16px;
}

.item-name-status {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}

b {
  font-weight: bold;
}

.pre-format {
  white-space: pre-wrap;
}

.table-container th button {
  text-transform: uppercase;
}

.alert {
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  margin: 1em 0;
  padding: 0 17px;
  position: relative;
}
.alert .close {
  position: absolute;
  top: 0;
  right: 0;
  color: #A0A4A8;
}
.alert p {
  color: inherit;
}

.alert-error {
  background-color: rgba(251, 78, 78, 0.04);
  border-color: #FB4E4E;
  color: #E93C3C;
}

.alert {
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  margin: 1em 0;
  padding: 0 17px;
  position: relative;
}
.alert .close {
  position: absolute;
  top: 0;
  right: 0;
  color: #A0A4A8;
}
.alert p {
  color: inherit;
}

.alert-error {
  background-color: rgba(251, 78, 78, 0.04);
  border-color: #FB4E4E;
  color: #E93C3C;
}

.no-list-type {
  list-style-type: none;
}

.checkbox-top-alignment .mat-checkbox-inner-container {
  margin-top: 4px;
}

/* STATUS BADGES */
.status-badge {
  text-align: center;
  display: inline-block;
  padding: 7px 12px;
  border-radius: 8px;
  text-transform: uppercase;
  color: #17161E;
  background-color: #f1f1f5;
}
.status-badge.clickable {
  cursor: pointer;
}
.status-badge.orange, .status-badge.warn {
  color: #CC8400;
  background-color: rgba(204, 132, 0, 0.3);
}
.status-badge.green, .status-badge.success {
  color: #128718;
  background-color: rgba(18, 135, 24, 0.3);
}
.status-badge.red, .status-badge.error {
  color: #E93C3C;
  background-color: rgba(233, 60, 60, 0.3);
}

/* NUMBER BADGE */
.number-badge-container {
  display: flex;
  width: 40px;
}
.number-badge-container .number-badge[number-badge]:after {
  content: attr(number-badge);
  height: 20px;
  min-width: 20px;
  text-align: center;
  border-radius: 50%;
  border: solid 3px #e3e2ea;
  float: left;
}

/* XS VIEWPORT */
.xs-only {
  display: none !important;
}
@media (max-width: 599px) {
  .xs-only {
    display: block !important;
  }
}

.xs-hide {
  display: block;
}
@media (max-width: 599px) {
  .xs-hide {
    display: none !important;
  }
}

@media (max-width: 599px) {
  .xs-bottom-divider {
    border-bottom: 1px solid #DBDDE0;
  }
}

@media (max-width: 599px) {
  .xs-top-divider {
    border-top: 1px solid #DBDDE0;
  }
}

.gtelg-only {
  display: none;
}
@media (min-width: 1440px) {
  .gtelg-only {
    display: block;
  }
}

.gtemd-only {
  display: none;
}
@media (min-width: 1024px) {
  .gtemd-only {
    display: block;
  }
}

.ltemd-only {
  display: none;
}
@media (max-width: 1439px) {
  .ltemd-only {
    display: block;
  }
}

.ltesm-only {
  display: none;
}
@media (max-width: 1023px) {
  .ltesm-only {
    display: block;
  }
}

.md-only {
  display: none;
}

.md-not {
  display: block;
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .md-only {
    display: block;
  }

  .md-not {
    display: none;
  }
}
.center-aligned {
  text-align: center;
}

.right-aligned {
  text-align: right;
}

.left-aligned {
  text-align: left;
}

.hidden {
  display: none !important;
}

.bottom-divider {
  border-bottom: 1px solid #DBDDE0;
}

.top-divider {
  border-top: 1px solid #DBDDE0;
}

.inline-error,
.inline-success,
.inline-warning {
  padding: 8px 20px;
  margin: 20px 0;
  width: 100%;
  border-radius: 8px;
  border: 1px solid;
  text-align: center;
}
.inline-error a,
.inline-error p,
.inline-success a,
.inline-success p,
.inline-warning a,
.inline-warning p {
  color: inherit !important;
}
.inline-error p,
.inline-success p,
.inline-warning p {
  margin-bottom: 25px;
}

.inline-error {
  color: #E93C3C;
  border-color: #FB4E4E;
  background-color: #FFC6C6;
}

.inline-success {
  color: #128718;
  border-color: #2AC769;
  background-color: #D1F9C6;
}

.inline-warning {
  color: #CC8400;
  border-color: #FFE4A5;
  background-color: #FFF2D2;
}

.bold-text {
  font-weight: bold;
}

pdf-viewer {
  display: block;
}
pdf-viewer.loaded .ng2-pdf-viewer-container {
  position: relative;
}

.spacer {
  flex: 1 1 auto;
}

.both-direction-centered {
  display: grid;
  place-items: center center;
}

.primary100 {
  color: #17161E;
}

.primary90 {
  color: #2e2c3b;
}

.primary80 {
  color: #444159;
}

.primary70 {
  color: #5b5776;
}

.primary60 {
  color: #716c94;
}

.primary50 {
  color: #8e8aa9;
}

.primary40 {
  color: #aaa7bf;
}

.primary30 {
  color: #c7c5d4;
}

.primary20 {
  color: #e3e2ea;
}

.primary10 {
  color: #f1f1f5;
}

.black100 {
  color: #25282B;
}

.black80 {
  color: #52575C;
}

.black60 {
  color: #A0A4A8;
}

.black40 {
  color: #CACCCF;
}

.black20 {
  color: #DBDDE0;
}

.black10 {
  color: #E8E8E8;
}

.black5 {
  color: #F7F7F7;
}

.white-default {
  color: #FFFFFF;
}

.white-dark {
  color: #F7F9FB;
}

.warning-default {
  color: #FFE4A5;
}

.warning-light {
  color: #FFF2D2;
}

.warning-dark {
  color: #CC8400;
}

.success-default {
  color: #2AC769;
}

.success-dark {
  color: #128718;
}

.success-light {
  color: #D1F9C6;
}

.error-default {
  color: #FB4E4E;
}

.error-dark {
  color: #E93C3C;
}

.error-light {
  color: #FFC6C6;
}

.accent-default {
  color: #4F24FA;
}

.bg-primary100 {
  background-color: #17161E;
}

.bg-primary90 {
  background-color: #2e2c3b;
}

.bg-primary80 {
  background-color: #444159;
}

.bg-primary70 {
  background-color: #5b5776;
}

.bg-primary60 {
  background-color: #716c94;
}

.bg-primary50 {
  background-color: #8e8aa9;
}

.bg-primary40 {
  background-color: #aaa7bf;
}

.bg-primary30 {
  background-color: #c7c5d4;
}

.bg-primary20 {
  background-color: #e3e2ea;
}

.bg-primary10 {
  background-color: #f1f1f5;
}

.bg-black100 {
  background-color: #25282B;
}

.bg-black80 {
  background-color: #52575C;
}

.bg-black60 {
  background-color: #A0A4A8;
}

.bg-black40 {
  background-color: #CACCCF;
}

.bg-black20 {
  background-color: #DBDDE0;
}

.bg-black10 {
  background-color: #E8E8E8;
}

.bg-black5 {
  background-color: #F7F7F7;
}

.bg-white-default {
  background-color: #FFFFFF;
}

.bg-white-dark {
  background-color: #F7F9FB;
}

.bg-warning-light {
  background-color: #FFF2D2;
}

.bg-warning-dark {
  background-color: #CC8400;
}

.bg-warning-default {
  background-color: #FFE4A5;
}

.bg-success-default {
  background-color: #2AC769;
}

.bg-success-dark {
  background-color: #128718;
}

.bg-success-light {
  background-color: #D1F9C6;
}

.bg-error-default {
  background-color: #FB4E4E;
}

.bg-error-dark {
  background-color: #E93C3C;
}

.bg-error-light {
  background-color: #FFC6C6;
}

.bg-accent-default {
  background-color: #4F24FA;
}

.fill-none {
  fill: none;
}

.fill-primary100 {
  fill: #17161E;
}

.fill-primary90 {
  fill: #2e2c3b;
}

.fill-primary80 {
  fill: #444159;
}

.fill-primary70 {
  fill: #5b5776;
}

.fill-primary60 {
  fill: #716c94;
}

.fill-primary50 {
  fill: #8e8aa9;
}

.fill-primary40 {
  fill: #aaa7bf;
}

.fill-primary30 {
  fill: #c7c5d4;
}

.fill-primary20 {
  fill: #e3e2ea;
}

.fill-primary10 {
  fill: #f1f1f5;
}

.fill-black100 {
  fill: #25282B;
}

.fill-black80 {
  fill: #52575C;
}

.fill-black60 {
  fill: #A0A4A8;
}

.fill-black40 {
  fill: #CACCCF;
}

.fill-black20 {
  fill: #DBDDE0;
}

.fill-black10 {
  fill: #E8E8E8;
}

.fill-black5 {
  fill: #F7F7F7;
}

.fill-white-default {
  fill: #FFFFFF;
}

.fill-white-dark {
  fill: #F7F9FB;
}

.fill-warning-light {
  fill: #FFF2D2;
}

.fill-warning-dark {
  fill: #CC8400;
}

.fill-warning-default {
  fill: #FFE4A5;
}

.fill-success-default {
  fill: #2AC769;
}

.fill-success-dark {
  fill: #128718;
}

.fill-success-light {
  fill: #D1F9C6;
}

.fill-error-default {
  fill: #FB4E4E;
}

.fill-error-dark {
  fill: #E93C3C;
}

.fill-error-light {
  fill: #FFC6C6;
}

.fill-accent-default {
  fill: #4F24FA;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
    transform-origin: center;
  }
  100% {
    transform: rotate(360deg);
  }
}
.primary100 {
  color: #17161E;
}

.primary90 {
  color: #2e2c3b;
}

.primary80 {
  color: #444159;
}

.primary70 {
  color: #5b5776;
}

.primary60 {
  color: #716c94;
}

.primary50 {
  color: #8e8aa9;
}

.primary40 {
  color: #aaa7bf;
}

.primary30 {
  color: #c7c5d4;
}

.primary20 {
  color: #e3e2ea;
}

.primary10 {
  color: #f1f1f5;
}

.black100 {
  color: #25282B;
}

.black80 {
  color: #52575C;
}

.black60 {
  color: #A0A4A8;
}

.black40 {
  color: #CACCCF;
}

.black20 {
  color: #DBDDE0;
}

.black10 {
  color: #E8E8E8;
}

.black5 {
  color: #F7F7F7;
}

.white-default {
  color: #FFFFFF;
}

.white-dark {
  color: #F7F9FB;
}

.warning-default {
  color: #FFE4A5;
}

.warning-light {
  color: #FFF2D2;
}

.warning-dark {
  color: #CC8400;
}

.success-default {
  color: #2AC769;
}

.success-dark {
  color: #128718;
}

.success-light {
  color: #D1F9C6;
}

.error-default {
  color: #FB4E4E;
}

.error-dark {
  color: #E93C3C;
}

.error-light {
  color: #FFC6C6;
}

.accent-default {
  color: #4F24FA;
}

.bg-primary100 {
  background-color: #17161E;
}

.bg-primary90 {
  background-color: #2e2c3b;
}

.bg-primary80 {
  background-color: #444159;
}

.bg-primary70 {
  background-color: #5b5776;
}

.bg-primary60 {
  background-color: #716c94;
}

.bg-primary50 {
  background-color: #8e8aa9;
}

.bg-primary40 {
  background-color: #aaa7bf;
}

.bg-primary30 {
  background-color: #c7c5d4;
}

.bg-primary20 {
  background-color: #e3e2ea;
}

.bg-primary10 {
  background-color: #f1f1f5;
}

.bg-black100 {
  background-color: #25282B;
}

.bg-black80 {
  background-color: #52575C;
}

.bg-black60 {
  background-color: #A0A4A8;
}

.bg-black40 {
  background-color: #CACCCF;
}

.bg-black20 {
  background-color: #DBDDE0;
}

.bg-black10 {
  background-color: #E8E8E8;
}

.bg-black5 {
  background-color: #F7F7F7;
}

.bg-white-default {
  background-color: #FFFFFF;
}

.bg-white-dark {
  background-color: #F7F9FB;
}

.bg-warning-light {
  background-color: #FFF2D2;
}

.bg-warning-dark {
  background-color: #CC8400;
}

.bg-warning-default {
  background-color: #FFE4A5;
}

.bg-success-default {
  background-color: #2AC769;
}

.bg-success-dark {
  background-color: #128718;
}

.bg-success-light {
  background-color: #D1F9C6;
}

.bg-error-default {
  background-color: #FB4E4E;
}

.bg-error-dark {
  background-color: #E93C3C;
}

.bg-error-light {
  background-color: #FFC6C6;
}

.bg-accent-default {
  background-color: #4F24FA;
}

.fill-none {
  fill: none;
}

.fill-primary100 {
  fill: #17161E;
}

.fill-primary90 {
  fill: #2e2c3b;
}

.fill-primary80 {
  fill: #444159;
}

.fill-primary70 {
  fill: #5b5776;
}

.fill-primary60 {
  fill: #716c94;
}

.fill-primary50 {
  fill: #8e8aa9;
}

.fill-primary40 {
  fill: #aaa7bf;
}

.fill-primary30 {
  fill: #c7c5d4;
}

.fill-primary20 {
  fill: #e3e2ea;
}

.fill-primary10 {
  fill: #f1f1f5;
}

.fill-black100 {
  fill: #25282B;
}

.fill-black80 {
  fill: #52575C;
}

.fill-black60 {
  fill: #A0A4A8;
}

.fill-black40 {
  fill: #CACCCF;
}

.fill-black20 {
  fill: #DBDDE0;
}

.fill-black10 {
  fill: #E8E8E8;
}

.fill-black5 {
  fill: #F7F7F7;
}

.fill-white-default {
  fill: #FFFFFF;
}

.fill-white-dark {
  fill: #F7F9FB;
}

.fill-warning-light {
  fill: #FFF2D2;
}

.fill-warning-dark {
  fill: #CC8400;
}

.fill-warning-default {
  fill: #FFE4A5;
}

.fill-success-default {
  fill: #2AC769;
}

.fill-success-dark {
  fill: #128718;
}

.fill-success-light {
  fill: #D1F9C6;
}

.fill-error-default {
  fill: #FB4E4E;
}

.fill-error-dark {
  fill: #E93C3C;
}

.fill-error-light {
  fill: #FFC6C6;
}

.fill-accent-default {
  fill: #4F24FA;
}

.liquid-light-theme .mat-button-wrapper {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.liquid-light-theme .mat-button-wrapper i-feather:not(.nav-icon-icon) {
  margin: -1px 3px 3px -3px;
  height: 20px;
  width: 20px;
}
.liquid-light-theme .mat-button.cdk-focused .mat-button-focus-overlay {
  opacity: 0;
}
.liquid-light-theme .mat-button-focus-overlay {
  background-color: #17161E;
}
.liquid-light-theme .mat-button:not([disabled]):hover .mat-button-focus-overlay {
  opacity: 0.06;
}
.liquid-light-theme .mat-button-base {
  display: flex;
  align-items: center;
  height: 48px;
  border-radius: 8px;
  color: #17161E;
  flex-shrink: 0;
  font-weight: 600;
}
.liquid-light-theme .mat-button-base:disabled i-feather {
  color: #CACCCF;
}
.liquid-light-theme .mat-button.no-borders {
  height: auto;
  width: auto;
  padding: 0;
}
.liquid-light-theme .mat-button.no-borders :hover {
  text-decoration: underline;
}
.liquid-light-theme .mat-button.no-borders .mat-button-focus-overlay {
  background-color: transparent;
}
.liquid-light-theme .mat-button.no-borders .mat-ripple {
  display: none;
}
.liquid-light-theme .mat-button.no-borders:disabled {
  background-color: transparent;
}
.liquid-light-theme .mat-button.no-borders:disabled :hover {
  text-decoration: none;
}
.liquid-light-theme .mat-flat-button {
  display: flex;
  min-width: 100px;
  justify-content: center;
  align-items: center;
}
.liquid-light-theme .mat-stroked-button {
  border: 1px solid #17161E;
  background-color: #FFFFFF;
  display: flex;
  min-width: 100px;
  justify-content: center;
  align-items: center;
}
.liquid-light-theme .mat-stroked-button:disabled {
  border-color: #DBDDE0;
}
.liquid-light-theme .mat-stroked-button:disabled .mat-button-wrapper {
  color: #DBDDE0;
}
.liquid-light-theme .mat-stroked-button:not(:disabled).mat-warn {
  border-color: #FB4E4E;
}
.liquid-light-theme .mat-stroked-button.image-upload-button {
  display: flex;
  justify-content: center;
  border-style: dashed;
  background-color: #F7F7F7;
}
.liquid-light-theme .mat-stroked-button.image-upload-button .upload-button-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.liquid-light-theme .mat-stroked-button.image-upload-button .upload-button-content i-feather {
  color: #A0A4A8;
  margin-bottom: 4px;
  height: 24px;
  width: 24px;
}
.liquid-light-theme .mat-icon-button {
  height: 40px;
  width: 40px;
  justify-content: center;
  align-items: center;
}
.liquid-light-theme .mat-icon-button .mat-button-focus-overlay {
  background-color: transparent;
}
.liquid-light-theme .mat-icon-button .mat-button-wrapper i-feather {
  height: 20px;
  width: 20px;
  margin: 0px 0px 0px 0px;
}
.liquid-light-theme .mat-icon-button .mat-ripple {
  display: none;
}
.liquid-light-theme .mat-mini-fab,
.liquid-light-theme .mat-fab {
  height: 20px;
  width: 20px;
  justify-content: center;
  border-radius: 50%;
}
.liquid-light-theme .mat-mini-fab i-feather:not(.nav-icon-icon),
.liquid-light-theme .mat-fab i-feather:not(.nav-icon-icon) {
  margin: 0;
  width: 16px;
  height: 16px;
}
.liquid-light-theme .mat-mini-fab .mat-button-wrapper,
.liquid-light-theme .mat-fab .mat-button-wrapper {
  padding: 0;
}

.primary100 {
  color: #17161E;
}

.primary90 {
  color: #2e2c3b;
}

.primary80 {
  color: #444159;
}

.primary70 {
  color: #5b5776;
}

.primary60 {
  color: #716c94;
}

.primary50 {
  color: #8e8aa9;
}

.primary40 {
  color: #aaa7bf;
}

.primary30 {
  color: #c7c5d4;
}

.primary20 {
  color: #e3e2ea;
}

.primary10 {
  color: #f1f1f5;
}

.black100 {
  color: #25282B;
}

.black80 {
  color: #52575C;
}

.black60 {
  color: #A0A4A8;
}

.black40 {
  color: #CACCCF;
}

.black20 {
  color: #DBDDE0;
}

.black10 {
  color: #E8E8E8;
}

.black5 {
  color: #F7F7F7;
}

.white-default {
  color: #FFFFFF;
}

.white-dark {
  color: #F7F9FB;
}

.warning-default {
  color: #FFE4A5;
}

.warning-light {
  color: #FFF2D2;
}

.warning-dark {
  color: #CC8400;
}

.success-default {
  color: #2AC769;
}

.success-dark {
  color: #128718;
}

.success-light {
  color: #D1F9C6;
}

.error-default {
  color: #FB4E4E;
}

.error-dark {
  color: #E93C3C;
}

.error-light {
  color: #FFC6C6;
}

.accent-default {
  color: #4F24FA;
}

.bg-primary100 {
  background-color: #17161E;
}

.bg-primary90 {
  background-color: #2e2c3b;
}

.bg-primary80 {
  background-color: #444159;
}

.bg-primary70 {
  background-color: #5b5776;
}

.bg-primary60 {
  background-color: #716c94;
}

.bg-primary50 {
  background-color: #8e8aa9;
}

.bg-primary40 {
  background-color: #aaa7bf;
}

.bg-primary30 {
  background-color: #c7c5d4;
}

.bg-primary20 {
  background-color: #e3e2ea;
}

.bg-primary10 {
  background-color: #f1f1f5;
}

.bg-black100 {
  background-color: #25282B;
}

.bg-black80 {
  background-color: #52575C;
}

.bg-black60 {
  background-color: #A0A4A8;
}

.bg-black40 {
  background-color: #CACCCF;
}

.bg-black20 {
  background-color: #DBDDE0;
}

.bg-black10 {
  background-color: #E8E8E8;
}

.bg-black5 {
  background-color: #F7F7F7;
}

.bg-white-default {
  background-color: #FFFFFF;
}

.bg-white-dark {
  background-color: #F7F9FB;
}

.bg-warning-light {
  background-color: #FFF2D2;
}

.bg-warning-dark {
  background-color: #CC8400;
}

.bg-warning-default {
  background-color: #FFE4A5;
}

.bg-success-default {
  background-color: #2AC769;
}

.bg-success-dark {
  background-color: #128718;
}

.bg-success-light {
  background-color: #D1F9C6;
}

.bg-error-default {
  background-color: #FB4E4E;
}

.bg-error-dark {
  background-color: #E93C3C;
}

.bg-error-light {
  background-color: #FFC6C6;
}

.bg-accent-default {
  background-color: #4F24FA;
}

.fill-none {
  fill: none;
}

.fill-primary100 {
  fill: #17161E;
}

.fill-primary90 {
  fill: #2e2c3b;
}

.fill-primary80 {
  fill: #444159;
}

.fill-primary70 {
  fill: #5b5776;
}

.fill-primary60 {
  fill: #716c94;
}

.fill-primary50 {
  fill: #8e8aa9;
}

.fill-primary40 {
  fill: #aaa7bf;
}

.fill-primary30 {
  fill: #c7c5d4;
}

.fill-primary20 {
  fill: #e3e2ea;
}

.fill-primary10 {
  fill: #f1f1f5;
}

.fill-black100 {
  fill: #25282B;
}

.fill-black80 {
  fill: #52575C;
}

.fill-black60 {
  fill: #A0A4A8;
}

.fill-black40 {
  fill: #CACCCF;
}

.fill-black20 {
  fill: #DBDDE0;
}

.fill-black10 {
  fill: #E8E8E8;
}

.fill-black5 {
  fill: #F7F7F7;
}

.fill-white-default {
  fill: #FFFFFF;
}

.fill-white-dark {
  fill: #F7F9FB;
}

.fill-warning-light {
  fill: #FFF2D2;
}

.fill-warning-dark {
  fill: #CC8400;
}

.fill-warning-default {
  fill: #FFE4A5;
}

.fill-success-default {
  fill: #2AC769;
}

.fill-success-dark {
  fill: #128718;
}

.fill-success-light {
  fill: #D1F9C6;
}

.fill-error-default {
  fill: #FB4E4E;
}

.fill-error-dark {
  fill: #E93C3C;
}

.fill-error-light {
  fill: #FFC6C6;
}

.fill-accent-default {
  fill: #4F24FA;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
    transform-origin: center;
  }
  100% {
    transform: rotate(360deg);
  }
}
.primary100 {
  color: #17161E;
}

.primary90 {
  color: #2e2c3b;
}

.primary80 {
  color: #444159;
}

.primary70 {
  color: #5b5776;
}

.primary60 {
  color: #716c94;
}

.primary50 {
  color: #8e8aa9;
}

.primary40 {
  color: #aaa7bf;
}

.primary30 {
  color: #c7c5d4;
}

.primary20 {
  color: #e3e2ea;
}

.primary10 {
  color: #f1f1f5;
}

.black100 {
  color: #25282B;
}

.black80 {
  color: #52575C;
}

.black60 {
  color: #A0A4A8;
}

.black40 {
  color: #CACCCF;
}

.black20 {
  color: #DBDDE0;
}

.black10 {
  color: #E8E8E8;
}

.black5 {
  color: #F7F7F7;
}

.white-default {
  color: #FFFFFF;
}

.white-dark {
  color: #F7F9FB;
}

.warning-default {
  color: #FFE4A5;
}

.warning-light {
  color: #FFF2D2;
}

.warning-dark {
  color: #CC8400;
}

.success-default {
  color: #2AC769;
}

.success-dark {
  color: #128718;
}

.success-light {
  color: #D1F9C6;
}

.error-default {
  color: #FB4E4E;
}

.error-dark {
  color: #E93C3C;
}

.error-light {
  color: #FFC6C6;
}

.accent-default {
  color: #4F24FA;
}

.bg-primary100 {
  background-color: #17161E;
}

.bg-primary90 {
  background-color: #2e2c3b;
}

.bg-primary80 {
  background-color: #444159;
}

.bg-primary70 {
  background-color: #5b5776;
}

.bg-primary60 {
  background-color: #716c94;
}

.bg-primary50 {
  background-color: #8e8aa9;
}

.bg-primary40 {
  background-color: #aaa7bf;
}

.bg-primary30 {
  background-color: #c7c5d4;
}

.bg-primary20 {
  background-color: #e3e2ea;
}

.bg-primary10 {
  background-color: #f1f1f5;
}

.bg-black100 {
  background-color: #25282B;
}

.bg-black80 {
  background-color: #52575C;
}

.bg-black60 {
  background-color: #A0A4A8;
}

.bg-black40 {
  background-color: #CACCCF;
}

.bg-black20 {
  background-color: #DBDDE0;
}

.bg-black10 {
  background-color: #E8E8E8;
}

.bg-black5 {
  background-color: #F7F7F7;
}

.bg-white-default {
  background-color: #FFFFFF;
}

.bg-white-dark {
  background-color: #F7F9FB;
}

.bg-warning-light {
  background-color: #FFF2D2;
}

.bg-warning-dark {
  background-color: #CC8400;
}

.bg-warning-default {
  background-color: #FFE4A5;
}

.bg-success-default {
  background-color: #2AC769;
}

.bg-success-dark {
  background-color: #128718;
}

.bg-success-light {
  background-color: #D1F9C6;
}

.bg-error-default {
  background-color: #FB4E4E;
}

.bg-error-dark {
  background-color: #E93C3C;
}

.bg-error-light {
  background-color: #FFC6C6;
}

.bg-accent-default {
  background-color: #4F24FA;
}

.fill-none {
  fill: none;
}

.fill-primary100 {
  fill: #17161E;
}

.fill-primary90 {
  fill: #2e2c3b;
}

.fill-primary80 {
  fill: #444159;
}

.fill-primary70 {
  fill: #5b5776;
}

.fill-primary60 {
  fill: #716c94;
}

.fill-primary50 {
  fill: #8e8aa9;
}

.fill-primary40 {
  fill: #aaa7bf;
}

.fill-primary30 {
  fill: #c7c5d4;
}

.fill-primary20 {
  fill: #e3e2ea;
}

.fill-primary10 {
  fill: #f1f1f5;
}

.fill-black100 {
  fill: #25282B;
}

.fill-black80 {
  fill: #52575C;
}

.fill-black60 {
  fill: #A0A4A8;
}

.fill-black40 {
  fill: #CACCCF;
}

.fill-black20 {
  fill: #DBDDE0;
}

.fill-black10 {
  fill: #E8E8E8;
}

.fill-black5 {
  fill: #F7F7F7;
}

.fill-white-default {
  fill: #FFFFFF;
}

.fill-white-dark {
  fill: #F7F9FB;
}

.fill-warning-light {
  fill: #FFF2D2;
}

.fill-warning-dark {
  fill: #CC8400;
}

.fill-warning-default {
  fill: #FFE4A5;
}

.fill-success-default {
  fill: #2AC769;
}

.fill-success-dark {
  fill: #128718;
}

.fill-success-light {
  fill: #D1F9C6;
}

.fill-error-default {
  fill: #FB4E4E;
}

.fill-error-dark {
  fill: #E93C3C;
}

.fill-error-light {
  fill: #FFC6C6;
}

.fill-accent-default {
  fill: #4F24FA;
}

.liquid-light-theme .mat-card {
  border-radius: 6px;
  margin-bottom: 1em;
  flex: 1;
}
.liquid-light-theme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .mat-card .mat-card-header-text {
  flex: 1;
}
.liquid-light-theme .mat-card .mat-card-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.liquid-light-theme .mat-card .mat-card-actions button:not(:last-child) {
  margin-right: 8px;
}
.liquid-light-theme .mat-card.zoom:hover {
  z-index: 1000;
  transform: scale3d(1.1, 1.1, 1);
}
.liquid-light-theme .mat-card-header .mat-card-header-text {
  margin: 0px !important;
}

.primary100 {
  color: #17161E;
}

.primary90 {
  color: #2e2c3b;
}

.primary80 {
  color: #444159;
}

.primary70 {
  color: #5b5776;
}

.primary60 {
  color: #716c94;
}

.primary50 {
  color: #8e8aa9;
}

.primary40 {
  color: #aaa7bf;
}

.primary30 {
  color: #c7c5d4;
}

.primary20 {
  color: #e3e2ea;
}

.primary10 {
  color: #f1f1f5;
}

.black100 {
  color: #25282B;
}

.black80 {
  color: #52575C;
}

.black60 {
  color: #A0A4A8;
}

.black40 {
  color: #CACCCF;
}

.black20 {
  color: #DBDDE0;
}

.black10 {
  color: #E8E8E8;
}

.black5 {
  color: #F7F7F7;
}

.white-default {
  color: #FFFFFF;
}

.white-dark {
  color: #F7F9FB;
}

.warning-default {
  color: #FFE4A5;
}

.warning-light {
  color: #FFF2D2;
}

.warning-dark {
  color: #CC8400;
}

.success-default {
  color: #2AC769;
}

.success-dark {
  color: #128718;
}

.success-light {
  color: #D1F9C6;
}

.error-default {
  color: #FB4E4E;
}

.error-dark {
  color: #E93C3C;
}

.error-light {
  color: #FFC6C6;
}

.accent-default {
  color: #4F24FA;
}

.bg-primary100 {
  background-color: #17161E;
}

.bg-primary90 {
  background-color: #2e2c3b;
}

.bg-primary80 {
  background-color: #444159;
}

.bg-primary70 {
  background-color: #5b5776;
}

.bg-primary60 {
  background-color: #716c94;
}

.bg-primary50 {
  background-color: #8e8aa9;
}

.bg-primary40 {
  background-color: #aaa7bf;
}

.bg-primary30 {
  background-color: #c7c5d4;
}

.bg-primary20 {
  background-color: #e3e2ea;
}

.bg-primary10 {
  background-color: #f1f1f5;
}

.bg-black100 {
  background-color: #25282B;
}

.bg-black80 {
  background-color: #52575C;
}

.bg-black60 {
  background-color: #A0A4A8;
}

.bg-black40 {
  background-color: #CACCCF;
}

.bg-black20 {
  background-color: #DBDDE0;
}

.bg-black10 {
  background-color: #E8E8E8;
}

.bg-black5 {
  background-color: #F7F7F7;
}

.bg-white-default {
  background-color: #FFFFFF;
}

.bg-white-dark {
  background-color: #F7F9FB;
}

.bg-warning-light {
  background-color: #FFF2D2;
}

.bg-warning-dark {
  background-color: #CC8400;
}

.bg-warning-default {
  background-color: #FFE4A5;
}

.bg-success-default {
  background-color: #2AC769;
}

.bg-success-dark {
  background-color: #128718;
}

.bg-success-light {
  background-color: #D1F9C6;
}

.bg-error-default {
  background-color: #FB4E4E;
}

.bg-error-dark {
  background-color: #E93C3C;
}

.bg-error-light {
  background-color: #FFC6C6;
}

.bg-accent-default {
  background-color: #4F24FA;
}

.fill-none {
  fill: none;
}

.fill-primary100 {
  fill: #17161E;
}

.fill-primary90 {
  fill: #2e2c3b;
}

.fill-primary80 {
  fill: #444159;
}

.fill-primary70 {
  fill: #5b5776;
}

.fill-primary60 {
  fill: #716c94;
}

.fill-primary50 {
  fill: #8e8aa9;
}

.fill-primary40 {
  fill: #aaa7bf;
}

.fill-primary30 {
  fill: #c7c5d4;
}

.fill-primary20 {
  fill: #e3e2ea;
}

.fill-primary10 {
  fill: #f1f1f5;
}

.fill-black100 {
  fill: #25282B;
}

.fill-black80 {
  fill: #52575C;
}

.fill-black60 {
  fill: #A0A4A8;
}

.fill-black40 {
  fill: #CACCCF;
}

.fill-black20 {
  fill: #DBDDE0;
}

.fill-black10 {
  fill: #E8E8E8;
}

.fill-black5 {
  fill: #F7F7F7;
}

.fill-white-default {
  fill: #FFFFFF;
}

.fill-white-dark {
  fill: #F7F9FB;
}

.fill-warning-light {
  fill: #FFF2D2;
}

.fill-warning-dark {
  fill: #CC8400;
}

.fill-warning-default {
  fill: #FFE4A5;
}

.fill-success-default {
  fill: #2AC769;
}

.fill-success-dark {
  fill: #128718;
}

.fill-success-light {
  fill: #D1F9C6;
}

.fill-error-default {
  fill: #FB4E4E;
}

.fill-error-dark {
  fill: #E93C3C;
}

.fill-error-light {
  fill: #FFC6C6;
}

.fill-accent-default {
  fill: #4F24FA;
}

@media (max-width: 1023px) {
  .liquid-light-theme .sidenav-tooltip {
    display: none;
  }
}
.liquid-light-theme a {
  color: #17161E;
}
.liquid-light-theme a:not(.mat-tab-link) {
  font-size: inherit;
  font-weight: inherit;
}
.liquid-light-theme a i:hover {
  color: inherit;
}
.liquid-light-theme .declined,
.liquid-light-theme .warn {
  color: #CC8400 !important;
}
.liquid-light-theme .declined.round, .liquid-light-theme .declined.oval,
.liquid-light-theme .warn.round,
.liquid-light-theme .warn.oval {
  background-color: #f1f1f5 !important;
}
.liquid-light-theme .error {
  color: #FB4E4E !important;
}
.liquid-light-theme .error.round, .liquid-light-theme .error.oval {
  background-color: #f1f1f5 !important;
}
.liquid-light-theme .secondary {
  color: #4F24FA !important;
}
.liquid-light-theme .secondary.round, .liquid-light-theme .secondary.oval {
  background-color: #f1f1f5 !important;
}
.liquid-light-theme .approved,
.liquid-light-theme .success {
  color: #128718 !important;
}
.liquid-light-theme .approved.round, .liquid-light-theme .approved.oval,
.liquid-light-theme .success.round,
.liquid-light-theme .success.oval {
  background-color: #f1f1f5 !important;
}
.liquid-light-theme .primary,
.liquid-light-theme .checked {
  color: #17161E !important;
}
.liquid-light-theme .primary.round, .liquid-light-theme .primary.oval,
.liquid-light-theme .checked.round,
.liquid-light-theme .checked.oval {
  background-color: #f1f1f5 !important;
}
.liquid-light-theme .accent {
  color: #4F24FA !important;
}
.liquid-light-theme .accent.round, .liquid-light-theme .accent.oval {
  background-color: #f1f1f5 !important;
}
.liquid-light-theme .info {
  color: #aaa7bf !important;
}
.liquid-light-theme .info.round, .liquid-light-theme .info.oval {
  background-color: #f1f1f5 !important;
}
.liquid-light-theme .blue {
  color: #8e8aa9;
}
.liquid-light-theme .blue.round, .liquid-light-theme .blue.oval {
  background-color: #f1f1f5;
}
.liquid-light-theme .green {
  color: #128718;
}
.liquid-light-theme .green.round, .liquid-light-theme .green.oval {
  background-color: #f1f1f5;
}
.liquid-light-theme .highlight {
  font-weight: bold;
  text-decoration: underline;
}
.liquid-light-theme button.menu-item.mat-menu-item:hover {
  color: #8e8aa9;
  background-color: #f1f1f5;
}
.liquid-light-theme i-feather {
  flex-shrink: 0;
}
.liquid-light-theme .mat-progress-bar.work-order-progress-bar .mat-progress-bar-background,
.liquid-light-theme .mat-progress-bar.work-order-progress-bar .mat-progress-bar-buffer {
  background-color: #E8E8E8;
  fill: #E8E8E8;
}
.liquid-light-theme .mat-progress-bar.work-order-progress-bar.work-order-progress-bar-warn .mat-progress-bar-fill::after {
  background-color: #E93C3C;
}

.primary100 {
  color: #17161E;
}

.primary90 {
  color: #2e2c3b;
}

.primary80 {
  color: #444159;
}

.primary70 {
  color: #5b5776;
}

.primary60 {
  color: #716c94;
}

.primary50 {
  color: #8e8aa9;
}

.primary40 {
  color: #aaa7bf;
}

.primary30 {
  color: #c7c5d4;
}

.primary20 {
  color: #e3e2ea;
}

.primary10 {
  color: #f1f1f5;
}

.black100 {
  color: #25282B;
}

.black80 {
  color: #52575C;
}

.black60 {
  color: #A0A4A8;
}

.black40 {
  color: #CACCCF;
}

.black20 {
  color: #DBDDE0;
}

.black10 {
  color: #E8E8E8;
}

.black5 {
  color: #F7F7F7;
}

.white-default {
  color: #FFFFFF;
}

.white-dark {
  color: #F7F9FB;
}

.warning-default {
  color: #FFE4A5;
}

.warning-light {
  color: #FFF2D2;
}

.warning-dark {
  color: #CC8400;
}

.success-default {
  color: #2AC769;
}

.success-dark {
  color: #128718;
}

.success-light {
  color: #D1F9C6;
}

.error-default {
  color: #FB4E4E;
}

.error-dark {
  color: #E93C3C;
}

.error-light {
  color: #FFC6C6;
}

.accent-default {
  color: #4F24FA;
}

.bg-primary100 {
  background-color: #17161E;
}

.bg-primary90 {
  background-color: #2e2c3b;
}

.bg-primary80 {
  background-color: #444159;
}

.bg-primary70 {
  background-color: #5b5776;
}

.bg-primary60 {
  background-color: #716c94;
}

.bg-primary50 {
  background-color: #8e8aa9;
}

.bg-primary40 {
  background-color: #aaa7bf;
}

.bg-primary30 {
  background-color: #c7c5d4;
}

.bg-primary20 {
  background-color: #e3e2ea;
}

.bg-primary10 {
  background-color: #f1f1f5;
}

.bg-black100 {
  background-color: #25282B;
}

.bg-black80 {
  background-color: #52575C;
}

.bg-black60 {
  background-color: #A0A4A8;
}

.bg-black40 {
  background-color: #CACCCF;
}

.bg-black20 {
  background-color: #DBDDE0;
}

.bg-black10 {
  background-color: #E8E8E8;
}

.bg-black5 {
  background-color: #F7F7F7;
}

.bg-white-default {
  background-color: #FFFFFF;
}

.bg-white-dark {
  background-color: #F7F9FB;
}

.bg-warning-light {
  background-color: #FFF2D2;
}

.bg-warning-dark {
  background-color: #CC8400;
}

.bg-warning-default {
  background-color: #FFE4A5;
}

.bg-success-default {
  background-color: #2AC769;
}

.bg-success-dark {
  background-color: #128718;
}

.bg-success-light {
  background-color: #D1F9C6;
}

.bg-error-default {
  background-color: #FB4E4E;
}

.bg-error-dark {
  background-color: #E93C3C;
}

.bg-error-light {
  background-color: #FFC6C6;
}

.bg-accent-default {
  background-color: #4F24FA;
}

.fill-none {
  fill: none;
}

.fill-primary100 {
  fill: #17161E;
}

.fill-primary90 {
  fill: #2e2c3b;
}

.fill-primary80 {
  fill: #444159;
}

.fill-primary70 {
  fill: #5b5776;
}

.fill-primary60 {
  fill: #716c94;
}

.fill-primary50 {
  fill: #8e8aa9;
}

.fill-primary40 {
  fill: #aaa7bf;
}

.fill-primary30 {
  fill: #c7c5d4;
}

.fill-primary20 {
  fill: #e3e2ea;
}

.fill-primary10 {
  fill: #f1f1f5;
}

.fill-black100 {
  fill: #25282B;
}

.fill-black80 {
  fill: #52575C;
}

.fill-black60 {
  fill: #A0A4A8;
}

.fill-black40 {
  fill: #CACCCF;
}

.fill-black20 {
  fill: #DBDDE0;
}

.fill-black10 {
  fill: #E8E8E8;
}

.fill-black5 {
  fill: #F7F7F7;
}

.fill-white-default {
  fill: #FFFFFF;
}

.fill-white-dark {
  fill: #F7F9FB;
}

.fill-warning-light {
  fill: #FFF2D2;
}

.fill-warning-dark {
  fill: #CC8400;
}

.fill-warning-default {
  fill: #FFE4A5;
}

.fill-success-default {
  fill: #2AC769;
}

.fill-success-dark {
  fill: #128718;
}

.fill-success-light {
  fill: #D1F9C6;
}

.fill-error-default {
  fill: #FB4E4E;
}

.fill-error-dark {
  fill: #E93C3C;
}

.fill-error-light {
  fill: #FFC6C6;
}

.fill-accent-default {
  fill: #4F24FA;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
    transform-origin: center;
  }
  100% {
    transform: rotate(360deg);
  }
}
.mat-chip {
  color: #17161E !important;
  background-color: #f1f1f5 !important;
  border: 1px solid #c7c5d4 !important;
  letter-spacing: 0.2px;
  min-width: 0;
}
.mat-chip:not(.chip-status-badge):not(.site-status-badge) {
  height: 34px !important;
}
.mat-chip:not(.chip-status-badge):not(.site-status-badge) .chip-value {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mat-chip.site-status-badge {
  height: 34px !important;
}
.mat-chip.site-status-badge .chip-value {
  white-space: nowrap;
}
.mat-chip.chip-status-badge {
  font-weight: normal;
  flex-shrink: 0;
  white-space: normal;
  text-transform: uppercase;
  text-align: center;
  border: none !important;
  max-width: 110px;
  height: auto;
}
.mat-chip.chip-warn {
  color: #CC8400 !important;
  background-color: #FFF2D2 !important;
  border-color: #CC8400 !important;
}
.mat-chip.chip-error {
  color: #E93C3C !important;
  background-color: #FFC6C6 !important;
  border-color: #E93C3C !important;
}
.mat-chip.chip-success {
  color: #128718 !important;
  background-color: #D1F9C6 !important;
  border-color: #128718 !important;
}
.mat-chip.clickable {
  cursor: pointer;
}
.mat-chip:hover:not(.clickable) {
  background-color: #f1f1f5 !important;
  border: 1px solid #c7c5d4 !important;
}
.mat-chip:hover:not(.clickable).chip-status-badge {
  border: none !important;
}
.mat-chip:hover:not(.clickable).chip-warn {
  color: #CC8400 !important;
  background-color: #FFF2D2 !important;
  border-color: #CC8400 !important;
}
.mat-chip:hover:not(.clickable).chip-error {
  color: #E93C3C !important;
  background-color: #FFC6C6 !important;
  border-color: #E93C3C !important;
}
.mat-chip:hover:not(.clickable).chip-success {
  color: #128718 !important;
  background-color: #D1F9C6 !important;
  border-color: #128718 !important;
}
.mat-chip:hover.clickable {
  background-color: #e3e2ea !important;
  border-color: #716c94 !important;
}
.mat-chip:hover.clickable.chip-warn {
  background-color: #FFE4A5 !important;
  border-color: #CC8400 !important;
}
.mat-chip:hover.clickable.chip-error {
  background-color: #FB4E4E !important;
  border-color: #E93C3C !important;
  color: #FFFFFF !important;
}
.mat-chip:hover.clickable.chip-success {
  background-color: #2AC769 !important;
  border-color: #128718 !important;
  color: #FFFFFF !important;
}
.mat-chip:hover::after {
  opacity: 1;
}
.mat-chip:focus.clickable {
  color: #17161E !important;
  background-color: #f1f1f5 !important;
  border-color: #c7c5d4 !important;
}
.mat-chip:focus.clickable.chip-warn {
  color: #CC8400 !important;
  background-color: #FFF2D2 !important;
  border-color: #CC8400 !important;
}
.mat-chip:focus.clickable.chip-error {
  color: #E93C3C !important;
  background-color: #FFC6C6 !important;
  border-color: #E93C3C !important;
}
.mat-chip:focus.clickable.chip-success {
  color: #128718 !important;
  background-color: #D1F9C6 !important;
  border-color: #128718 !important;
}
.mat-chip:active:not(.clickable) {
  box-shadow: none !important;
}
.mat-chip.focused.clickable {
  background-color: #5b5776 !important;
  border-color: #17161E !important;
  color: #FFFFFF !important;
}
.mat-chip.focused.clickable.chip-warn {
  background-color: #CC8400 !important;
  border-color: #CC8400 !important;
}
.mat-chip.focused.clickable.chip-error {
  background-color: #E93C3C !important;
  border-color: #E93C3C !important;
}
.mat-chip.focused.clickable.chip-success {
  background-color: #128718 !important;
  border-color: #128718 !important;
}
.mat-chip.focused.chip-status-badge {
  border: none !important;
}
.mat-chip::after {
  background: #e3e2ea !important;
}
.mat-chip .chip-img-prefix {
  height: 24px;
  width: 24px;
  border-radius: 50%;
}
.mat-chip .chip-img-prefix:nth-of-type(1) {
  margin-right: 10px;
}
.mat-chip .mat-chip-trailing-icon, .mat-chip .chip-icon-suffix,
.mat-chip .chip-icon-prefix {
  height: 20px !important;
  width: 20px !important;
}
.mat-chip .chip-icon-prefix {
  margin-right: 10px;
}
.mat-chip .mat-chip-trailing-icon, .mat-chip .chip-icon-suffix {
  opacity: 1 !important;
  color: inherit !important;
  margin-left: 10px !important;
}
.mat-chip .chip-icon-suffix {
  cursor: default;
}

.narrow-chip-list .chip-value {
  max-width: 110px !important;
}

.mat-chip-list-wrapper {
  margin: 0 !important;
}

.liquid-light-theme .mat-chip.mat-standard-chip.mini-chip:not(.chip-status-badge) {
  font-size: 10px !important;
  font-weight: normal !important;
  line-height: 14px !important;
  padding: 0px 4px !important;
  border-radius: 8px;
  min-height: auto !important;
  max-height: 16px !important;
  vertical-align: middle !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.liquid-light-theme .mat-chip.mat-standard-chip.mini-chip:not(.chip-status-badge).removable i-feather.mat-chip-remove.mat-chip-trailing-icon, .liquid-light-theme .mat-chip.mat-standard-chip.mini-chip:not(.chip-status-badge).removable i-feather.mat-chip-remove.chip-icon-suffix {
  height: 10px !important;
  width: 10px !important;
  margin: 0 !important;
  padding: 0 !important;
  margin-left: 2px !important;
}
.liquid-light-theme .mat-chip.mat-standard-chip.mini-chip:not(.chip-status-badge) span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.liquid-light-theme .mat-chip.mat-standard-chip.mini-chip:not(.chip-status-badge).collapsible {
  max-width: 115px;
}
.liquid-light-theme .mat-chip.mat-standard-chip.mini-chip:not(.chip-status-badge).collapsible span {
  max-width: 105px;
}

.liquid-light-theme .mat-dialog-container {
  margin: auto;
  min-height: 225px;
}
@media (max-width: 1023px) {
  .liquid-light-theme .mat-dialog-container {
    max-width: 90vw;
  }
}
.liquid-light-theme .mat-dialog-content {
  max-height: none;
  overflow: unset;
}
.liquid-light-theme .button-row {
  display: flex;
  justify-content: flex-end;
}
.liquid-light-theme .button-row button {
  margin-left: 10px;
}
@media (max-width: 599px) {
  .liquid-light-theme .button-row {
    flex-direction: column-reverse;
    align-items: center;
  }
  .liquid-light-theme .button-row button {
    margin-left: 0;
    margin-top: 10px;
  }
}
.liquid-light-theme mat-dialog-actions .cancel-alert {
  margin: 0 auto;
  width: 80%;
}
.liquid-light-theme mat-dialog-actions .cancel-alert p {
  margin: 0;
}
@media (max-width: 1023px) {
  .liquid-light-theme mat-dialog-actions .cancel-alert {
    width: 50%;
  }
}

.primary100 {
  color: #17161E;
}

.primary90 {
  color: #2e2c3b;
}

.primary80 {
  color: #444159;
}

.primary70 {
  color: #5b5776;
}

.primary60 {
  color: #716c94;
}

.primary50 {
  color: #8e8aa9;
}

.primary40 {
  color: #aaa7bf;
}

.primary30 {
  color: #c7c5d4;
}

.primary20 {
  color: #e3e2ea;
}

.primary10 {
  color: #f1f1f5;
}

.black100 {
  color: #25282B;
}

.black80 {
  color: #52575C;
}

.black60 {
  color: #A0A4A8;
}

.black40 {
  color: #CACCCF;
}

.black20 {
  color: #DBDDE0;
}

.black10 {
  color: #E8E8E8;
}

.black5 {
  color: #F7F7F7;
}

.white-default {
  color: #FFFFFF;
}

.white-dark {
  color: #F7F9FB;
}

.warning-default {
  color: #FFE4A5;
}

.warning-light {
  color: #FFF2D2;
}

.warning-dark {
  color: #CC8400;
}

.success-default {
  color: #2AC769;
}

.success-dark {
  color: #128718;
}

.success-light {
  color: #D1F9C6;
}

.error-default {
  color: #FB4E4E;
}

.error-dark {
  color: #E93C3C;
}

.error-light {
  color: #FFC6C6;
}

.accent-default {
  color: #4F24FA;
}

.bg-primary100 {
  background-color: #17161E;
}

.bg-primary90 {
  background-color: #2e2c3b;
}

.bg-primary80 {
  background-color: #444159;
}

.bg-primary70 {
  background-color: #5b5776;
}

.bg-primary60 {
  background-color: #716c94;
}

.bg-primary50 {
  background-color: #8e8aa9;
}

.bg-primary40 {
  background-color: #aaa7bf;
}

.bg-primary30 {
  background-color: #c7c5d4;
}

.bg-primary20 {
  background-color: #e3e2ea;
}

.bg-primary10 {
  background-color: #f1f1f5;
}

.bg-black100 {
  background-color: #25282B;
}

.bg-black80 {
  background-color: #52575C;
}

.bg-black60 {
  background-color: #A0A4A8;
}

.bg-black40 {
  background-color: #CACCCF;
}

.bg-black20 {
  background-color: #DBDDE0;
}

.bg-black10 {
  background-color: #E8E8E8;
}

.bg-black5 {
  background-color: #F7F7F7;
}

.bg-white-default {
  background-color: #FFFFFF;
}

.bg-white-dark {
  background-color: #F7F9FB;
}

.bg-warning-light {
  background-color: #FFF2D2;
}

.bg-warning-dark {
  background-color: #CC8400;
}

.bg-warning-default {
  background-color: #FFE4A5;
}

.bg-success-default {
  background-color: #2AC769;
}

.bg-success-dark {
  background-color: #128718;
}

.bg-success-light {
  background-color: #D1F9C6;
}

.bg-error-default {
  background-color: #FB4E4E;
}

.bg-error-dark {
  background-color: #E93C3C;
}

.bg-error-light {
  background-color: #FFC6C6;
}

.bg-accent-default {
  background-color: #4F24FA;
}

.fill-none {
  fill: none;
}

.fill-primary100 {
  fill: #17161E;
}

.fill-primary90 {
  fill: #2e2c3b;
}

.fill-primary80 {
  fill: #444159;
}

.fill-primary70 {
  fill: #5b5776;
}

.fill-primary60 {
  fill: #716c94;
}

.fill-primary50 {
  fill: #8e8aa9;
}

.fill-primary40 {
  fill: #aaa7bf;
}

.fill-primary30 {
  fill: #c7c5d4;
}

.fill-primary20 {
  fill: #e3e2ea;
}

.fill-primary10 {
  fill: #f1f1f5;
}

.fill-black100 {
  fill: #25282B;
}

.fill-black80 {
  fill: #52575C;
}

.fill-black60 {
  fill: #A0A4A8;
}

.fill-black40 {
  fill: #CACCCF;
}

.fill-black20 {
  fill: #DBDDE0;
}

.fill-black10 {
  fill: #E8E8E8;
}

.fill-black5 {
  fill: #F7F7F7;
}

.fill-white-default {
  fill: #FFFFFF;
}

.fill-white-dark {
  fill: #F7F9FB;
}

.fill-warning-light {
  fill: #FFF2D2;
}

.fill-warning-dark {
  fill: #CC8400;
}

.fill-warning-default {
  fill: #FFE4A5;
}

.fill-success-default {
  fill: #2AC769;
}

.fill-success-dark {
  fill: #128718;
}

.fill-success-light {
  fill: #D1F9C6;
}

.fill-error-default {
  fill: #FB4E4E;
}

.fill-error-dark {
  fill: #E93C3C;
}

.fill-error-light {
  fill: #FFC6C6;
}

.fill-accent-default {
  fill: #4F24FA;
}

.liquid-light-theme mat-hint {
  display: flex;
  align-items: center;
}
.liquid-light-theme mat-hint i-feather {
  height: 15px;
  width: 15px;
  margin: 0 3px;
}
.liquid-light-theme .mat-form-field {
  font-size: 16px;
}
.liquid-light-theme .mat-form-field i-feather {
  color: #CACCCF;
}
.liquid-light-theme .mat-form-field .mat-form-field-prefix {
  bottom: -7px;
  padding-right: 10px;
}
.liquid-light-theme .mat-form-field .mat-form-field-prefix span {
  position: relative;
  bottom: 7px;
}
.liquid-light-theme .mat-form-field .mat-form-field-suffix {
  bottom: -7px;
  padding-left: 10px;
}
.liquid-light-theme .mat-form-field .mat-form-field-suffix span {
  position: relative;
  bottom: 7px;
}
.liquid-light-theme .mat-form-field .mat-form-field-ripple {
  background-color: #17161E;
}
.liquid-light-theme .mat-form-field.auto-width-form-field .mat-form-field-infix {
  width: auto;
}
.liquid-light-theme .mat-form-field.mat-focused i-feather {
  color: #A0A4A8;
}
.liquid-light-theme .mat-form-field.mat-focused .mat-hint {
  font-size: 12px;
  color: #17161E;
}
.liquid-light-theme .mat-form-field.mat-form-field-disabled {
  color: rgba(0, 0, 0, 0.38) i-feather;
  color-color: #E8E8E8;
}
.liquid-light-theme .mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, black 0, rgba(0, 0, 0, 0.42) 33%, #c2c7cc 0) !important;
  background-size: 1px 100% !important;
  background-repeat: repeat-x !important;
}
.liquid-light-theme .mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  transform: translateY(-1.2em) scale(0.75);
}
.liquid-light-theme .mat-form-field-appearance-fill .mat-form-field-prefix {
  bottom: -7px;
}
.liquid-light-theme .mat-form-field-appearance-fill .mat-form-field-suffix {
  bottom: 0;
}
.liquid-light-theme .mat-form-field-appearance-fill .mat-form-field-label {
  top: 25px;
}
.liquid-light-theme .mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  transform: translateY(-1.2em) scale(0.75);
}
.liquid-light-theme .mat-radio-group {
  display: flex;
  flex-direction: column;
}
.liquid-light-theme .mat-radio-group.horizontal {
  flex-direction: row;
}
.liquid-light-theme .mat-radio-group.horizontal mat-radio-button {
  margin-right: 5px;
}
.liquid-light-theme .mat-radio-group.horizontal mat-radio-button:not(:last-of-type) {
  margin-right: 10px;
}
.liquid-light-theme .mat-radio-group.radio-wrap .mat-radio-label {
  align-items: flex-start;
  text-align: left;
}
.liquid-light-theme .mat-radio-group.radio-wrap .mat-radio-label .mat-radio-label-content {
  white-space: normal;
}
.liquid-light-theme .mat-radio-group .mat-radio-button {
  margin: 5px 0;
}
.liquid-light-theme .mat-radio-group .mat-radio-outer-circle {
  border-color: #17161E;
}
.liquid-light-theme .mat-checkbox-frame {
  border-color: #17161E;
}
.liquid-light-theme .mat-checkbox.wrap-label .mat-checkbox-label {
  white-space: normal;
  line-height: normal;
}

.primary100 {
  color: #17161E;
}

.primary90 {
  color: #2e2c3b;
}

.primary80 {
  color: #444159;
}

.primary70 {
  color: #5b5776;
}

.primary60 {
  color: #716c94;
}

.primary50 {
  color: #8e8aa9;
}

.primary40 {
  color: #aaa7bf;
}

.primary30 {
  color: #c7c5d4;
}

.primary20 {
  color: #e3e2ea;
}

.primary10 {
  color: #f1f1f5;
}

.black100 {
  color: #25282B;
}

.black80 {
  color: #52575C;
}

.black60 {
  color: #A0A4A8;
}

.black40 {
  color: #CACCCF;
}

.black20 {
  color: #DBDDE0;
}

.black10 {
  color: #E8E8E8;
}

.black5 {
  color: #F7F7F7;
}

.white-default {
  color: #FFFFFF;
}

.white-dark {
  color: #F7F9FB;
}

.warning-default {
  color: #FFE4A5;
}

.warning-light {
  color: #FFF2D2;
}

.warning-dark {
  color: #CC8400;
}

.success-default {
  color: #2AC769;
}

.success-dark {
  color: #128718;
}

.success-light {
  color: #D1F9C6;
}

.error-default {
  color: #FB4E4E;
}

.error-dark {
  color: #E93C3C;
}

.error-light {
  color: #FFC6C6;
}

.accent-default {
  color: #4F24FA;
}

.bg-primary100 {
  background-color: #17161E;
}

.bg-primary90 {
  background-color: #2e2c3b;
}

.bg-primary80 {
  background-color: #444159;
}

.bg-primary70 {
  background-color: #5b5776;
}

.bg-primary60 {
  background-color: #716c94;
}

.bg-primary50 {
  background-color: #8e8aa9;
}

.bg-primary40 {
  background-color: #aaa7bf;
}

.bg-primary30 {
  background-color: #c7c5d4;
}

.bg-primary20 {
  background-color: #e3e2ea;
}

.bg-primary10 {
  background-color: #f1f1f5;
}

.bg-black100 {
  background-color: #25282B;
}

.bg-black80 {
  background-color: #52575C;
}

.bg-black60 {
  background-color: #A0A4A8;
}

.bg-black40 {
  background-color: #CACCCF;
}

.bg-black20 {
  background-color: #DBDDE0;
}

.bg-black10 {
  background-color: #E8E8E8;
}

.bg-black5 {
  background-color: #F7F7F7;
}

.bg-white-default {
  background-color: #FFFFFF;
}

.bg-white-dark {
  background-color: #F7F9FB;
}

.bg-warning-light {
  background-color: #FFF2D2;
}

.bg-warning-dark {
  background-color: #CC8400;
}

.bg-warning-default {
  background-color: #FFE4A5;
}

.bg-success-default {
  background-color: #2AC769;
}

.bg-success-dark {
  background-color: #128718;
}

.bg-success-light {
  background-color: #D1F9C6;
}

.bg-error-default {
  background-color: #FB4E4E;
}

.bg-error-dark {
  background-color: #E93C3C;
}

.bg-error-light {
  background-color: #FFC6C6;
}

.bg-accent-default {
  background-color: #4F24FA;
}

.fill-none {
  fill: none;
}

.fill-primary100 {
  fill: #17161E;
}

.fill-primary90 {
  fill: #2e2c3b;
}

.fill-primary80 {
  fill: #444159;
}

.fill-primary70 {
  fill: #5b5776;
}

.fill-primary60 {
  fill: #716c94;
}

.fill-primary50 {
  fill: #8e8aa9;
}

.fill-primary40 {
  fill: #aaa7bf;
}

.fill-primary30 {
  fill: #c7c5d4;
}

.fill-primary20 {
  fill: #e3e2ea;
}

.fill-primary10 {
  fill: #f1f1f5;
}

.fill-black100 {
  fill: #25282B;
}

.fill-black80 {
  fill: #52575C;
}

.fill-black60 {
  fill: #A0A4A8;
}

.fill-black40 {
  fill: #CACCCF;
}

.fill-black20 {
  fill: #DBDDE0;
}

.fill-black10 {
  fill: #E8E8E8;
}

.fill-black5 {
  fill: #F7F7F7;
}

.fill-white-default {
  fill: #FFFFFF;
}

.fill-white-dark {
  fill: #F7F9FB;
}

.fill-warning-light {
  fill: #FFF2D2;
}

.fill-warning-dark {
  fill: #CC8400;
}

.fill-warning-default {
  fill: #FFE4A5;
}

.fill-success-default {
  fill: #2AC769;
}

.fill-success-dark {
  fill: #128718;
}

.fill-success-light {
  fill: #D1F9C6;
}

.fill-error-default {
  fill: #FB4E4E;
}

.fill-error-dark {
  fill: #E93C3C;
}

.fill-error-light {
  fill: #FFC6C6;
}

.fill-accent-default {
  fill: #4F24FA;
}

.liquid-light-theme .madlib-error {
  white-space: wrap;
  word-wrap: break-word;
  border-radius: 8px;
  border: 1px solid #c7c5d4;
  color: #17161E;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  max-width: auto;
  background-color: #FFFFFF;
}

.primary100 {
  color: #17161E;
}

.primary90 {
  color: #2e2c3b;
}

.primary80 {
  color: #444159;
}

.primary70 {
  color: #5b5776;
}

.primary60 {
  color: #716c94;
}

.primary50 {
  color: #8e8aa9;
}

.primary40 {
  color: #aaa7bf;
}

.primary30 {
  color: #c7c5d4;
}

.primary20 {
  color: #e3e2ea;
}

.primary10 {
  color: #f1f1f5;
}

.black100 {
  color: #25282B;
}

.black80 {
  color: #52575C;
}

.black60 {
  color: #A0A4A8;
}

.black40 {
  color: #CACCCF;
}

.black20 {
  color: #DBDDE0;
}

.black10 {
  color: #E8E8E8;
}

.black5 {
  color: #F7F7F7;
}

.white-default {
  color: #FFFFFF;
}

.white-dark {
  color: #F7F9FB;
}

.warning-default {
  color: #FFE4A5;
}

.warning-light {
  color: #FFF2D2;
}

.warning-dark {
  color: #CC8400;
}

.success-default {
  color: #2AC769;
}

.success-dark {
  color: #128718;
}

.success-light {
  color: #D1F9C6;
}

.error-default {
  color: #FB4E4E;
}

.error-dark {
  color: #E93C3C;
}

.error-light {
  color: #FFC6C6;
}

.accent-default {
  color: #4F24FA;
}

.bg-primary100 {
  background-color: #17161E;
}

.bg-primary90 {
  background-color: #2e2c3b;
}

.bg-primary80 {
  background-color: #444159;
}

.bg-primary70 {
  background-color: #5b5776;
}

.bg-primary60 {
  background-color: #716c94;
}

.bg-primary50 {
  background-color: #8e8aa9;
}

.bg-primary40 {
  background-color: #aaa7bf;
}

.bg-primary30 {
  background-color: #c7c5d4;
}

.bg-primary20 {
  background-color: #e3e2ea;
}

.bg-primary10 {
  background-color: #f1f1f5;
}

.bg-black100 {
  background-color: #25282B;
}

.bg-black80 {
  background-color: #52575C;
}

.bg-black60 {
  background-color: #A0A4A8;
}

.bg-black40 {
  background-color: #CACCCF;
}

.bg-black20 {
  background-color: #DBDDE0;
}

.bg-black10 {
  background-color: #E8E8E8;
}

.bg-black5 {
  background-color: #F7F7F7;
}

.bg-white-default {
  background-color: #FFFFFF;
}

.bg-white-dark {
  background-color: #F7F9FB;
}

.bg-warning-light {
  background-color: #FFF2D2;
}

.bg-warning-dark {
  background-color: #CC8400;
}

.bg-warning-default {
  background-color: #FFE4A5;
}

.bg-success-default {
  background-color: #2AC769;
}

.bg-success-dark {
  background-color: #128718;
}

.bg-success-light {
  background-color: #D1F9C6;
}

.bg-error-default {
  background-color: #FB4E4E;
}

.bg-error-dark {
  background-color: #E93C3C;
}

.bg-error-light {
  background-color: #FFC6C6;
}

.bg-accent-default {
  background-color: #4F24FA;
}

.fill-none {
  fill: none;
}

.fill-primary100 {
  fill: #17161E;
}

.fill-primary90 {
  fill: #2e2c3b;
}

.fill-primary80 {
  fill: #444159;
}

.fill-primary70 {
  fill: #5b5776;
}

.fill-primary60 {
  fill: #716c94;
}

.fill-primary50 {
  fill: #8e8aa9;
}

.fill-primary40 {
  fill: #aaa7bf;
}

.fill-primary30 {
  fill: #c7c5d4;
}

.fill-primary20 {
  fill: #e3e2ea;
}

.fill-primary10 {
  fill: #f1f1f5;
}

.fill-black100 {
  fill: #25282B;
}

.fill-black80 {
  fill: #52575C;
}

.fill-black60 {
  fill: #A0A4A8;
}

.fill-black40 {
  fill: #CACCCF;
}

.fill-black20 {
  fill: #DBDDE0;
}

.fill-black10 {
  fill: #E8E8E8;
}

.fill-black5 {
  fill: #F7F7F7;
}

.fill-white-default {
  fill: #FFFFFF;
}

.fill-white-dark {
  fill: #F7F9FB;
}

.fill-warning-light {
  fill: #FFF2D2;
}

.fill-warning-dark {
  fill: #CC8400;
}

.fill-warning-default {
  fill: #FFE4A5;
}

.fill-success-default {
  fill: #2AC769;
}

.fill-success-dark {
  fill: #128718;
}

.fill-success-light {
  fill: #D1F9C6;
}

.fill-error-default {
  fill: #FB4E4E;
}

.fill-error-dark {
  fill: #E93C3C;
}

.fill-error-light {
  fill: #FFC6C6;
}

.fill-accent-default {
  fill: #4F24FA;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
    transform-origin: center;
  }
  100% {
    transform: rotate(360deg);
  }
}
.primary100 {
  color: #17161E;
}

.primary90 {
  color: #2e2c3b;
}

.primary80 {
  color: #444159;
}

.primary70 {
  color: #5b5776;
}

.primary60 {
  color: #716c94;
}

.primary50 {
  color: #8e8aa9;
}

.primary40 {
  color: #aaa7bf;
}

.primary30 {
  color: #c7c5d4;
}

.primary20 {
  color: #e3e2ea;
}

.primary10 {
  color: #f1f1f5;
}

.black100 {
  color: #25282B;
}

.black80 {
  color: #52575C;
}

.black60 {
  color: #A0A4A8;
}

.black40 {
  color: #CACCCF;
}

.black20 {
  color: #DBDDE0;
}

.black10 {
  color: #E8E8E8;
}

.black5 {
  color: #F7F7F7;
}

.white-default {
  color: #FFFFFF;
}

.white-dark {
  color: #F7F9FB;
}

.warning-default {
  color: #FFE4A5;
}

.warning-light {
  color: #FFF2D2;
}

.warning-dark {
  color: #CC8400;
}

.success-default {
  color: #2AC769;
}

.success-dark {
  color: #128718;
}

.success-light {
  color: #D1F9C6;
}

.error-default {
  color: #FB4E4E;
}

.error-dark {
  color: #E93C3C;
}

.error-light {
  color: #FFC6C6;
}

.accent-default {
  color: #4F24FA;
}

.bg-primary100 {
  background-color: #17161E;
}

.bg-primary90 {
  background-color: #2e2c3b;
}

.bg-primary80 {
  background-color: #444159;
}

.bg-primary70 {
  background-color: #5b5776;
}

.bg-primary60 {
  background-color: #716c94;
}

.bg-primary50 {
  background-color: #8e8aa9;
}

.bg-primary40 {
  background-color: #aaa7bf;
}

.bg-primary30 {
  background-color: #c7c5d4;
}

.bg-primary20 {
  background-color: #e3e2ea;
}

.bg-primary10 {
  background-color: #f1f1f5;
}

.bg-black100 {
  background-color: #25282B;
}

.bg-black80 {
  background-color: #52575C;
}

.bg-black60 {
  background-color: #A0A4A8;
}

.bg-black40 {
  background-color: #CACCCF;
}

.bg-black20 {
  background-color: #DBDDE0;
}

.bg-black10 {
  background-color: #E8E8E8;
}

.bg-black5 {
  background-color: #F7F7F7;
}

.bg-white-default {
  background-color: #FFFFFF;
}

.bg-white-dark {
  background-color: #F7F9FB;
}

.bg-warning-light {
  background-color: #FFF2D2;
}

.bg-warning-dark {
  background-color: #CC8400;
}

.bg-warning-default {
  background-color: #FFE4A5;
}

.bg-success-default {
  background-color: #2AC769;
}

.bg-success-dark {
  background-color: #128718;
}

.bg-success-light {
  background-color: #D1F9C6;
}

.bg-error-default {
  background-color: #FB4E4E;
}

.bg-error-dark {
  background-color: #E93C3C;
}

.bg-error-light {
  background-color: #FFC6C6;
}

.bg-accent-default {
  background-color: #4F24FA;
}

.fill-none {
  fill: none;
}

.fill-primary100 {
  fill: #17161E;
}

.fill-primary90 {
  fill: #2e2c3b;
}

.fill-primary80 {
  fill: #444159;
}

.fill-primary70 {
  fill: #5b5776;
}

.fill-primary60 {
  fill: #716c94;
}

.fill-primary50 {
  fill: #8e8aa9;
}

.fill-primary40 {
  fill: #aaa7bf;
}

.fill-primary30 {
  fill: #c7c5d4;
}

.fill-primary20 {
  fill: #e3e2ea;
}

.fill-primary10 {
  fill: #f1f1f5;
}

.fill-black100 {
  fill: #25282B;
}

.fill-black80 {
  fill: #52575C;
}

.fill-black60 {
  fill: #A0A4A8;
}

.fill-black40 {
  fill: #CACCCF;
}

.fill-black20 {
  fill: #DBDDE0;
}

.fill-black10 {
  fill: #E8E8E8;
}

.fill-black5 {
  fill: #F7F7F7;
}

.fill-white-default {
  fill: #FFFFFF;
}

.fill-white-dark {
  fill: #F7F9FB;
}

.fill-warning-light {
  fill: #FFF2D2;
}

.fill-warning-dark {
  fill: #CC8400;
}

.fill-warning-default {
  fill: #FFE4A5;
}

.fill-success-default {
  fill: #2AC769;
}

.fill-success-dark {
  fill: #128718;
}

.fill-success-light {
  fill: #D1F9C6;
}

.fill-error-default {
  fill: #FB4E4E;
}

.fill-error-dark {
  fill: #E93C3C;
}

.fill-error-light {
  fill: #FFC6C6;
}

.fill-accent-default {
  fill: #4F24FA;
}

.liquid-light-theme {
  /* SIDE NAVIGATION */
  /* COA */
  /* INVOICE SELECT */
  /* BRANDING */
  /* Clients / Vendors */
  /* Clients / Vendors */
  /* Select Business Function */
  /* Placeholder deliverable list */
  /* Notification Chips */
  /* Create Work Order */
  /* Invoice Detail */
  /* Tag Group Colllapsed tooltip */
}
.liquid-light-theme .wallet-cards .mat-radio-label-content {
  padding-left: 32px;
}
.liquid-light-theme .wallet-cards .mat-form-field-wrapper {
  padding-bottom: 26px;
}
.liquid-light-theme .site-header .nav-userHeader i-feather {
  color: #17161E;
}
.liquid-light-theme .site-header .nav-search .mat-form-field-wrapper {
  overflow: hidden;
  background: #FFFFFF;
  border-radius: 8px;
  padding: 0;
  border: none;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .site-header .nav-search .mat-form-field-label-wrapper,
.liquid-light-theme .site-header .nav-search .mat-form-field-underline {
  display: none;
}
.liquid-light-theme .site-header .nav-search .mat-form-field-flex {
  padding: 0;
  background-color: #FFFFFF;
}
.liquid-light-theme .site-header .nav-search .mat-form-field-suffix {
  align-self: center;
  margin-right: 15px;
  margin-left: 0;
}
@media (max-width: 599px) {
  .liquid-light-theme .site-header .nav-search .mat-form-field-suffix {
    width: 40px;
    height: 40px;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
    margin: 0;
    position: absolute;
  }
}
.liquid-light-theme .site-header .nav-search .mat-form-field-suffix i-feather {
  font-size: 24px;
  color: #17161E;
}
@media (max-width: 599px) {
  .liquid-light-theme .site-header .nav-search .search-field:focus-within .mat-form-field-suffix {
    top: 0;
    left: 0;
  }
}
.liquid-light-theme .site-header .nav-search .mat-form-field-infix {
  padding: 0;
  border: none;
  width: auto;
}
.liquid-light-theme .site-header .nav-search .mat-form-field-infix input {
  margin: 0;
  font-size: 16px;
  padding: 0 15px;
  line-height: 50px;
  color: #52575C;
  caret-color: #52575C;
}
@media (max-width: 599px) {
  .liquid-light-theme .site-header .nav-search .mat-form-field-infix input {
    line-height: 40px;
  }
}
.liquid-light-theme .app .mat-sidenav-content.app-content {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}
.liquid-light-theme .app .mat-drawer.mat-sidenav.mat-drawer-opened {
  width: 74px;
}
.liquid-light-theme .app .content .sidenav-container .mat-sidenav-content .router-outlet {
  width: 100%;
}
.liquid-light-theme .app .content .sidenav-container .mat-sidenav-content.guest-page {
  margin-left: 0px !important;
}
.liquid-light-theme .app .content .sidenav-container .mat-sidenav-content.guest-page .router-outlet {
  height: 100%;
  width: 100vw !important;
}
@media (min-width: 1024px) {
  .liquid-light-theme .app .content .sidenav-container .mat-sidenav-content {
    transition: margin 250ms ease-in-out;
  }
}
@media print and (min-width: 1024px) {
  .liquid-light-theme .app .content .sidenav-container .mat-sidenav-content {
    margin-left: 0px !important;
  }
}
@media (min-width: 1024px) {
  .liquid-light-theme .app .content .sidenav-container .mat-sidenav-content .router-outlet {
    width: 100%;
  }
}
@media (max-width: 1023px) {
  .liquid-light-theme .app .content .sidenav-container .mat-sidenav-content {
    margin-left: 0 !important;
  }
  .liquid-light-theme .app .content .sidenav-container .mat-sidenav-content .router-outlet {
    width: 100vw;
  }
}
.liquid-light-theme .app .content .sidenav-container .sidenav-header .mat-form-field .mat-form-field-flex,
.liquid-light-theme .app .content .sidenav-container .sidenav-header .mat-form-field-flex {
  background-color: transparent !important;
  padding: 0 10px;
}
.liquid-light-theme .app .content .sidenav-container .sidenav-nav {
  transition: width 250ms ease-in-out;
}
.liquid-light-theme .app .content .sidenav-container .sidenav-nav a.expand .mat-icon {
  color: #52575C;
}
.liquid-light-theme .app .content .sidenav-container .sidenav-nav a.expand .mat-icon:hover {
  color: #52575C !important;
}
.liquid-light-theme .app .content .sidenav-container .sidenav-nav .mat-form-field .mat-form-field {
  font-size: 14px;
}
.liquid-light-theme .app .content .sidenav-container .sidenav-nav .mat-drawer-inner-container {
  display: flex;
  flex-direction: column;
}
.liquid-light-theme .containers-dashboard .info-container .highlight {
  box-shadow: 2px 0px 14px #8e8aa9;
}
.liquid-light-theme .container-editor .line-items .item .field .details {
  color: #5b5776;
}
.liquid-light-theme .invitee {
  border: 1px solid #e3e2ea;
}
.liquid-light-theme .selected-invite-option {
  color: #4F24FA;
}
.liquid-light-theme .unselected-invite-option {
  color: #aaa7bf;
}
.liquid-light-theme .placeholder {
  color: #aaa7bf;
}
.liquid-light-theme .email {
  border: 1px solid #aaa7bf;
}
.liquid-light-theme .search {
  border: 1px solid #aaa7bf;
}
.liquid-light-theme .search .search-results .result-row .result {
  border-bottom: 1px dotted #e3e2ea;
}
.liquid-light-theme .search .search-results .result-row:hover {
  background-color: #f1f1f5;
}
.liquid-light-theme .search .search-results .result-row .result-selected {
  background-color: #f1f1f5;
}
.liquid-light-theme .search .search-results .result-row .not-selectable {
  color: #aaa7bf;
}
.liquid-light-theme .search .search-results .result-row .not-selectable:hover {
  background-color: #f1f1f5;
}
.liquid-light-theme .document-sign .field-list .add-row,
.liquid-light-theme .document-sign .section-list .add-row,
.liquid-light-theme .document-sign .sidenav .add-row,
.liquid-light-theme .questionnaire .field-list .add-row,
.liquid-light-theme .questionnaire .section-list .add-row,
.liquid-light-theme .questionnaire .sidenav .add-row,
.liquid-light-theme .container-with-sidenav .field-list .add-row,
.liquid-light-theme .container-with-sidenav .section-list .add-row,
.liquid-light-theme .container-with-sidenav .sidenav .add-row {
  color: #2AC769;
}
.liquid-light-theme .document-sign .field-list .list-container .field-row:hover,
.liquid-light-theme .document-sign .field-list .list-container .list-row:hover,
.liquid-light-theme .document-sign .section-list .list-container .field-row:hover,
.liquid-light-theme .document-sign .section-list .list-container .list-row:hover,
.liquid-light-theme .document-sign .sidenav .list-container .field-row:hover,
.liquid-light-theme .document-sign .sidenav .list-container .list-row:hover,
.liquid-light-theme .questionnaire .field-list .list-container .field-row:hover,
.liquid-light-theme .questionnaire .field-list .list-container .list-row:hover,
.liquid-light-theme .questionnaire .section-list .list-container .field-row:hover,
.liquid-light-theme .questionnaire .section-list .list-container .list-row:hover,
.liquid-light-theme .questionnaire .sidenav .list-container .field-row:hover,
.liquid-light-theme .questionnaire .sidenav .list-container .list-row:hover,
.liquid-light-theme .container-with-sidenav .field-list .list-container .field-row:hover,
.liquid-light-theme .container-with-sidenav .field-list .list-container .list-row:hover,
.liquid-light-theme .container-with-sidenav .section-list .list-container .field-row:hover,
.liquid-light-theme .container-with-sidenav .section-list .list-container .list-row:hover,
.liquid-light-theme .container-with-sidenav .sidenav .list-container .field-row:hover,
.liquid-light-theme .container-with-sidenav .sidenav .list-container .list-row:hover {
  background-color: #f1f1f5;
}
.liquid-light-theme .document-sign .field-list .list-container .field-row:hover .field-info,
.liquid-light-theme .document-sign .field-list .list-container .field-row:hover .select,
.liquid-light-theme .document-sign .field-list .list-container .field-row:hover .list-row-item,
.liquid-light-theme .document-sign .field-list .list-container .list-row:hover .field-info,
.liquid-light-theme .document-sign .field-list .list-container .list-row:hover .select,
.liquid-light-theme .document-sign .field-list .list-container .list-row:hover .list-row-item,
.liquid-light-theme .document-sign .section-list .list-container .field-row:hover .field-info,
.liquid-light-theme .document-sign .section-list .list-container .field-row:hover .select,
.liquid-light-theme .document-sign .section-list .list-container .field-row:hover .list-row-item,
.liquid-light-theme .document-sign .section-list .list-container .list-row:hover .field-info,
.liquid-light-theme .document-sign .section-list .list-container .list-row:hover .select,
.liquid-light-theme .document-sign .section-list .list-container .list-row:hover .list-row-item,
.liquid-light-theme .document-sign .sidenav .list-container .field-row:hover .field-info,
.liquid-light-theme .document-sign .sidenav .list-container .field-row:hover .select,
.liquid-light-theme .document-sign .sidenav .list-container .field-row:hover .list-row-item,
.liquid-light-theme .document-sign .sidenav .list-container .list-row:hover .field-info,
.liquid-light-theme .document-sign .sidenav .list-container .list-row:hover .select,
.liquid-light-theme .document-sign .sidenav .list-container .list-row:hover .list-row-item,
.liquid-light-theme .questionnaire .field-list .list-container .field-row:hover .field-info,
.liquid-light-theme .questionnaire .field-list .list-container .field-row:hover .select,
.liquid-light-theme .questionnaire .field-list .list-container .field-row:hover .list-row-item,
.liquid-light-theme .questionnaire .field-list .list-container .list-row:hover .field-info,
.liquid-light-theme .questionnaire .field-list .list-container .list-row:hover .select,
.liquid-light-theme .questionnaire .field-list .list-container .list-row:hover .list-row-item,
.liquid-light-theme .questionnaire .section-list .list-container .field-row:hover .field-info,
.liquid-light-theme .questionnaire .section-list .list-container .field-row:hover .select,
.liquid-light-theme .questionnaire .section-list .list-container .field-row:hover .list-row-item,
.liquid-light-theme .questionnaire .section-list .list-container .list-row:hover .field-info,
.liquid-light-theme .questionnaire .section-list .list-container .list-row:hover .select,
.liquid-light-theme .questionnaire .section-list .list-container .list-row:hover .list-row-item,
.liquid-light-theme .questionnaire .sidenav .list-container .field-row:hover .field-info,
.liquid-light-theme .questionnaire .sidenav .list-container .field-row:hover .select,
.liquid-light-theme .questionnaire .sidenav .list-container .field-row:hover .list-row-item,
.liquid-light-theme .questionnaire .sidenav .list-container .list-row:hover .field-info,
.liquid-light-theme .questionnaire .sidenav .list-container .list-row:hover .select,
.liquid-light-theme .questionnaire .sidenav .list-container .list-row:hover .list-row-item,
.liquid-light-theme .container-with-sidenav .field-list .list-container .field-row:hover .field-info,
.liquid-light-theme .container-with-sidenav .field-list .list-container .field-row:hover .select,
.liquid-light-theme .container-with-sidenav .field-list .list-container .field-row:hover .list-row-item,
.liquid-light-theme .container-with-sidenav .field-list .list-container .list-row:hover .field-info,
.liquid-light-theme .container-with-sidenav .field-list .list-container .list-row:hover .select,
.liquid-light-theme .container-with-sidenav .field-list .list-container .list-row:hover .list-row-item,
.liquid-light-theme .container-with-sidenav .section-list .list-container .field-row:hover .field-info,
.liquid-light-theme .container-with-sidenav .section-list .list-container .field-row:hover .select,
.liquid-light-theme .container-with-sidenav .section-list .list-container .field-row:hover .list-row-item,
.liquid-light-theme .container-with-sidenav .section-list .list-container .list-row:hover .field-info,
.liquid-light-theme .container-with-sidenav .section-list .list-container .list-row:hover .select,
.liquid-light-theme .container-with-sidenav .section-list .list-container .list-row:hover .list-row-item,
.liquid-light-theme .container-with-sidenav .sidenav .list-container .field-row:hover .field-info,
.liquid-light-theme .container-with-sidenav .sidenav .list-container .field-row:hover .select,
.liquid-light-theme .container-with-sidenav .sidenav .list-container .field-row:hover .list-row-item,
.liquid-light-theme .container-with-sidenav .sidenav .list-container .list-row:hover .field-info,
.liquid-light-theme .container-with-sidenav .sidenav .list-container .list-row:hover .select,
.liquid-light-theme .container-with-sidenav .sidenav .list-container .list-row:hover .list-row-item {
  color: #8e8aa9;
}
.liquid-light-theme .document-sign .field-list .list-container .select-row .new,
.liquid-light-theme .document-sign .section-list .list-container .select-row .new,
.liquid-light-theme .document-sign .sidenav .list-container .select-row .new,
.liquid-light-theme .questionnaire .field-list .list-container .select-row .new,
.liquid-light-theme .questionnaire .section-list .list-container .select-row .new,
.liquid-light-theme .questionnaire .sidenav .list-container .select-row .new,
.liquid-light-theme .container-with-sidenav .field-list .list-container .select-row .new,
.liquid-light-theme .container-with-sidenav .section-list .list-container .select-row .new,
.liquid-light-theme .container-with-sidenav .sidenav .list-container .select-row .new {
  color: #2AC769;
}
.liquid-light-theme .document-sign .field-list .list-container .select-row:hover,
.liquid-light-theme .document-sign .section-list .list-container .select-row:hover,
.liquid-light-theme .document-sign .sidenav .list-container .select-row:hover,
.liquid-light-theme .questionnaire .field-list .list-container .select-row:hover,
.liquid-light-theme .questionnaire .section-list .list-container .select-row:hover,
.liquid-light-theme .questionnaire .sidenav .list-container .select-row:hover,
.liquid-light-theme .container-with-sidenav .field-list .list-container .select-row:hover,
.liquid-light-theme .container-with-sidenav .section-list .list-container .select-row:hover,
.liquid-light-theme .container-with-sidenav .sidenav .list-container .select-row:hover {
  background-color: #f1f1f5;
}
.liquid-light-theme .document-sign .field-list .list-container .select-row:hover .select,
.liquid-light-theme .document-sign .section-list .list-container .select-row:hover .select,
.liquid-light-theme .document-sign .sidenav .list-container .select-row:hover .select,
.liquid-light-theme .questionnaire .field-list .list-container .select-row:hover .select,
.liquid-light-theme .questionnaire .section-list .list-container .select-row:hover .select,
.liquid-light-theme .questionnaire .sidenav .list-container .select-row:hover .select,
.liquid-light-theme .container-with-sidenav .field-list .list-container .select-row:hover .select,
.liquid-light-theme .container-with-sidenav .section-list .list-container .select-row:hover .select,
.liquid-light-theme .container-with-sidenav .sidenav .list-container .select-row:hover .select {
  color: #8e8aa9;
}
.liquid-light-theme .document-sign .field-list .list-container .select-row .select.selected,
.liquid-light-theme .document-sign .section-list .list-container .select-row .select.selected,
.liquid-light-theme .document-sign .sidenav .list-container .select-row .select.selected,
.liquid-light-theme .questionnaire .field-list .list-container .select-row .select.selected,
.liquid-light-theme .questionnaire .section-list .list-container .select-row .select.selected,
.liquid-light-theme .questionnaire .sidenav .list-container .select-row .select.selected,
.liquid-light-theme .container-with-sidenav .field-list .list-container .select-row .select.selected,
.liquid-light-theme .container-with-sidenav .section-list .list-container .select-row .select.selected,
.liquid-light-theme .container-with-sidenav .sidenav .list-container .select-row .select.selected {
  background-color: #f1f1f5;
  color: #8e8aa9;
}
.liquid-light-theme .document-sign .field-list .list-container .selected,
.liquid-light-theme .document-sign .section-list .list-container .selected,
.liquid-light-theme .document-sign .sidenav .list-container .selected,
.liquid-light-theme .questionnaire .field-list .list-container .selected,
.liquid-light-theme .questionnaire .section-list .list-container .selected,
.liquid-light-theme .questionnaire .sidenav .list-container .selected,
.liquid-light-theme .container-with-sidenav .field-list .list-container .selected,
.liquid-light-theme .container-with-sidenav .section-list .list-container .selected,
.liquid-light-theme .container-with-sidenav .sidenav .list-container .selected {
  background-color: #f1f1f5;
}
.liquid-light-theme .document-sign .field-list .list-container .selected .field-info,
.liquid-light-theme .document-sign .field-list .list-container .selected .select,
.liquid-light-theme .document-sign .field-list .list-container .selected .list-row-item,
.liquid-light-theme .document-sign .section-list .list-container .selected .field-info,
.liquid-light-theme .document-sign .section-list .list-container .selected .select,
.liquid-light-theme .document-sign .section-list .list-container .selected .list-row-item,
.liquid-light-theme .document-sign .sidenav .list-container .selected .field-info,
.liquid-light-theme .document-sign .sidenav .list-container .selected .select,
.liquid-light-theme .document-sign .sidenav .list-container .selected .list-row-item,
.liquid-light-theme .questionnaire .field-list .list-container .selected .field-info,
.liquid-light-theme .questionnaire .field-list .list-container .selected .select,
.liquid-light-theme .questionnaire .field-list .list-container .selected .list-row-item,
.liquid-light-theme .questionnaire .section-list .list-container .selected .field-info,
.liquid-light-theme .questionnaire .section-list .list-container .selected .select,
.liquid-light-theme .questionnaire .section-list .list-container .selected .list-row-item,
.liquid-light-theme .questionnaire .sidenav .list-container .selected .field-info,
.liquid-light-theme .questionnaire .sidenav .list-container .selected .select,
.liquid-light-theme .questionnaire .sidenav .list-container .selected .list-row-item,
.liquid-light-theme .container-with-sidenav .field-list .list-container .selected .field-info,
.liquid-light-theme .container-with-sidenav .field-list .list-container .selected .select,
.liquid-light-theme .container-with-sidenav .field-list .list-container .selected .list-row-item,
.liquid-light-theme .container-with-sidenav .section-list .list-container .selected .field-info,
.liquid-light-theme .container-with-sidenav .section-list .list-container .selected .select,
.liquid-light-theme .container-with-sidenav .section-list .list-container .selected .list-row-item,
.liquid-light-theme .container-with-sidenav .sidenav .list-container .selected .field-info,
.liquid-light-theme .container-with-sidenav .sidenav .list-container .selected .select,
.liquid-light-theme .container-with-sidenav .sidenav .list-container .selected .list-row-item {
  color: #8e8aa9;
}
.liquid-light-theme .tracking-doc-container .tracking-entry {
  background-color: #f1f1f5;
}
.liquid-light-theme .step .step-content {
  border: 1px solid #716c94;
}
.liquid-light-theme .step .edit-step-content {
  border: 1px solid #716c94;
}
.liquid-light-theme .overlay .overlay-window .overlay-window-item:hover {
  color: #8e8aa9;
  background-color: #f1f1f5;
}
.liquid-light-theme .section .hero .image-frame .image-offset {
  background-color: #4F24FA;
}
.liquid-light-theme .section .two-box .compare-box .compare-header {
  background-color: #4F24FA;
}
.liquid-light-theme .onboarding-processes .processes-container .process-card .card-content .front .action-menu .menu-item:hover {
  color: #8e8aa9;
  background-color: #f1f1f5;
}
.liquid-light-theme .page-contents .contents-list .list-item .item-data .item-data-main .list-item-status.accent {
  color: #4F24FA;
}
.liquid-light-theme .page-contents .contents-list .list-item .item-data .item-data-main .list-item-status.warn {
  color: #FFE4A5;
}
.liquid-light-theme .page-contents .contents-list .list-item .item-data .item-data-main .list-item-status.primary {
  color: #8e8aa9;
}
.liquid-light-theme .page-contents .contents-list .list-item-container .list-item {
  background-color: #FFFFFF;
}
.liquid-light-theme .page-contents .contents-list .list-item-container .list-item.edit-step-content .step-name .required .mat-checkbox-inner-container {
  color: #4F24FA;
}
.liquid-light-theme .page-contents .contents-list .list-item-container .step-count .count {
  color: #4F24FA;
  border: 3px solid #17161E;
}
.liquid-light-theme .overflow-cell-coa {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media (min-width: 600px) and (max-width: 1023px) {
  .liquid-light-theme .overflow-cell-coa {
    max-width: inherit;
  }
}
.liquid-light-theme .invoice-form .row .col.invoice-info .mat-select-trigger {
  height: 1.33em;
}
.liquid-light-theme .organization-settings-branding .avatar-container .img-container .mat-icon-button {
  background-color: #FFFFFF;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.liquid-light-theme .organization-settings-branding .avatar-container .img-container .mat-icon-button.small {
  height: 20px;
  width: 20px;
  line-height: 20px;
  box-shadow: none;
}
.liquid-light-theme .business-info-nav-mobile .mat-form-field-wrapper {
  padding-left: 1.25em;
  padding-right: 1.25em;
  padding-bottom: 0;
}
.liquid-light-theme .business-info-nav-mobile .mat-form-field-wrapper .mat-form-field-outline-start,
.liquid-light-theme .business-info-nav-mobile .mat-form-field-wrapper .mat-form-field-outline-gap,
.liquid-light-theme .business-info-nav-mobile .mat-form-field-wrapper .mat-form-field-outline-end {
  border-color: transparent;
}
.liquid-light-theme .business-info-nav-mobile .mat-input-element {
  color: #17161E;
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 22px;
  padding-top: 0;
}
.liquid-light-theme .business-info-nav-mobile .mat-focused .mat-form-field-infix::after {
  color: #17161E;
}
.liquid-light-theme .user-settings-dialog .user-settings-dialog-edit-personal-information form .mat-form-field .mat-form-field-flex,
.liquid-light-theme .user-settings-dialog .user-settings-dialog-change-password form .mat-form-field .mat-form-field-flex {
  padding: 0;
  background-color: #FFFFFF;
}
.liquid-light-theme .user-settings-dialog .user-settings-dialog-edit-personal-information form .mat-form-field .mat-form-field-infix,
.liquid-light-theme .user-settings-dialog .user-settings-dialog-change-password form .mat-form-field .mat-form-field-infix {
  width: 100%;
}
.liquid-light-theme .user-settings-dialog .user-settings-dialog-edit-personal-information form .mat-form-field .mat-form-field-infix input.mat-input-element,
.liquid-light-theme .user-settings-dialog .user-settings-dialog-change-password form .mat-form-field .mat-form-field-infix input.mat-input-element {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #25282B;
}
.liquid-light-theme .user-settings-dialog .user-settings-dialog-edit-personal-information form .mat-form-field .mat-form-field-infix .mat-form-field-label-wrapper label,
.liquid-light-theme .user-settings-dialog .user-settings-dialog-change-password form .mat-form-field .mat-form-field-infix .mat-form-field-label-wrapper label {
  padding-top: 0;
  color: #A0A4A8;
  font-size: 16px;
  line-height: 24px;
}
.liquid-light-theme .user-settings-dialog .user-settings-dialog-change-password .mat-slider .mat-slider-track-background {
  background-color: #DBDDE0;
  border-radius: 2px;
}
.liquid-light-theme .user-settings-dialog .user-settings-dialog-change-password .mat-slider .mat-slider-track-fill {
  background-color: #444159;
  border-radius: 2px;
}
.liquid-light-theme .user-settings-dialog .user-settings-dialog-change-password .mat-slider .mat-slider-thumb-container .mat-slider-thumb {
  background-color: #FFFFFF;
  border: 2px solid #444159;
}
.liquid-light-theme .mat-ink-bar {
  background-color: #4F24FA !important;
}
.liquid-light-theme .subnav-mobile .mat-form-field-wrapper {
  padding-left: 1.25em;
  padding-right: 1.25em;
  padding-bottom: 0;
}
.liquid-light-theme .subnav-mobile .mat-form-field-wrapper .mat-form-field-outline-start,
.liquid-light-theme .subnav-mobile .mat-form-field-wrapper .mat-form-field-outline-gap,
.liquid-light-theme .subnav-mobile .mat-form-field-wrapper .mat-form-field-outline-end {
  border-color: transparent;
}
.liquid-light-theme .subnav-mobile .mat-input-element {
  color: #17161E;
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 22px;
  padding-top: 0;
}
.liquid-light-theme .subnav-mobile .mat-focused .mat-form-field-infix::after {
  color: #17161E;
}
.liquid-light-theme .avatar-management-dialog .mat-dialog-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.liquid-light-theme .avatar-management-dialog .mat-button.button-transparent {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.2px;
  background-color: transparent;
  margin-right: 20px;
  color: #17161E;
}
.liquid-light-theme .avatar-management-dialog .mat-raised-button {
  font-weight: normal;
  background-color: #4F24FA;
  color: #FFFFFF;
  border-radius: 8px;
}
.liquid-light-theme .state-zip-container .mat-form-field-infix {
  width: 100%;
}
.liquid-light-theme .clients-home .action-button:not(:last-child),
.liquid-light-theme .vendors-home .action-button:not(:last-child) {
  margin-right: 20px;
}
.liquid-light-theme .clients-home .table-container td.check-button.button-column button:not(:disabled) i-feather,
.liquid-light-theme .vendors-home .table-container td.check-button.button-column button:not(:disabled) i-feather {
  color: #17161E !important;
}
.liquid-light-theme .clients-home .table-container td.check-button.button-column button:not(:disabled) i-feather.selected,
.liquid-light-theme .vendors-home .table-container td.check-button.button-column button:not(:disabled) i-feather.selected {
  fill: #17161E !important;
}
.liquid-light-theme .clients-home .mat-chip-list:focus,
.liquid-light-theme .vendors-home .mat-chip-list:focus {
  outline: none !important;
}
.liquid-light-theme .clients-home .mat-chip:focus,
.liquid-light-theme .vendors-home .mat-chip:focus {
  outline: none !important;
}
.liquid-light-theme .clients-home .team-filters-overlay-panel,
.liquid-light-theme .vendors-home .team-filters-overlay-panel {
  width: 100%;
}
.liquid-light-theme td.mat-cell.table-low-contrast {
  color: #A0A4A8;
}
.liquid-light-theme .clients-home app-table.table .mat-table,
.liquid-light-theme .vendors-home app-table.table .mat-table {
  width: 100%;
}
.liquid-light-theme .business-descriptions button .mat-button-wrapper {
  flex: 1;
  flex-direction: column;
}
.liquid-light-theme .mat-tab-group.no-border .mat-tab-header {
  border-bottom-style: none !important;
}
.liquid-light-theme .no-hover .mat-button-focus-overlay {
  opacity: 0 !important;
}
.liquid-light-theme app-deliverable-list.deliverable-list .list-item.view-mode,
.liquid-light-theme app-deliverable-list.deliverable-list .list-item.edit-mode {
  box-shadow: none !important;
  padding: 0 !important;
  width: 100% !important;
}
.liquid-light-theme .notification-container {
  margin: 15px;
}
.liquid-light-theme .notification-container mat-chip.mat-chip {
  height: auto !important;
}
.liquid-light-theme .notification-container .mat-chip {
  width: 100%;
}
.liquid-light-theme .notification-container .mat-chip .chip-value {
  max-width: 100% !important;
  width: 100% !important;
  white-space: break-spaces;
}
.liquid-light-theme .create-work-order-workflow .services-and-deliverables .mat-expansion-panel-content .mat-expansion-panel-body div.workflow-group-content.single-step {
  margin: 24px 0 !important;
}
.liquid-light-theme .create-work-order-workflow .services-and-deliverables .mat-expansion-panel-content .mat-expansion-panel-body div.workflow-group-content.single-step .inline-error {
  width: auto;
  margin: 0 75px;
}
.liquid-light-theme .create-work-order-workflow .services-and-deliverables .mat-expansion-panel-content .mat-expansion-panel-body div.workflow-group-content.single-step .inline-confirm {
  margin: 24px 150px;
}
.liquid-light-theme .create-work-order-workflow .services-and-deliverables .mat-expansion-panel-content .mat-expansion-panel-body div.workflow-group-content.single-step .step-footer .button-container {
  margin-right: 24px;
}
.liquid-light-theme .mat-tab-group.invoice-error .mat-tab-header .mat-tab-label-container .mat-tab-list .mat-ink-bar {
  background-color: #E93C3C !important;
}
.liquid-light-theme .invoice-cta .pay-invoice .form .mat-radio-label-content {
  white-space: initial !important;
  line-height: initial !important;
}
.liquid-light-theme .tag-collapsed-tooltip {
  white-space: pre-line;
  word-wrap: normal;
  border-radius: 8px;
  border: 1px solid #c7c5d4;
  color: #17161E;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  max-width: 200px;
  background-color: #FFFFFF;
}
.liquid-light-theme app-data-page-template .data-header-actions {
  display: flex;
  align-items: center;
}
.liquid-light-theme app-data-page-template .data-page-actions {
  grid-area: table-grid-actions;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  top: 0;
  width: 100%;
  max-width: calc(96vw - 260px);
  padding-left: 32px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
@media (max-width: 1023px) {
  .liquid-light-theme app-data-page-template .data-page-actions {
    max-width: 100vw;
  }
}
@media (max-width: 599px) {
  .liquid-light-theme app-data-page-template .data-page-actions {
    padding-left: 20px;
  }
}
.liquid-light-theme app-data-page-template .data-page-actions .toggles {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.liquid-light-theme app-data-page-template .data-page-content {
  grid-area: table-grid-content;
  padding-left: 32px;
  min-width: 100%;
  min-width: fit-content;
}
@media (max-width: 599px) {
  .liquid-light-theme app-data-page-template .data-page-content {
    margin: 0;
    padding: 0 12px;
  }
}
.liquid-light-theme app-data-page-template .data-page-content.loading {
  display: flex;
  justify-content: center;
}
.liquid-light-theme app-action-page-template.module-container.settings-table-module {
  position: relative;
  overflow-x: unset;
}
.liquid-light-theme app-action-page-template.module-container.settings-table-module .module-contents {
  overflow-x: unset;
}
.liquid-light-theme app-action-page-template.module-container.settings-table-module .module-contents .subnav-content-container {
  overflow-x: unset;
}
.liquid-light-theme app-action-page-template.module-container.settings-table-module .module-contents .subnav-content-container .subnav-content {
  overflow-x: unset;
}
.liquid-light-theme app-settings-table-template {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 1fr;
  grid-template-areas: "table-grid-heading" "table-grid-actions" "table-grid-content";
  grid-gap: 10px;
  grid-template-columns: 100%;
  overflow-x: unset;
  overflow-y: unset;
}
@media (max-width: 1023px) {
  .liquid-light-theme app-settings-table-template {
    overflow: auto;
    margin: 20px 0;
  }
}
@media (max-width: 599px) {
  .liquid-light-theme app-settings-table-template {
    margin: 0;
    overflow-x: hidden;
    display: block;
  }
  .liquid-light-theme app-settings-table-template .data-table .lqd-paginator .mat-paginator-outer-container .mat-paginator-container {
    padding: 0;
  }
}
.liquid-light-theme app-settings-table-template .header-actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
@media (max-width: 599px) {
  .liquid-light-theme app-settings-table-template .header-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.liquid-light-theme app-settings-table-template .nav-content {
  overflow-x: unset;
  overflow-y: unset;
}
.liquid-light-theme app-settings-table-template .nav-content .mat-tab-body-wrapper {
  overflow-x: unset;
  overflow-y: unset;
  max-width: 100%;
}
.liquid-light-theme app-settings-table-template .nav-content .mat-tab-body-wrapper .mat-tab-body {
  overflow-x: hidden;
}
.liquid-light-theme app-settings-table-template .nav-content .mat-tab-body-wrapper .mat-tab-body.mat-tab-body-active {
  overflow-x: auto;
  max-width: 100%;
  overflow-y: unset;
}
.liquid-light-theme app-settings-table-template .nav-content .mat-tab-body-wrapper .mat-tab-body .mat-tab-body-content {
  position: relative;
  margin: 0;
  padding: 0;
  overflow-x: unset;
  overflow-y: unset;
}
.liquid-light-theme app-settings-table-template .nav-content .mat-tab-body-wrapper .mat-tab-body .mat-tab-body-content > .table-container {
  grid-area: table-grid-content;
  padding-left: 32px;
  min-width: 100%;
  max-width: 100%;
  padding-left: 0;
}
@media (max-width: 599px) {
  .liquid-light-theme app-settings-table-template .nav-content .mat-tab-body-wrapper .mat-tab-body .mat-tab-body-content > .table-container {
    margin: 0;
    padding: 0 12px;
  }
}
@media (max-width: 599px) {
  .liquid-light-theme app-settings-table-template .nav-content .mat-tab-body-wrapper .mat-tab-body .mat-tab-body-content > .table-container {
    margin: 0;
    padding: 0;
  }
}
.liquid-light-theme app-settings-table-template .page-content {
  grid-area: table-grid-content;
  padding-left: 32px;
  min-width: 100%;
  padding: 0;
}
@media (max-width: 599px) {
  .liquid-light-theme app-settings-table-template .page-content {
    margin: 0;
    padding: 0 12px;
  }
}
@media (max-width: 599px) {
  .liquid-light-theme app-settings-table-template .page-content {
    margin: 0;
    padding: 0;
  }
}
.liquid-light-theme .project-links-work-order-summary-dialog .mat-dialog-container {
  padding: 0 !important;
}
.liquid-light-theme .contact-search-option {
  padding: 5px !important;
  height: unset;
  line-height: unset;
}

.primary100 {
  color: #17161E;
}

.primary90 {
  color: #2e2c3b;
}

.primary80 {
  color: #444159;
}

.primary70 {
  color: #5b5776;
}

.primary60 {
  color: #716c94;
}

.primary50 {
  color: #8e8aa9;
}

.primary40 {
  color: #aaa7bf;
}

.primary30 {
  color: #c7c5d4;
}

.primary20 {
  color: #e3e2ea;
}

.primary10 {
  color: #f1f1f5;
}

.black100 {
  color: #25282B;
}

.black80 {
  color: #52575C;
}

.black60 {
  color: #A0A4A8;
}

.black40 {
  color: #CACCCF;
}

.black20 {
  color: #DBDDE0;
}

.black10 {
  color: #E8E8E8;
}

.black5 {
  color: #F7F7F7;
}

.white-default {
  color: #FFFFFF;
}

.white-dark {
  color: #F7F9FB;
}

.warning-default {
  color: #FFE4A5;
}

.warning-light {
  color: #FFF2D2;
}

.warning-dark {
  color: #CC8400;
}

.success-default {
  color: #2AC769;
}

.success-dark {
  color: #128718;
}

.success-light {
  color: #D1F9C6;
}

.error-default {
  color: #FB4E4E;
}

.error-dark {
  color: #E93C3C;
}

.error-light {
  color: #FFC6C6;
}

.accent-default {
  color: #4F24FA;
}

.bg-primary100 {
  background-color: #17161E;
}

.bg-primary90 {
  background-color: #2e2c3b;
}

.bg-primary80 {
  background-color: #444159;
}

.bg-primary70 {
  background-color: #5b5776;
}

.bg-primary60 {
  background-color: #716c94;
}

.bg-primary50 {
  background-color: #8e8aa9;
}

.bg-primary40 {
  background-color: #aaa7bf;
}

.bg-primary30 {
  background-color: #c7c5d4;
}

.bg-primary20 {
  background-color: #e3e2ea;
}

.bg-primary10 {
  background-color: #f1f1f5;
}

.bg-black100 {
  background-color: #25282B;
}

.bg-black80 {
  background-color: #52575C;
}

.bg-black60 {
  background-color: #A0A4A8;
}

.bg-black40 {
  background-color: #CACCCF;
}

.bg-black20 {
  background-color: #DBDDE0;
}

.bg-black10 {
  background-color: #E8E8E8;
}

.bg-black5 {
  background-color: #F7F7F7;
}

.bg-white-default {
  background-color: #FFFFFF;
}

.bg-white-dark {
  background-color: #F7F9FB;
}

.bg-warning-light {
  background-color: #FFF2D2;
}

.bg-warning-dark {
  background-color: #CC8400;
}

.bg-warning-default {
  background-color: #FFE4A5;
}

.bg-success-default {
  background-color: #2AC769;
}

.bg-success-dark {
  background-color: #128718;
}

.bg-success-light {
  background-color: #D1F9C6;
}

.bg-error-default {
  background-color: #FB4E4E;
}

.bg-error-dark {
  background-color: #E93C3C;
}

.bg-error-light {
  background-color: #FFC6C6;
}

.bg-accent-default {
  background-color: #4F24FA;
}

.fill-none {
  fill: none;
}

.fill-primary100 {
  fill: #17161E;
}

.fill-primary90 {
  fill: #2e2c3b;
}

.fill-primary80 {
  fill: #444159;
}

.fill-primary70 {
  fill: #5b5776;
}

.fill-primary60 {
  fill: #716c94;
}

.fill-primary50 {
  fill: #8e8aa9;
}

.fill-primary40 {
  fill: #aaa7bf;
}

.fill-primary30 {
  fill: #c7c5d4;
}

.fill-primary20 {
  fill: #e3e2ea;
}

.fill-primary10 {
  fill: #f1f1f5;
}

.fill-black100 {
  fill: #25282B;
}

.fill-black80 {
  fill: #52575C;
}

.fill-black60 {
  fill: #A0A4A8;
}

.fill-black40 {
  fill: #CACCCF;
}

.fill-black20 {
  fill: #DBDDE0;
}

.fill-black10 {
  fill: #E8E8E8;
}

.fill-black5 {
  fill: #F7F7F7;
}

.fill-white-default {
  fill: #FFFFFF;
}

.fill-white-dark {
  fill: #F7F9FB;
}

.fill-warning-light {
  fill: #FFF2D2;
}

.fill-warning-dark {
  fill: #CC8400;
}

.fill-warning-default {
  fill: #FFE4A5;
}

.fill-success-default {
  fill: #2AC769;
}

.fill-success-dark {
  fill: #128718;
}

.fill-success-light {
  fill: #D1F9C6;
}

.fill-error-default {
  fill: #FB4E4E;
}

.fill-error-dark {
  fill: #E93C3C;
}

.fill-error-light {
  fill: #FFC6C6;
}

.fill-accent-default {
  fill: #4F24FA;
}

@media print {
  .site-header,
.sidenav-nav,
.cdk-overlay-container,
.hidden-input,
.mat-select-value-text,
.shadow,
.mat-expansion-indicator,
.help-aside-wrapper,
.hide-print,
.fc-widget-small {
    display: none !important;
  }

  .inline-error {
    background-color: #FFC6C6 !important;
    -webkit-print-color-adjust: exact !important;
  }

  .inline-success {
    background-color: #D1F9C6 !important;
    -webkit-print-color-adjust: exact !important;
  }

  .inline-warning {
    background-color: #FFF2D2 !important;
    -webkit-print-color-adjust: exact !important;
  }

  app-invoice-summary .stack {
    display: flex;
    justify-content: space-between;
  }
  app-invoice-summary .stack .col.invoice-info {
    max-width: 45%;
  }
  app-invoice-summary .stack .col.invoice-info p {
    text-align: right;
    -webkit-print-color-adjust: exact !important;
  }

  app-help-tooltip i-feather.trigger {
    display: none;
  }

  .order-detail-card .detail-list .mat-expansion-panel-header {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .order-detail-card app-deliverable-form form {
    padding: 0 12% !important;
    -webkit-print-color-adjust: exact !important;
  }

  .sidenav-container {
    min-height: 0px !important;
  }

  .liquid-light-theme {
    padding: 10px;
    background: none;
  }
  .liquid-light-theme .mat-chip.mat-standard-chip {
    -webkit-print-color-adjust: exact !important;
  }

  .line-items-table {
    width: 100% !important;
    -webkit-print-color-adjust: exact !important;
  }

  * {
    overflow: unset !important;
    height: auto !important;
    width: auto !important;
    min-height: 0px !important;
    max-height: max-content !important;
  }

  .invoice-detail {
    width: 561px !important;
  }

  .avatar img {
    height: 100px !important;
    width: 100px !important;
  }
  .avatar .avatar-placeholder {
    height: 100px !important;
    width: 100px !important;
    background-color: #52575C;
    -webkit-print-color-adjust: exact !important;
  }

  .trigger {
    height: 20px !important;
    width: 20px !important;
  }

  .content .sidenav-container {
    background: none !important;
  }

  .summary .detail-list .mat-expansion-panel-content {
    overflow: visible !important;
    display: block !important;
    visibility: visible !important;
    height: inherit !important;
  }
}
.primary100 {
  color: #17161E;
}

.primary90 {
  color: #2e2c3b;
}

.primary80 {
  color: #444159;
}

.primary70 {
  color: #5b5776;
}

.primary60 {
  color: #716c94;
}

.primary50 {
  color: #8e8aa9;
}

.primary40 {
  color: #aaa7bf;
}

.primary30 {
  color: #c7c5d4;
}

.primary20 {
  color: #e3e2ea;
}

.primary10 {
  color: #f1f1f5;
}

.black100 {
  color: #25282B;
}

.black80 {
  color: #52575C;
}

.black60 {
  color: #A0A4A8;
}

.black40 {
  color: #CACCCF;
}

.black20 {
  color: #DBDDE0;
}

.black10 {
  color: #E8E8E8;
}

.black5 {
  color: #F7F7F7;
}

.white-default {
  color: #FFFFFF;
}

.white-dark {
  color: #F7F9FB;
}

.warning-default {
  color: #FFE4A5;
}

.warning-light {
  color: #FFF2D2;
}

.warning-dark {
  color: #CC8400;
}

.success-default {
  color: #2AC769;
}

.success-dark {
  color: #128718;
}

.success-light {
  color: #D1F9C6;
}

.error-default {
  color: #FB4E4E;
}

.error-dark {
  color: #E93C3C;
}

.error-light {
  color: #FFC6C6;
}

.accent-default {
  color: #4F24FA;
}

.bg-primary100 {
  background-color: #17161E;
}

.bg-primary90 {
  background-color: #2e2c3b;
}

.bg-primary80 {
  background-color: #444159;
}

.bg-primary70 {
  background-color: #5b5776;
}

.bg-primary60 {
  background-color: #716c94;
}

.bg-primary50 {
  background-color: #8e8aa9;
}

.bg-primary40 {
  background-color: #aaa7bf;
}

.bg-primary30 {
  background-color: #c7c5d4;
}

.bg-primary20 {
  background-color: #e3e2ea;
}

.bg-primary10 {
  background-color: #f1f1f5;
}

.bg-black100 {
  background-color: #25282B;
}

.bg-black80 {
  background-color: #52575C;
}

.bg-black60 {
  background-color: #A0A4A8;
}

.bg-black40 {
  background-color: #CACCCF;
}

.bg-black20 {
  background-color: #DBDDE0;
}

.bg-black10 {
  background-color: #E8E8E8;
}

.bg-black5 {
  background-color: #F7F7F7;
}

.bg-white-default {
  background-color: #FFFFFF;
}

.bg-white-dark {
  background-color: #F7F9FB;
}

.bg-warning-light {
  background-color: #FFF2D2;
}

.bg-warning-dark {
  background-color: #CC8400;
}

.bg-warning-default {
  background-color: #FFE4A5;
}

.bg-success-default {
  background-color: #2AC769;
}

.bg-success-dark {
  background-color: #128718;
}

.bg-success-light {
  background-color: #D1F9C6;
}

.bg-error-default {
  background-color: #FB4E4E;
}

.bg-error-dark {
  background-color: #E93C3C;
}

.bg-error-light {
  background-color: #FFC6C6;
}

.bg-accent-default {
  background-color: #4F24FA;
}

.fill-none {
  fill: none;
}

.fill-primary100 {
  fill: #17161E;
}

.fill-primary90 {
  fill: #2e2c3b;
}

.fill-primary80 {
  fill: #444159;
}

.fill-primary70 {
  fill: #5b5776;
}

.fill-primary60 {
  fill: #716c94;
}

.fill-primary50 {
  fill: #8e8aa9;
}

.fill-primary40 {
  fill: #aaa7bf;
}

.fill-primary30 {
  fill: #c7c5d4;
}

.fill-primary20 {
  fill: #e3e2ea;
}

.fill-primary10 {
  fill: #f1f1f5;
}

.fill-black100 {
  fill: #25282B;
}

.fill-black80 {
  fill: #52575C;
}

.fill-black60 {
  fill: #A0A4A8;
}

.fill-black40 {
  fill: #CACCCF;
}

.fill-black20 {
  fill: #DBDDE0;
}

.fill-black10 {
  fill: #E8E8E8;
}

.fill-black5 {
  fill: #F7F7F7;
}

.fill-white-default {
  fill: #FFFFFF;
}

.fill-white-dark {
  fill: #F7F9FB;
}

.fill-warning-light {
  fill: #FFF2D2;
}

.fill-warning-dark {
  fill: #CC8400;
}

.fill-warning-default {
  fill: #FFE4A5;
}

.fill-success-default {
  fill: #2AC769;
}

.fill-success-dark {
  fill: #128718;
}

.fill-success-light {
  fill: #D1F9C6;
}

.fill-error-default {
  fill: #FB4E4E;
}

.fill-error-dark {
  fill: #E93C3C;
}

.fill-error-light {
  fill: #FFC6C6;
}

.fill-accent-default {
  fill: #4F24FA;
}

.liquid-light-theme .mat-table .mat-cell.mat-column-select,
.liquid-light-theme .mat-table .mat-header-cell.mat-column-select {
  flex: 0 0 20px;
  padding: 0 15px 0 15px;
}
.liquid-light-theme .mat-table .mat-cell.mat-column-invitedDate,
.liquid-light-theme .mat-table .mat-header-cell.mat-column-invitedDate {
  flex: 0 0 150px;
  padding: 0 60px 0 15px;
}
.liquid-light-theme table:not(.no-hover-color) tr:hover td {
  background-color: #f1f1f5;
}
.liquid-light-theme .data-table {
  min-width: fit-content;
}
@media (max-width: 599px) {
  .liquid-light-theme .data-table {
    min-width: auto;
  }
}
.liquid-light-theme .data-table .table-container table.mat-table {
  overflow-x: auto;
  table-layout: fixed;
  min-width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.liquid-light-theme .data-table .table-container table.mat-table thead,
.liquid-light-theme .data-table .table-container table.mat-table .mat-header-row {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: #E8E8E8;
}
.liquid-light-theme .data-table .table-container table.mat-table th {
  font-size: 14px;
}
.liquid-light-theme .data-table .table-container table.mat-table th .mat-sort-header-container button.mat-sort-header-button {
  color: #25282B !important;
  font-weight: 600 !important;
  text-transform: none !important;
}
.liquid-light-theme .data-table .table-container table.mat-table th.right-aligned .mat-sort-header-container {
  justify-content: flex-end;
}
.liquid-light-theme .data-table .table-container table.mat-table th.right-aligned .mat-sort-header-container .mat-sort-header-arrow {
  margin-right: -18px;
}
.liquid-light-theme .data-table .table-container table.mat-table th:first-of-type {
  border-top-left-radius: 8px;
}
.liquid-light-theme .data-table .table-container table.mat-table th:last-of-type {
  border-top-right-radius: 8px;
  padding-right: 32px;
}
.liquid-light-theme .data-table .table-container table.mat-table th,
.liquid-light-theme .data-table .table-container table.mat-table td {
  padding: 0 16px;
  border-color: #E8E8E8;
}
.liquid-light-theme .data-table .table-container table.mat-table th:first-of-type,
.liquid-light-theme .data-table .table-container table.mat-table td:first-of-type {
  padding-left: 32px;
  border-left: 1px solid #E8E8E8;
}
.liquid-light-theme .data-table .table-container table.mat-table th:last-of-type,
.liquid-light-theme .data-table .table-container table.mat-table td:last-of-type {
  padding-right: 32px;
  border-right: 1px solid #E8E8E8;
}
.liquid-light-theme .data-table .table-container table.mat-table .mat-header-row.stuck th.mat-header-cell {
  border-radius: 0 !important;
  top: -1px !important;
}
.liquid-light-theme .data-table .lqd-paginator:not(.no-sticky) .mat-paginator-outer-container {
  position: relative;
}
.liquid-light-theme .data-table .lqd-paginator:not(.no-sticky) .mat-paginator-outer-container .mat-paginator-container {
  max-width: 500px;
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  padding: 0;
  padding-left: 28px;
  justify-content: flex-start !important;
}

.primary100 {
  color: #17161E;
}

.primary90 {
  color: #2e2c3b;
}

.primary80 {
  color: #444159;
}

.primary70 {
  color: #5b5776;
}

.primary60 {
  color: #716c94;
}

.primary50 {
  color: #8e8aa9;
}

.primary40 {
  color: #aaa7bf;
}

.primary30 {
  color: #c7c5d4;
}

.primary20 {
  color: #e3e2ea;
}

.primary10 {
  color: #f1f1f5;
}

.black100 {
  color: #25282B;
}

.black80 {
  color: #52575C;
}

.black60 {
  color: #A0A4A8;
}

.black40 {
  color: #CACCCF;
}

.black20 {
  color: #DBDDE0;
}

.black10 {
  color: #E8E8E8;
}

.black5 {
  color: #F7F7F7;
}

.white-default {
  color: #FFFFFF;
}

.white-dark {
  color: #F7F9FB;
}

.warning-default {
  color: #FFE4A5;
}

.warning-light {
  color: #FFF2D2;
}

.warning-dark {
  color: #CC8400;
}

.success-default {
  color: #2AC769;
}

.success-dark {
  color: #128718;
}

.success-light {
  color: #D1F9C6;
}

.error-default {
  color: #FB4E4E;
}

.error-dark {
  color: #E93C3C;
}

.error-light {
  color: #FFC6C6;
}

.accent-default {
  color: #4F24FA;
}

.bg-primary100 {
  background-color: #17161E;
}

.bg-primary90 {
  background-color: #2e2c3b;
}

.bg-primary80 {
  background-color: #444159;
}

.bg-primary70 {
  background-color: #5b5776;
}

.bg-primary60 {
  background-color: #716c94;
}

.bg-primary50 {
  background-color: #8e8aa9;
}

.bg-primary40 {
  background-color: #aaa7bf;
}

.bg-primary30 {
  background-color: #c7c5d4;
}

.bg-primary20 {
  background-color: #e3e2ea;
}

.bg-primary10 {
  background-color: #f1f1f5;
}

.bg-black100 {
  background-color: #25282B;
}

.bg-black80 {
  background-color: #52575C;
}

.bg-black60 {
  background-color: #A0A4A8;
}

.bg-black40 {
  background-color: #CACCCF;
}

.bg-black20 {
  background-color: #DBDDE0;
}

.bg-black10 {
  background-color: #E8E8E8;
}

.bg-black5 {
  background-color: #F7F7F7;
}

.bg-white-default {
  background-color: #FFFFFF;
}

.bg-white-dark {
  background-color: #F7F9FB;
}

.bg-warning-light {
  background-color: #FFF2D2;
}

.bg-warning-dark {
  background-color: #CC8400;
}

.bg-warning-default {
  background-color: #FFE4A5;
}

.bg-success-default {
  background-color: #2AC769;
}

.bg-success-dark {
  background-color: #128718;
}

.bg-success-light {
  background-color: #D1F9C6;
}

.bg-error-default {
  background-color: #FB4E4E;
}

.bg-error-dark {
  background-color: #E93C3C;
}

.bg-error-light {
  background-color: #FFC6C6;
}

.bg-accent-default {
  background-color: #4F24FA;
}

.fill-none {
  fill: none;
}

.fill-primary100 {
  fill: #17161E;
}

.fill-primary90 {
  fill: #2e2c3b;
}

.fill-primary80 {
  fill: #444159;
}

.fill-primary70 {
  fill: #5b5776;
}

.fill-primary60 {
  fill: #716c94;
}

.fill-primary50 {
  fill: #8e8aa9;
}

.fill-primary40 {
  fill: #aaa7bf;
}

.fill-primary30 {
  fill: #c7c5d4;
}

.fill-primary20 {
  fill: #e3e2ea;
}

.fill-primary10 {
  fill: #f1f1f5;
}

.fill-black100 {
  fill: #25282B;
}

.fill-black80 {
  fill: #52575C;
}

.fill-black60 {
  fill: #A0A4A8;
}

.fill-black40 {
  fill: #CACCCF;
}

.fill-black20 {
  fill: #DBDDE0;
}

.fill-black10 {
  fill: #E8E8E8;
}

.fill-black5 {
  fill: #F7F7F7;
}

.fill-white-default {
  fill: #FFFFFF;
}

.fill-white-dark {
  fill: #F7F9FB;
}

.fill-warning-light {
  fill: #FFF2D2;
}

.fill-warning-dark {
  fill: #CC8400;
}

.fill-warning-default {
  fill: #FFE4A5;
}

.fill-success-default {
  fill: #2AC769;
}

.fill-success-dark {
  fill: #128718;
}

.fill-success-light {
  fill: #D1F9C6;
}

.fill-error-default {
  fill: #FB4E4E;
}

.fill-error-dark {
  fill: #E93C3C;
}

.fill-error-light {
  fill: #FFC6C6;
}

.fill-accent-default {
  fill: #4F24FA;
}

app-workflow-module mat-expansion-panel {
  box-shadow: none !important;
}
app-workflow-module .workflow-module-top-shadow,
app-workflow-module .workflow-module-bottom-shadow {
  flex: 1;
}
app-workflow-module .workflow-module-top-shadow {
  height: 20px;
  background: linear-gradient(180deg, rgba(216, 216, 216, 0.45) 0%, rgba(255, 255, 255, 0) 88.84%);
}
app-workflow-module .workflow-module-panel {
  border-left: 1px solid #E8E8E8;
  border-right: 1px solid #E8E8E8;
  border-bottom: 1px solid #E8E8E8;
}
app-workflow-module .workflow-module-panel.mat-expanded .workflow-module-header {
  border-bottom: 1px solid #E8E8E8;
}
app-workflow-module .workflow-group-panel:not(:last-of-type) {
  border-bottom: 1px solid #E8E8E8;
}
app-workflow-module:first-of-type .workflow-module-panel {
  border-top: 1px solid #E8E8E8;
}
app-workflow-module:last-of-type .workflow-module-panel {
  border-bottom: 1px solid #E8E8E8;
}
app-workflow-module .workflow-group-panel {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
app-workflow-module:not(:first-of-type) .workflow-module-panel {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
app-workflow-module:not(:last-of-type) .workflow-module-panel,
app-workflow-module:not(:last-of-type) .workflow-group-panel {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
app-workflow-module:not(:last-of-type) .workflow-module-bottom-shadow {
  height: 20px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 40.1%, rgba(216, 216, 216, 0.444) 100%);
}
app-workflow-module:last-of-type .workflow-group-panel:not(:last-of-type) {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
app-workflow-module .mat-expansion-panel-header.cdk-focused {
  background-color: inherit !important;
}
app-workflow-module .workflow-module-panel .mat-expansion-panel-body {
  padding: 0;
}
app-workflow-module .workflow-module-panel.mat-expanded .workflow-module-header {
  position: relative;
  /* box-shadow: 0px 3px 3px -3px rgba(0, 0, 0, 0.12),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14),
      0px 1px 5px 0px rgba(0, 0, 0, 0.12); */
}
app-workflow-module .workflow-module-panel .mat-expansion-panel-header {
  padding: 0 60px;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
@media (max-width: 599px) {
  app-workflow-module .workflow-module-panel .mat-expansion-panel-header {
    padding: 0 20px;
  }
}
app-workflow-module .workflow-module-panel .mat-expansion-panel-header:not([aria-disabled=true]):hover {
  background: none !important;
}
app-workflow-module .workflow-module-panel .form-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
@media (max-width: 1023px) {
  app-workflow-module .workflow-module-panel .form-wrapper {
    flex-direction: column;
    text-align: center;
  }
  app-workflow-module .workflow-module-panel .form-wrapper li {
    text-align: left;
  }
}
app-workflow-module .workflow-module-panel .form-wrapper .image-wrapper {
  height: 200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: auto;
}
@media (min-width: 1024px) {
  app-workflow-module .workflow-module-panel .form-wrapper .image-wrapper {
    justify-content: flex-start;
    margin-right: 37px;
    width: 163px;
  }
}
app-workflow-module .workflow-module-panel .form-wrapper .form-field {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
app-workflow-module .workflow-module-panel .form-wrapper .workflow-form-header {
  display: flex;
  justify-content: center;
}
@media (max-width: 1023px) {
  app-workflow-module .workflow-module-panel .form-wrapper .workflow-form-header {
    flex-direction: column;
    align-items: center;
  }
}
app-workflow-module .workflow-module-panel .form-wrapper .vertical-radio-options mat-radio-button:first-of-type {
  margin-bottom: 25px;
}
app-workflow-module .workflow-module-panel .form-wrapper .horizontal-radio-options {
  flex-direction: row;
}
@media (max-width: 1023px) {
  app-workflow-module .workflow-module-panel .form-wrapper .horizontal-radio-options {
    justify-content: center;
  }
}
app-workflow-module .workflow-module-panel .form-wrapper .horizontal-radio-options mat-radio-button:first-of-type {
  margin-right: 50px;
}
@media (min-width: 1024px) and (max-width: 1439px) {
  app-workflow-module .workflow-module-panel .form-wrapper.narrow-md {
    flex-direction: column !important;
  }
}

app-workflow-module .header-container,
.create-business-form .header-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-right: 15px;
}
app-workflow-module .header-container img,
.create-business-form .header-container img {
  display: none;
}
@media (min-width: 600px) {
  app-workflow-module .header-container img,
.create-business-form .header-container img {
    display: block;
    height: 100px;
    margin-right: 24px;
    width: 100px;
  }
}
app-workflow-module .header-container .group-complete,
.create-business-form .header-container .group-complete {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 10px;
}
app-workflow-module .header-container .action-container,
.create-business-form .header-container .action-container {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Poppins, sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1, .mat-headline, .mat-typography h1 {
  font: 400 24px/32px Poppins, sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2, .mat-title, .mat-typography h2 {
  font: 500 20px/32px Poppins, sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3, .mat-subheading-2, .mat-typography h3 {
  font: 400 16px/28px Poppins, sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4, .mat-subheading-1, .mat-typography h4 {
  font: 400 15px/24px Poppins, sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5, .mat-typography h5 {
  font: 400 calc(14px * 0.83)/20px Poppins, sans-serif;
  margin: 0 0 12px;
}

.mat-h6, .mat-typography h6 {
  font: 400 calc(14px * 0.67)/20px Poppins, sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong, .mat-body-2 {
  font: 500 14px/24px Poppins, sans-serif;
  letter-spacing: normal;
}

.mat-body, .mat-body-1, .mat-typography {
  font: 400 14px/20px Poppins, sans-serif;
  letter-spacing: normal;
}
.mat-body p, .mat-body-1 p, .mat-typography p {
  margin: 0 0 12px;
}

.mat-small, .mat-caption {
  font: 400 12px/20px Poppins, sans-serif;
  letter-spacing: normal;
}

.mat-display-4, .mat-typography .mat-display-4 {
  font: 300 112px/112px Poppins, sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3, .mat-typography .mat-display-3 {
  font: 400 56px/56px Poppins, sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2, .mat-typography .mat-display-2 {
  font: 400 45px/48px Poppins, sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1, .mat-typography .mat-display-1 {
  font: 400 34px/40px Poppins, sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px/20px Poppins, sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: Poppins, sans-serif;
}

.mat-card {
  font-family: Poppins, sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: Poppins, sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon, .mat-chip .mat-icon.chip-icon-suffix,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: Poppins, sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: Poppins, sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px/32px Poppins, sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: Poppins, sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px/20px Poppins, sans-serif;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Poppins, sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34373em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34372em) scale(0.75);
  width: 133.3333633333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00106px);
  -ms-transform: translateY(-1.28119em) scale(0.75);
  width: 133.3333933333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00107px);
  -ms-transform: translateY(-1.28118em) scale(0.75);
  width: 133.3334033333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00108px);
  -ms-transform: translateY(-1.28117em) scale(0.75);
  width: 133.3334133333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28116em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28115em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28114em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59373em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59372em) scale(0.75);
  width: 133.3333633333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59373em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59372em) scale(0.75);
  width: 133.3333633333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Poppins, sans-serif;
  font-size: 12px;
}

.mat-radio-button {
  font-family: Poppins, sans-serif;
}

.mat-select {
  font-family: Poppins, sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: Poppins, sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Poppins, sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: Poppins, sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px/32px Poppins, sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: Poppins, sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: Poppins, sans-serif;
}

.mat-list-option {
  font-family: Poppins, sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: Poppins, sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px/24px Poppins, sans-serif;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: Poppins, sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: Poppins, sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}